import { Fragment } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { Button, Divider, Text } from "../../design-library";
import { formatDate } from "../utils";
import { UserResource } from "../../resources";

const entries = [
  {
    label: "Child Context",
    dataKey: "situation",
    placeholder: "Enter child context...",
  },
  {
    label: "Shadow Response",
    dataKey: "shadows_response",
    placeholder: "Enter shadow's response...",
  },
  {
    label: "Outcome",
    dataKey: "outcome",
    placeholder: "Enter outcome...",
  },
];

const useStyles = makeStyles(() => ({
  itemHeader: { marginTop: "12px" },
  itemBody: { marginTop: "6px" },
}));

function ExampleDetails({ example, printMode = false }) {
  const classes = useStyles();

  return (
    <Box>
      {!printMode && (
        <>
          <Text type="title" content={example.headline} />
          {!UserResource.current?.isDLP && (
            <Button
              statefulLoader
              icon="bookmark"
              style={{ float: "right", padding: "4px", margin: "-4px" }}
              iconStyle={{
                fontSize: "20px",
                color: !example.is_bookmarked && "#C5C5C5",
              }}
              onClick={() => example.toggleBookmark()}
            />
          )}
          <Text
            type="subtitle"
            content={
              <>
                Created at {formatDate(example.added)} by{" "}
                {example.added_by?.full_name || <i>Unknown</i>}
              </>
            }
          />
          <Divider />
        </>
      )}
      {entries.map((entry, idx) => (
        <Fragment key={entry.dataKey}>
          {!printMode && (
            <Text
              type="label"
              content={entry.label}
              className={classes.itemHeader}
            />
          )}
          <Text
            type="paragraph"
            content={example[entry.dataKey]}
            className={classes.itemBody}
          />
        </Fragment>
      ))}
    </Box>
  );
}

export default ExampleDetails;
