/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Input } from "../../../design-library";
import _ from "lodash";

export const additionalFields = [
  {
    label: "Micro Goal",
    dataKey: "helpful",
    placeholder: "How will we know the tip worked?",
  },
  {
    label: "How To",
    dataKey: "howto",
    placeholder:
      "Include what to say, body language, body positioning, tone of voice",
  },
  {
    label: "Persuasive Element",
    dataKey: "persuasive",
    placeholder: "This works because...",
  },
  { label: "Sub Goal", dataKey: "sub_goal" },
  { label: "Overarching Goal", dataKey: "overarching_goal" },
];

function AdditionalFieldsForm({ initialValue = {}, onChange = _.noop }) {
  const [form, setForm] = useState(initialValue);
  const onFormInput = (key, value) => setForm({ ...form, [key]: value });

  useEffect(() => onChange(form), [form]);

  return (
    <Grid container spacing={2}>
      {additionalFields.map(({ label, dataKey, placeholder }) => (
        <Grid item xs={12} key={dataKey}>
          <Input
            placeholder={placeholder}
            label={label}
            onChange={(e) => onFormInput(dataKey, e.target.value)}
            value={form[dataKey]}
            shrinkLabel
            fullWidth
            multiline
          />
        </Grid>
      ))}
    </Grid>
  );
}

AdditionalFieldsForm.propTypes = {
  initialValue: PropTypes.object,
  onChange: PropTypes.func,
};

export default AdditionalFieldsForm;
