/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { DateRangePicker, SearchBar, Spinner } from "../../design-library";
import { StruggleTable } from "../../shared-components/Struggle";
import { useURLState } from "../../shared-components/utils";

const defaultRange = {
  start_date: moment().subtract(6, "weeks").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};

function formatParams(params, page) {
  return {
    search_text: params.search_text,
    start_date: params.start_date || defaultRange.start_date,
    end_date: params.end_date || defaultRange.end_date,
    ordering: params.ordering || "-id",
    page: page || 1,
    page_size: params.pageSize || 20,
  };
}

export default function StrugglesTab({ student }) {
  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [params, setParams] = useURLState({}, [
    "search_text",
    "start_date",
    "end_date",
  ]);

  const loadNextStruggles = (fromFirst = true) => {
    setIsLoading(true);
    const page = fromFirst ? 1 : nextPage;
    student
      .loadStruggles(formatParams(params, page))
      .then((struggles) => setNextPage(struggles.length < 20 ? -1 : page + 1))
      .catch(() => setNextPage(-1))
      .finally(() => setIsLoading(false));
  };

  useEffect(loadNextStruggles, [params, student.id]);

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box height="60px">
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              initialValue={params.search_text || ""}
              onSearch={(q) => setParams({ ...params, search_text: q })}
              placeholder="Search a struggle"
            />
            <DateRangePicker
              style={{ marginLeft: "12px" }}
              noBorder
              range={{
                startDate: new Date(
                  params.start_date || defaultRange.start_date
                ),
                endDate: new Date(params.end_date || defaultRange.end_date),
              }}
              onChange={({ startDate, endDate }) =>
                setParams({
                  ...params,
                  start_date: moment(startDate).format("YYYY-MM-DD"),
                  end_date: moment(endDate).format("YYYY-MM-DD"),
                })
              }
            />
            {isLoading && (
              <Spinner
                style={{
                  display: "inline-flex",
                  height: "40px",
                  marginLeft: "12px",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box height="calc(100% - 60px)" overflow="auto" position="relative">
        <StruggleTable struggles={student.struggles} />
        {!isLoading && nextPage !== -1 && (
          <>
            .
            <Waypoint onEnter={() => loadNextStruggles(false)} />
          </>
        )}
        {nextPage > 1 && isLoading && (
          <Spinner style={{ height: "40px" }} label="Loading more..." />
        )}
      </Box>
    </Box>
  );
}
