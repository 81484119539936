import { Box, makeStyles } from "@material-ui/core";
import { Icon } from "../../design-library";
import { UserResource } from "../../resources";

const useStyles = makeStyles(() => ({
  subtext: {
    color: "#93999A",
    fontSize: "12px",
    marginBottom: "2px",
  },
  root: {
    borderLeft: "3px solid #8E5B93",
    backgroundColor: "#8E5B9322",
    cursor: ({ onClick }) => (onClick ? "pointer" : "default"),
    color: "#686868",
    fontSize: "14px",
    outline: ({ highlighted }) => (highlighted ? `1px solid #8E5B93` : ""),
    marginBottom: "3px",
    padding: "8px",
  },
  bulb: {
    fontSize: "17px",
    color: "#8E5B93",
    float: "right",
  },
  bookmark: {
    fontSize: "17px",
    color: "#8E5B93",
    float: "right",
    marginRight: "4px",
  },
}));

function ExampleCard({ entry, subtext, example, highlighted, onClick }) {
  example = entry || example;

  const classes = useStyles({ onClick, highlighted });

  return (
    <Box className={classes.root} onClick={onClick}>
      {subtext && (
        <span className={["truncate-1", classes.subtext].join(" ")}>
          {subtext}
        </span>
      )}
      <Box display="flex">
        <span
          className="truncate-1"
          style={{
            fontStyle: !example.headline ? "italic" : "",
            width: "100%",
          }}
        >
          {example.headline || "Untitled"}
        </span>
        {!UserResource.current?.isDLP && example.is_bookmarked && (
          <Icon name="bookmark" className={classes.bookmark} />
        )}
        {example.tipId && <Icon name="bulb" className={classes.bulb} />}
      </Box>
    </Box>
  );
}

export default ExampleCard;
