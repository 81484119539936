/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import {
  Box,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
} from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Checkbox, Modal, Button } from "..";

function getInitialParams(filters) {
  const params = {};

  for (const { dataKey, options } of filters) {
    params[dataKey] = options.map((op) => op.value);
  }

  return params;
}

function FilterPanel({
  asModal = false,
  filters = [],
  initialParams,
  header,
  style = {},
  onChange = _.noop,
}) {
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [params, setParams] = useState(
    initialParams || getInitialParams(filters)
  );

  const toggleFilter = (dataKey, value) => {
    if (params[dataKey]?.includes(value)) {
      params[dataKey] = params[dataKey].filter((val) => val !== value);
    } else {
      params[dataKey] = [value, ...(params[dataKey] || [])];
    }

    setParams({ ...params });
  };

  const toggleAll = (dataKey) => {
    const options = filters.find((f) => f.dataKey === dataKey)?.options || [];
    const someChecked = params[dataKey]?.length !== options.length;
    params[dataKey] = someChecked ? options.map((o) => o.value) : [];
    setParams({ ...params });
  };

  useEffect(() => onChange(params), [params]);

  const body = (
    <Grid container spacing={2}>
      {filters.map(({ options, dataKey, label, fullWidth }) => (
        <Grid item xs={fullWidth ? 12 : 6} key={dataKey}>
          <Checkbox
            value={!!params[dataKey]?.length}
            indeterminate={
              ![0, options.length].includes(params[dataKey]?.length)
            }
            label={<span style={{ fontWeight: "bold" }}>{label}</span>}
            onChange={() => toggleAll(dataKey)}
          />
          <Divider style={{ margin: "10px 0" }} />
          {options.map(({ label, cue, value }) => (
            <Box key={value} color="#686868">
              <Checkbox
                value={params[dataKey]?.includes(value)}
                label={
                  <span>
                    {cue} {label}
                  </span>
                }
                onChange={() => toggleFilter(dataKey, value)}
              />
            </Box>
          ))}
        </Grid>
      ))}
    </Grid>
  );

  const footer = (
    <Box textAlign="right">
      <Button
        label="Reset"
        onClick={() => setParams(getInitialParams(filters))}
        style={{ marginRight: "12px" }}
      />
      <Button
        primary
        label={asModal ? "Back" : "Close"}
        onClick={() => {
          setFilterPanelVisible(false);
          setAnchorEl(null);
        }}
      />
    </Box>
  );

  return (
    <>
      <Button
        icon="filter"
        style={{ fontSize: "12px", ...style }}
        primary
        onClick={(e) => {
          asModal ? setFilterPanelVisible(true) : setAnchorEl(e.currentTarget);
        }}
      />
      {asModal ? (
        <Modal
          open={filterPanelVisible}
          breadcrumbs={[header || "Filter Panel"]}
          onClose={() => setFilterPanelVisible(false)}
          footer={footer}
        >
          {body}
        </Modal>
      ) : (
        <Popper
          style={{ zIndex: 10000 }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Paper style={{ padding: "16px 12px 12px 12px" }}>
              {body}
              <Divider style={{ margin: "12px 0" }} />
              {footer}
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}

FilterPanel.propTypes = {
  asModal: PropTypes.bool,
  filters: PropTypes.array,
  initialParams: PropTypes.object,
  header: PropTypes.string,
};

export default FilterPanel;
