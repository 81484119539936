import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Text, Icon, Spinner, Emptyness } from "../../design-library";
import { formatDate } from "../utils";

const useStyles = makeStyles(() => ({
  infoIcon: { fontSize: "16px", color: "#00B5B2" },
}));

function HeadsUpItem({ label, entries }) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  return (
    <Box>
      <Box
        display="flex"
        style={{ cursor: "pointer" }}
        onClick={() => setExpanded((v) => !v)}
      >
        <Box
          display="flex"
          height="30px"
          justifyContent="center"
          flexDirection="column"
          marginRight="8px"
        >
          <Icon name="info" className={classes.infoIcon} />
        </Box>
        <Box
          display="flex"
          height="30px"
          justifyContent="center"
          flexDirection="column"
        >
          <Text type="clickable" content={label} />
        </Box>
      </Box>
      {expanded &&
        entries.map(({ date, content }) => (
          <Box marginBottom="8px" key={content}>
            <Text type="subtitle" content={formatDate(date)} />
            <Text type="paragraph" content={content} />
          </Box>
        ))}
      <Box></Box>
    </Box>
  );
}

function StudentHeadsup({ student }) {
  const headsUps = student?.headsUps?.filter(
    ({ entries }) => entries?.filter((v) => !!v.content).length > 0
  );

  return (
    <Box paddingRight="8px">
      {!headsUps ? (
        <Spinner size={20} />
      ) : !headsUps.length ? (
        <Emptyness style={{ marginTop: "20px" }} size={10} />
      ) : (
        headsUps.map(({ label, entries }) => (
          <HeadsUpItem key={label} label={label} entries={entries} />
        ))
      )}
    </Box>
  );
}

export default StudentHeadsup;
