import { Box, Grid, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ContentList, Divider, Dropdown, Text } from "../../design-library";
import { UserResource } from "../../resources";
import {
  PractitionerBio,
  PractitionerUpdateModal,
} from "../../shared-components/Practitioner";
import { StudentStamp } from "../../shared-components/Student";
import { relativeTimeFormat } from "../../shared-components/utils";
import ChangePasswordModal from "./ChangePasswordModal";

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function Page() {
  const [modalContent, setModalContent] = useState({});
  const history = useHistory();
  const user = UserResource.current;
  const practitioner = user.practitioner;

  useEffect(() => {
    document.title = "Learning Seeds | Profile";
  }, []);

  const classes = useStyles();

  return (
    <Box height="100%">
      <Box
        height="100px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box height="100%" className={classes.pageHeader}>
              <Text
                type="title"
                style={{ fontSize: "22px", margin: 0, color: "#00B5B2" }}
                content={practitioner.fullName}
              />
              <Text
                type="subtitle"
                style={{ fontSize: "14px" }}
                content={
                  <>Joined {relativeTimeFormat(practitioner.date_joined)}</>
                }
              />
            </Box>
          </Grid>
          <Grid item>
            <Box height="100%" className={classes.pageHeader}>
              <Dropdown
                icon="edit"
                options={[
                  { label: "Update Bio", value: "editBio" },
                  { label: "Change Password", value: "changePassword" },
                ]}
                onSelect={(val) => setModalContent({ [val]: true })}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ margin: 0 }} />
      <Box
        height="calc(100% - 101px)"
        width="100%"
        position="relative"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <Text type="label" content="Bio" style={{ margin: "8px 0" }} />
        <PractitionerBio
          practitioner={practitioner}
          style={{ padding: "8px", background: "white" }}
        />
        {user.role === UserResource.ROLES.DLP && (
          <>
            <Divider />
            <Text type="label" content="Professional Goal" />
            <Text
              content={
                practitioner.professional_goal?.split("\n").map((entry) => (
                  <Fragment key={Math.random()}>
                    &bull; {entry}
                    <br />
                  </Fragment>
                )) || "N/A"
              }
              style={{ marginTop: "8px" }}
            />
          </>
        )}
        {user.role !== UserResource.ROLES.DLP && (
          <>
            <Divider />
            <Text
              type="label"
              content="Your Caseload"
              style={{ marginBottom: "8px" }}
            />
            <ContentList
              CardComponent={StudentStamp}
              entries={practitioner.assignedStudents}
              folds="flex"
              onEntryClick={(e) => history.push(`/students/${e.id}/tips`)}
            />
          </>
        )}
        <PractitionerUpdateModal
          practitioner={modalContent.editBio && practitioner}
          onClose={() => setModalContent({})}
        />
        <ChangePasswordModal
          open={modalContent.changePassword}
          onClose={() => setModalContent({})}
        />
      </Box>
    </Box>
  );
}
