import { Box } from "@material-ui/core";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { NotificationEntry } from ".";
import { AppContext } from "../../context";
import {
  Button,
  ContentList,
  Divider,
  Emptyness,
  Popper,
  Spinner,
  Text,
  Tooltip,
} from "../../design-library";
import { TipDisplayModal } from "../Tip";
import { NotificationResource } from "../../resources";
import { EpisodeDisplayModal } from "../Episode";
import { StruggleDisplayModal } from "../Struggle";
import { Waypoint } from "react-waypoint";

function NotificationPopper({ AnchorComponent }) {
  const {
    appState: { notificationsConfig },
    appDispatch,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState();
  const [modalContent, setModalContent] = useState({});
  const [nextPage, setNextPage] = useState();

  const fetch = (fromFirst = true) => {
    setIsLoading(true);
    const page = fromFirst ? 1 : nextPage;
    const params = {
      ordering: "-id",
      page,
      page_size: 20,
    };
    NotificationResource.list(params, "NOTIFICATIONS_CONFIG")
      .then((entries) => {
        setNextPage(entries.length < 20 ? -1 : page + 1);
        appDispatch({
          type: "FLAGS:SET",
          payload: { hasNewNotifications: false },
        });
      })
      .catch(() => setNextPage(-1))
      .finally(() => setIsLoading(false));
  };

  const pages = notificationsConfig?.pages;
  const ids = pages && _.flatten(Object.values(pages));
  const entries = NotificationResource.pickMany(ids);

  return (
    <Popper placement="top" AnchorComponent={AnchorComponent} onOpen={fetch}>
      <Box width="440px" height="400px" border="1px solid #00000022">
        <Box
          height="50px"
          display="flex"
          justifyContent="space-between"
          padding="0 12px"
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Text content="Notifications" type="title" style={{ margin: 0 }} />
          </Box>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Tooltip content="Mark all as read">
              <Button
                icon="tick"
                style={{ marginRight: "-8px" }}
                onClick={() => NotificationResource.markAllAsRead().then(fetch)}
                statefulLoader
              />
            </Tooltip>
          </Box>
        </Box>
        <Divider style={{ margin: "0" }} />
        <Box height="calc(100% - 51px)" padding="12px" overflow="auto">
          {!entries ? (
            <Spinner style={{ height: "280px" }} />
          ) : !entries.length ? (
            <Emptyness size={7} />
          ) : (
            <ContentList
              entries={entries}
              CardComponent={NotificationEntry}
              onEntryClick={(entry, e) => setModalContent(e)}
            />
          )}
          {!isLoading && nextPage !== -1 && (
            <Waypoint onEnter={() => fetch(false)} />
          )}
          {nextPage > 1 && isLoading && (
            <Spinner style={{ height: "40px" }} label="Loading more..." />
          )}
        </Box>
        <TipDisplayModal
          tipId={modalContent.tip}
          initialSiderContent={_.pick(modalContent, ["questions", "example"])}
          onClose={() => setModalContent({})}
        />
        <EpisodeDisplayModal
          episodeId={modalContent.episode}
          onClose={() => setModalContent({})}
        />
        <StruggleDisplayModal
          struggleId={modalContent.struggle}
          onClose={() => setModalContent({})}
        />
      </Box>
    </Popper>
  );
}

export default NotificationPopper;
