import { Box } from "@material-ui/core";
import { useState } from "react";
import { Button, Input, Modal } from "../../design-library";
import { UserResource } from "../../resources";

const fields = [
  { label: "Current password", key: "old_password" },
  { label: "New password", key: "new_password1" },
  { label: "Re-type new password", key: "new_password2" },
];

function ChangePasswordModal({ open, onClose }) {
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const onFormInput = (key, value) => setForm({ ...form, [key]: value });

  const handleReset = () => {
    setIsLoading(true);
    UserResource.changePassword(form)
      .then(onClose)
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      breadcrumbs={["Change Password"]}
      footer={
        <Box textAlign="right">
          <Button
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "12px" }}
            disabled={isLoading}
          />
          <Button
            primary
            label="Submit"
            onClick={handleReset}
            isLoading={isLoading}
          />
        </Box>
      }
    >
      {fields.map(({ label, key }) => (
        <Input
          key={key}
          type="password"
          label={label}
          onChange={(e) => onFormInput(key, e.target.value)}
          fullWidth
          style={{ marginBottom: "8px" }}
        />
      ))}
    </Modal>
  );
}

export default ChangePasswordModal;
