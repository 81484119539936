/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ActivityLog } from "../Activity";

function PractitionerActivities({ practitioner }) {
  useEffect(() => {
    practitioner.loadActivities({ page: 1 });
  }, [practitioner.id]);

  return <ActivityLog entries={practitioner.activities} />;
}

export default PractitionerActivities;
