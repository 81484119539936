/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Checkbox, Input, Modal, Text } from "../../../design-library";
import { Box, Divider, Grid } from "@material-ui/core";
import TipResource from "../../../resources/tip";
import { TipCard } from "../../Tip";
import AdditionalFieldsForm, { additionalFields } from "./AdditionalFieldsForm";
import StateSelector from "./StateSelector";
import _ from "lodash";
import { TipSelector } from "../../Tip";
import { useCookieState } from "../../utils";

const initialFormState = {
  title: "",
  levels: "1",
  linked_tips: [],
  child_context: {},
  environment_context: {},
};

function CreateTipModal({ open = false, onClose = _.noop, onCreate = _.noop }) {
  const [form, setForm] = useCookieState("newTipForm", initialFormState);
  const [isSaving, setIsSaving] = useState(false);

  const onFormInput = (key, value) => setForm({ ...form, [key]: value });

  const onSave = () => {
    setIsSaving(true);

    TipResource.createOne(form)
      .then((tip) => {
        onCreate(tip);
        onClose();
        setForm(initialFormState);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      breadcrumbs={["Tips", "Create New"]}
      footer={
        <Box textAlign="right">
          <Box
            display="flex"
            height="100%"
            style={{ float: "left" }}
            color="#686868"
          >
            <Checkbox
              label="Requires edit"
              initialState={form.marked_for_editing}
              onChange={(e, val) => onFormInput("marked_for_editing", val)}
            />
          </Box>
          <Button
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "12px" }}
            disabled={isSaving}
          />
          <Button primary label="Save" onClick={onSave} isLoading={isSaving} />
        </Box>
      }
    >
      <StateSelector
        initialForm={_.pick(form, ["levels"])}
        onChange={(af) => setForm({ ...form, ...af })}
      />
      <Divider style={{ margin: "12px 0", width: "100%" }} />
      <Text type="label" content="Title" />
      <Input
        placeholder="Enter tip title"
        value={form.title}
        onChange={(e) => onFormInput("title", e.target.value)}
        variant="standard"
        fullWidth
        style={{ marginTop: "6px" }}
      />
      <Divider style={{ margin: "12px 0", width: "100%" }} />
      <Box display="flex">
        <Text
          type="label"
          content="Tip Summary"
          style={{ marginBottom: "12px" }}
        />
      </Box>
      <Input
        onChange={(e) => onFormInput("tip_summary", e.target.value)}
        initialValue={form.tip_summary}
        placeholder="Enter tip summary"
        shrinkLabel
        fullWidth
        multiline
      />
      <Box display="flex">
        <Text
          type="label"
          content="Child Context"
          style={{ marginBottom: "12px", marginTop: "12px" }}
        />
      </Box>
      <Input
        onChange={(e) => onFormInput("child_context_flattened", e.target.value)}
        initialValue={form.child_context_flattened}
        placeholder="Enter child context"
        shrinkLabel
        fullWidth
        multiline
      />
      <Divider style={{ margin: "12px 0", width: "100%" }} />
      <Box display="flex">
        <Text
          type="label"
          content="Environmental Context"
          style={{ marginBottom: "12px" }}
        />
      </Box>
      <Input
        onChange={(e) =>
          onFormInput("environment_context_flattened", e.target.value)
        }
        initialValue={form.environment_context_flattened}
        placeholder="Enter environment context"
        shrinkLabel
        fullWidth
        multiline
      />

      <Divider style={{ margin: "12px 0", width: "100%" }} />
      <Text
        type="label"
        content="Additional Fields"
        style={{ marginBottom: "12px" }}
      />
      <AdditionalFieldsForm
        initialValue={_.pick(
          form,
          additionalFields.map((f) => f.dataKey)
        )}
        onChange={(af) => setForm({ ...form, ...af })}
      />
      <Divider style={{ margin: "12px 0", width: "100%" }} />
      <Box display="flex" justifyContent="space-between">
        <Text
          type="label"
          content="Related Tips"
          style={{ marginBottom: "12px" }}
        />
        <TipSelector
          AnchorComponent={({ onClick }) => (
            <Button
              icon="plus"
              style={{ padding: "8px", margin: "-8px", height: "100%" }}
              onClick={onClick}
            />
          )}
          onSelect={(tipIds) => onFormInput("linked_tips", tipIds)}
          multiSelect
        />
      </Box>
      <>
        <Grid container spacing={1}>
          {TipResource.pickMany(form.linked_tips)?.map((tip) => (
            <Grid item xs={6} key={tip.id}>
              <TipCard
                tip={tip}
                truncate={1}
                onCross={() =>
                  onFormInput(
                    "linked_tips",
                    form.linked_tips.filter((id) => id !== tip.id)
                  )
                }
              />
            </Grid>
          ))}
        </Grid>
      </>
    </Modal>
  );
}

CreateTipModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
};

export default CreateTipModal;
