import { Box, Grid } from "@material-ui/core";
import { Text } from "../../design-library";

const fields = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Username", key: "username" },
  { label: "Email", key: "email" },
  { label: "Role", key: "roleLabel" },
];

function PractitionerBio({ practitioner, style }) {
  return (
    <Box style={style}>
      <Grid container spacing={1}>
        {fields.map(({ label, key }) => (
          <Grid item xs={6} key={key} style={{ margin: "2px 0" }}>
            <Text
              label={label}
              content={practitioner[key] || "-"}
              type="labeledBox"
              style={{ marginTop: "-8px" }}
              fullHeight
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PractitionerBio;
