import { Text } from "../../../design-library";

function SetTipEditMarkEntry({ meta, onClick }) {
  return meta.is_marked ? (
    <Text
      content={
        <>
          marked tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; to edit later.
        </>
      }
    />
  ) : (
    <Text
      content={
        <>
          removed edit mark from tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          .
        </>
      }
    />
  );
}

export default SetTipEditMarkEntry;
