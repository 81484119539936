import { Box, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Button, ContentList, Input, Text } from "../../design-library";
import { QuestionResource } from "../../resources";
import { relativeTimeFormat } from "../utils";

const useStyles = makeStyles(() => ({
  discussion: {
    border: "1px solid #D5977755",
    padding: "8px",
    outline: ({ highlighted }) => (highlighted ? "1px solid #D59777" : ""),
  },
  byline: { marginBottom: "4px" },
  replies: { margin: "8px 0" },
  replyItem: {
    borderLeft: "2px solid #D5977755",
    paddingLeft: "8px",
  },
  rootQuestion: { borderLeft: "0", paddingLeft: "0" },
  clickableTipId: {
    color: "#00B5B2",
    cursor: "pointer",
  },
}));

function Reply({ entry, className }) {
  const classes = useStyles();
  return (
    <Box className={[classes.replyItem, className].join(" ")}>
      <Text
        content={
          <>
            <b>{QuestionResource.getAuthorName(entry)}</b> replied{" "}
            {relativeTimeFormat(entry.created_at)}
          </>
        }
        type="subtitle"
        className={classes.byline}
      />
      <Text content={entry.body} type="paragraph" />
    </Box>
  );
}

function QuestionStamp({ entry, highlighted = false, onClick }) {
  const classes = useStyles({ highlighted });
  const [reply, setReply] = useState("");
  const [mode, setMode] = useState();
  const [isLoading, setIsLoading] = useState();
  const inputRef = useRef();

  useEffect(() => {
    if (mode === "reply") inputRef.current?.focus();
  }, [mode]);

  const addReply = () => {
    setIsLoading(true);
    entry
      .addReply({ body: reply })
      .then(() => {
        setReply("");
        setMode();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box className={classes.discussion}>
      <Box>
        <Text
          content={
            <>
              <b>{QuestionResource.getAuthorName(entry)}</b> asked a question{" "}
              {onClick && (
                <>
                  about tip{" "}
                  <b
                    className={classes.clickableTipId}
                    onClick={() => onClick({ tip: entry.tip })}
                  >
                    #{entry.tip}
                  </b>{" "}
                </>
              )}
              {relativeTimeFormat(entry.created_at)}
            </>
          }
          type="subtitle"
          className={classes.byline}
        />
        <Text content={entry.body} type="paragraph" />
      </Box>
      <Box display="flex" marginTop="8px">
        {mode === "reply" ? (
          <Box width="100%">
            <Input
              inputRef={inputRef}
              onBlur={() => !reply.trim() && setMode()}
              fullWidth
              value={reply}
              placeholder="Write a reply..."
              onChange={(e) => setReply(e.target.value)}
              multiline
            />
            {reply.trim() && (
              <Button
                inline
                label="Send"
                isLoading={isLoading}
                style={{ float: "right", marginTop: "8px" }}
                onClick={addReply}
              />
            )}
          </Box>
        ) : (
          <Button
            inline
            label="Write a Reply"
            onClick={() => setMode("reply")}
          />
        )}
      </Box>
      <Box className={classes.replies}>
        <ContentList entries={entry.replies} CardComponent={Reply} />
      </Box>
    </Box>
  );
}

export default QuestionStamp;
