import PropTypes from "prop-types";
import Illustration from "./Illustration";
import { Box } from "@material-ui/core";

function ErrorState({ size = 24, style }) {
  const dim = `${size * 12}px`;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      textAlign="center"
      sx={{ opacity: 0.25, userSelect: "none" }}
      style={style}
    >
      <Box paddingBottom={6}>
        <Illustration style={{ width: dim, height: dim }} />
      </Box>
    </Box>
  );
}

ErrorState.propTypes = {
  size: PropTypes.number,
};

export default ErrorState;
