/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Input, Text } from "../../design-library";

function CelebrationForm({ initialValues, onUpdate }) {
  const [form, setForm] = useState({});
  const onInput = (key, value) => setForm({ ...form, [key]: value });
  useEffect(() => onUpdate && onUpdate(form), [form]);

  const inputProps = {
    fullWidth: true,
    multiline: true,
    style: { marginTop: "10px" },
  };

  const labelProps = {
    type: "label",
    style: { marginTop: "20px" },
  };

  return (
    <>
      <Text {...labelProps} content="Headline" style={{ marginTop: 0 }} />
      <Input
        {...inputProps}
        initialValue={initialValues?.headline || ""}
        multiline={false}
        placeholder="Enter headline..."
        onChange={(e) => onInput("headline", e.target.value)}
        variant="standard"
      />

      <Text {...labelProps} content="Celebration" />
      <Input
        {...inputProps}
        initialValue={initialValues?.celebration || ""}
        placeholder="Enter celebration..."
        onChange={(e) => onInput("celebration", e.target.value)}
      />
    </>
  );
}

export default CelebrationForm;
