import { Fragment } from "react";
import { Box, Divider } from "@material-ui/core";
import { Text } from "../../design-library";
import { formatDate } from "../utils";

const entries = [
  {
    label: "Child Context",
    dataKey: "child_context",
  },
  {
    label: "Shadow Response",
    dataKey: "shadow_response",
  },
  {
    label: "Outcome",
    dataKey: "outcome",
  },
  {
    label: "Question",
    dataKey: "question",
  },
];

function StruggleDetails({ struggle }) {
  return (
    <Box>
      <Text
        type="title"
        content={`${struggle.is_resolved ? "[RESOLVED] " : ""}${
          struggle.headline
        }`}
      />
      <Text
        type="subtitle"
        content={`Created at ${formatDate(struggle.created_at)} by ${
          struggle.practitioner
        }`}
      />
      <Divider style={{ margin: "20px 0" }} />
      {entries.map((entry, idx) => (
        <Fragment key={entry.dataKey}>
          <Text
            type="label"
            content={entry.label}
            style={{ marginTop: idx > 0 ? "20px" : 0 }}
          />
          <Text
            type="paragraph"
            content={struggle[entry.dataKey]}
            style={{ marginTop: "6px" }}
          />
        </Fragment>
      ))}
    </Box>
  );
}

export default StruggleDetails;
