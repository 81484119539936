import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Spinner, Text } from "../../design-library";

const useStyles = makeStyles(() => ({
  collapseBtn: {
    fontSize: "12px",
  },
}));

export function getEnvContextText(tip) {
  if (tip.environment_context_flattened) {
    return tip.environment_context_flattened;
  }

  let vals = Object.values(tip.environment_context) || [];
  if (vals[0]?.order) {
    vals = vals.sort((a, b) => a.order - b.order);
    vals = vals.map((v) => v.value);
  }

  return vals.filter((t) => !!t).join("\n\n");
}

export function getChildContextText(tip) {
  if (tip.child_context_flattened) {
    return tip.child_context_flattened;
  }

  let vals = Object.values(tip.child_context) || [];
  if (vals[0]?.order) {
    vals = vals.sort((a, b) => a.order - b.order);
    vals = vals.map((v) => v.value);
  }

  return vals.filter((t) => !!t).join("\n\n");
}

function getHowToText(tip) {
  const texts = [tip.howto, tip.persuasive];

  return texts.filter((t) => !!t).join("\n\n");
}

function TipDescription({ tip, printMode }) {
  const [collapsed, setCollapsed] = useState(true);
  const classes = useStyles();

  const description = [];

  if (tip?.loaded) {
    const childContext = getChildContextText(tip);
    const envContext = getEnvContextText(tip);
    const howtoText = getHowToText(tip);

    if (!childContext && !envContext && !howtoText) {
      description.push({ content: tip.description });
      description.push({ content: tip.when });
    }
    description.push({
      label: "When the child is",
      content: childContext,
    });
    description.push({
      label: "In the environment",
      content: envContext,
    });
    description.push({ label: "It can be helpful to", content: tip.helpful });
    description.push({ label: "Here is how", content: howtoText });
    description.push({ label: "Sub goal", content: tip.sub_goal });
    description.push({
      label: "Overarching goal",
      content: tip.overarching_goal,
    });
  }

  return (
    <>
      <Text content="Description" type="label" />
      {!printMode && collapsed ? (
        <Text
          content="[...]"
          type="clickable"
          className={classes.collapseBtn}
          onClick={() => setCollapsed(false)}
        />
      ) : tip?.loaded ? (
        <>
          {description
            .filter(({ content }) => !!content)
            .map(({ label, content }, idx) => (
              <Text
                key={label || content}
                label={label}
                content={content}
                type={label ? "labeledBox" : "paragraph"}
                style={{ marginTop: idx ? "12px" : "8px" }}
              />
            ))}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default TipDescription;
