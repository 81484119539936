import { useState } from "react";
import { ContentSelector, Text } from "../../design-library";
import { PractitionerResource } from "../../resources";
import { PractitionerCard } from ".";
import _ from "lodash";
import { getUpdatedPageDict } from "../utils";

function Anchor({ onClick }) {
  return (
    <Text content="Select Practitioner" type="clickable" onClick={onClick} />
  );
}

function formatParams(params, page) {
  return {
    full_name: params.query,
    page: page || 1,
    page_size: params.pageSize || 20,
  };
}

function PractitionerSelector({
  AnchorComponent = Anchor,
  onSelect,
  multiSelect,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [pages, setPages] = useState();

  const searchPractitioners = (query, fromFirst = true) => {
    setIsLoading(true);

    const page = fromFirst ? 1 : nextPage;
    const q = formatParams({ query }, page);
    PractitionerResource.list(q)
      .then((entries) => {
        const ids = entries.map((e) => e.id);
        setPages(getUpdatedPageDict(q.page, ids, pages));
        setNextPage(entries.length < q.page_size ? -1 : page + 1);
        setIsLoading(false);
      })
      .catch(() => setNextPage(-1));
  };

  const handleSelect = multiSelect
    ? (entries) => onSelect(entries.map((en) => en.id))
    : (entry) => onSelect(entry.id);

  const practitioners = pages
    ? PractitionerResource.pickMany(_.flatten(Object.values(pages)))
    : null;

  return (
    <ContentSelector
      contentName="Practitioner"
      onSearch={searchPractitioners}
      AnchorComponent={AnchorComponent}
      entries={practitioners}
      onSelect={handleSelect}
      CardComponent={PractitionerCard}
      isLoading={isLoading}
      multiSelect={multiSelect}
      hasMorePages={nextPage !== -1}
    />
  );
}

export default PractitionerSelector;
