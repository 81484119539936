/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { Box, Divider } from "@material-ui/core";
import { useEffect, useState, useRef, useContext } from "react";
import {
  Modal,
  Text,
  Button,
  Spinner,
  Tooltip,
  Icon,
  Dropdown,
  Chip,
  Input,
  ContentList,
  Badge,
  ErrorState,
  Checkbox,
} from "../../../design-library";
import { ExampleResource, TipResource, UserResource } from "../../../resources";
import { ExampleCard, ExampleForm, ExampleSider } from "../../Example";
import AdditionalFieldsForm, {
  additionalFields,
} from "../../Tip/CreateTipModal/AdditionalFieldsForm";
import StateSelector from "../../Tip/CreateTipModal/StateSelector";
import {
  TipCard,
  TipSelector,
  TipQuestions,
  TipByline,
  TipSummary,
} from "../../Tip";
import { StudentSelector } from "../../Student";
import { focusDom } from "../../utils";
import TipReviewForm from "../TipReviewForm";
import { StudentContext } from "../../../context";
import { getChildContextText, getEnvContextText } from "../TipDescription";

let focusTimeout;

function StudentSelectorAnchor({ onClick, style }) {
  return <Button label="Suggest" onClick={onClick} style={style} />;
}

function TipReaderRegular({
  tipId,
  onClose,
  initialSiderContent = {},
  onOpen = _.noop,
}) {
  const { studentId, stMap } = useContext(StudentContext);
  const sTip = stMap ? stMap[tipId] : null;

  const [siderContent, setSiderContent] = useState(initialSiderContent);
  const [modalContent, setModalContent] = useState({});
  const [mode, setMode] = useState();
  const [exampleForm, setExampleForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tipEditForm, setTipEditForm] = useState({});
  const [exampleEditForm, setExampleEditForm] = useState({});
  const [isQueued, setIsQueued] = useState(false); // Used in suggest-tip; isQueued=true -> Tip goes to the queue.
  const [error, setError] = useState();
  const [ratingForm, setRatingForm] = useState();

  const topRef = useRef();
  const bottomRef = useRef();
  const focusSelf = () => {
    clearTimeout(focusTimeout);
    focusTimeout = focusDom(topRef);
  };

  useEffect(() => {
    if (tipId) {
      setRatingForm();
      setMode();
      setSiderContent(initialSiderContent);
      setError();
      TipResource.getOne(tipId)
        .then((tip) => tip.loadExamples())
        .then((tip) => tip.loadQuestions())
        .then(onOpen);
    }
  }, [tipId]);

  useEffect(() => setExampleEditForm({}), [siderContent.example]);

  const tip = TipResource.pick(tipId);
  const submitRating = () => {
    setIsLoading(true);
    tip
      .addReview({ ...ratingForm, student: studentId })
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  const breadcrumbs = ["Tips"];
  if (tipId) {
    breadcrumbs.push(tipId);
  }

  const createExample = () => {
    setIsLoading(true);
    const form = { ...exampleForm };
    form.description = form.situation;
    form.heading = form.headline;
    form.tip = tipId;

    ExampleResource.createOne(form)
      .then((example) => {
        if (tip.examples) {
          tip.example_ids = [example.id, ...tip.example_ids];
          tip.updateStateObject();
        }

        setMode();
      })
      .finally(() => setIsLoading(false));
  };

  const updateTip = () => {
    const form = { ...tipEditForm };
    if (!form.title) form.title = undefined;

    setIsLoading(true);
    tip
      .update(form)
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  let footerContextMenu = [];

  if (siderContent.example) {
    footerContextMenu = [
      { label: "Edit Tip", onClick: () => setMode("edit-tip") },
      { label: "Edit Example", onClick: () => setMode("edit-example") },
    ];
  } else {
    footerContextMenu = [{ label: "Edit", onClick: () => setMode("edit-tip") }];
  }

  if (tip) {
    const ii = siderContent.example ? "tip " : ""; // Let's omit "tip" from the text if it is obvious
    const label = tip.marked_for_editing
      ? "Remove edit mark"
      : `Mark ${ii}for edit`;
    footerContextMenu.push({ label, onClick: () => tip.toggleEditMark() });
  }

  const updateExample = () => {
    setIsLoading(true);
    ExampleResource.pick(siderContent.example)
      ?.update(exampleEditForm)
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  const handleTryDropdown = (value) => {
    const body = { helpful: true, retryLater: true };
    if (value > 1) body.helpful = false;
    if (value > 2) body.retryLater = false;
    setIsLoading(true);
    tip?.try(body).finally(() => setIsLoading(false));
  };

  const isRated = tip?.is_rated;
  const eduPlusFeat = UserResource.current.isEducatorPlus && !!sTip;

  const footerActionsWithMode = [
    {
      Component: Button,
      label: "Save",
      disabled: mode === "rate-tip" && !ratingForm,
      isLoading,
      onClick: () => {
        if (mode === "add-example") return createExample();
        if (mode === "edit-tip") return updateTip();
        if (mode === "edit-example") return updateExample();
        if (mode === "rate-tip") return submitRating();
      },
    },
    {
      Component: Button,
      label: "Cancel",
      disabled: isLoading,
      onClick: () => setMode(),
    },
  ];

  const footerActions = [
    ...footerContextMenu,
    {
      hidden: !eduPlusFeat,
      Component: Dropdown,
      label: "I tried",
      title: "Was the tip helpful?",
      disabled: !tip?.loaded || !isRated,
      isLoading,
      onSelect: handleTryDropdown,
      options: [
        { label: "Yes", value: 1 },
        { label: "No, but will try again", value: 2 },
        { label: "No, won't try anymore", value: 3 },
      ],
    },
    {
      Component: !eduPlusFeat ? Button : null,
      disabled: !tip?.loaded,
      onClick: () => setMode("add-example"),
      label: "Add Example",
    },
    {
      Component: StudentSelector,
      AnchorComponent: StudentSelectorAnchor,
      label: "Suggest",
      onSelect: (ids) => tip?.suggest(ids, isQueued),
      multiSelect: true,
      customAction: UserResource.current.isAdmin && (
        <Box style={{ float: "left", height: "100%" }} display="flex">
          <Checkbox
            label="Add to tip queue"
            initialState={isQueued}
            onChange={(e, v) => setIsQueued(v)}
          />
        </Box>
      ),
    },
    {
      Component: Button,
      onClick: !_.isEmpty(siderContent) ? () => setSiderContent({}) : onClose,
      label: "Back",
    },
  ];

  return (
    <Modal
      open={!!tipId}
      onClose={onClose}
      breadcrumbs={breadcrumbs}
      footerActions={mode ? footerActionsWithMode : footerActions}
      sider={
        siderContent.example ? (
          <ExampleSider
            editMode={mode === "edit-example"}
            exampleId={siderContent.example}
            onFormUpdate={(form) => setExampleEditForm(form)}
            setModalContent={(content) =>
              content.tip === tipId ? focusSelf() : setModalContent(content)
            }
          />
        ) : siderContent.questions ? (
          <TipQuestions tip={tip} highlightedIds={siderContent.questions} />
        ) : null
      }
    >
      {error ? (
        <ErrorState size={12} />
      ) : tip ? (
        <>
          <Box ref={topRef}>
            {mode === "edit-tip" ? (
              <>
                <StateSelector
                  initialForm={{
                    levels: tip.levels,
                  }}
                  onChange={(form) =>
                    setTipEditForm({ ...tipEditForm, ...form })
                  }
                  style={{ marginBottom: "8px" }}
                />
                <Divider style={{ margin: "12px 0" }} />
              </>
            ) : (
              <>
                <Chip
                  filled
                  style={{ marginBottom: "8px" }}
                  color={tip.color}
                  content={<span>{_.capitalize(tip.motivator)}</span>}
                />
                {tip.marked_for_editing && (
                  <Tooltip content="Requires edit">
                    <Chip
                      filled
                      style={{ marginBottom: "8px", marginLeft: "4px" }}
                      color="#00B5B2"
                      content={
                        <Icon name="editMark" style={{ fontSize: "12px" }} />
                      }
                    />
                  </Tooltip>
                )}
                <Badge
                  content={tip.questions?.length}
                  max={9}
                  style={{ float: "right" }}
                >
                  <Button
                    icon="chat"
                    style={{ padding: "8px", margin: "-8px" }}
                    onClick={() => setSiderContent({ questions: [] })}
                  />
                </Badge>
              </>
            )}
            {mode === "edit-tip" ? (
              <>
                <Text type="label" content="Title" />
                <Input
                  placeholder="Enter tip title"
                  initialValue={tip.title}
                  onChange={(e) =>
                    setTipEditForm({ ...tipEditForm, title: e.target.value })
                  }
                  variant="standard"
                  fullWidth
                  style={{ marginTop: "6px" }}
                />
              </>
            ) : (
              <TipByline
                tip={tip}
                sTip={sTip}
                onRateClick={
                  eduPlusFeat &&
                  (() => {
                    setMode("rate-tip");
                    bottomRef.current.scrollIntoView({ behavior: "smooth" });
                  })
                }
              />
            )}
          </Box>
          <Divider style={{ margin: "20px 0" }} />
          {mode === "edit-tip" ? (
            <Box>
              <Box display="flex">
                <Text
                  type="label"
                  content="Tip Summary"
                  style={{ marginBottom: "12px" }}
                />
              </Box>
              <Input
                onChange={(e) =>
                  setTipEditForm({
                    ...tipEditForm,
                    tip_summary: e.target.value,
                  })
                }
                initialValue={tip.tip_summary}
                placeholder="Enter tip summary"
                shrinkLabel
                fullWidth
                fullHeight
                multiline
              />
              <Box display="flex">
                <Text
                  type="label"
                  content="Child Context"
                  style={{ marginBottom: "12px", marginTop: "12px" }}
                />
              </Box>
              <Input
                onChange={(e) =>
                  setTipEditForm({
                    ...tipEditForm,
                    child_context_flattened: e.target.value,
                  })
                }
                initialValue={getChildContextText(tip)}
                placeholder="Enter child context"
                shrinkLabel
                fullWidth
                fullHeight
                multiline
              />
              <Divider style={{ margin: "12px 0", width: "100%" }} />
              <Box display="flex">
                <Text
                  type="label"
                  content="Environmental Context"
                  style={{ marginBottom: "12px" }}
                />
              </Box>
              <Input
                onChange={(e) =>
                  setTipEditForm({
                    ...tipEditForm,
                    environment_context_flattened: e.target.value,
                  })
                }
                initialValue={getEnvContextText(tip)}
                placeholder="Enter environment context"
                shrinkLabel
                fullWidth
                fullHeight
                multiline
              />
              <Divider style={{ margin: "12px 0", width: "100%" }} />
              <Text
                type="label"
                content="Additional Fields"
                style={{ marginBottom: "12px" }}
              />
              <AdditionalFieldsForm
                initialValue={_.pick(
                  tip,
                  additionalFields.map((f) => f.dataKey)
                )}
                onChange={(af) => setTipEditForm({ ...tipEditForm, ...af })}
              />
            </Box>
          ) : (
            <Box>
              <TipSummary tip={tip} />
              <Divider style={{ margin: "20px 0" }} />
              {mode === "rate-tip" ? (
                <>
                  <Text
                    content="Please rate the tip:"
                    style={{ fontWeight: "bold" }}
                  />
                  <TipReviewForm
                    style={{ marginTop: "8px" }}
                    onChange={(form) => setRatingForm(form)}
                  />
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    marginBottom="10px"
                  >
                    <Text type="label" content="Related Tips" />
                    <TipSelector
                      AnchorComponent={({ onClick }) => (
                        <Button
                          icon="plus"
                          style={{
                            padding: "8px",
                            margin: "-8px",
                            height: "100%",
                          }}
                          onClick={onClick}
                        />
                      )}
                      onSelect={(tipIds) => tip?.linkTips(tipIds)}
                      multiSelect
                    />
                  </Box>
                  <ContentList
                    entries={tip.relatedTips}
                    CardComponent={TipCard}
                    onEntryClick={(entry) => setModalContent({ tip: entry.id })}
                    onEntryCross={(entry) => tip?.unlinkTips([entry.id])}
                  />
                  <Divider style={{ margin: "20px 0" }} />
                  {tip && mode === "add-example" ? (
                    <ExampleForm onUpdate={(form) => setExampleForm(form)} />
                  ) : (
                    <>
                      <Text
                        type="label"
                        content="Examples"
                        style={{ marginBottom: "10px" }}
                      />
                      <ContentList
                        entries={tip.examples}
                        CardComponent={ExampleCard}
                        onEntryClick={(ex) =>
                          setSiderContent({ example: ex.id })
                        }
                        highlightedIds={[siderContent.example]}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          )}

          <TipReaderRegular
            tipId={modalContent.tip}
            onClose={() => setModalContent({})}
            onOpen={onOpen}
          />
        </>
      ) : (
        <Spinner size={30} style={{ height: "100%" }} />
      )}
      <span ref={bottomRef} />
    </Modal>
  );
}

export default TipReaderRegular;
