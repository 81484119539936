import { Text } from "../../../design-library";

function ToggleExampleBookmarkEntry({ meta, onClick }) {
  return meta.is_bookmarked ? (
    <Text
      content={
        <>
          bookmarked example &nbsp;
          <b>#{meta.example}</b>. .
        </>
      }
    />
  ) : (
    <Text
      content={
        <>
          removed example &nbsp;
          <b>#{meta.example}</b>. &nbsp; from bookmark.
        </>
      }
    />
  );
}

export default ToggleExampleBookmarkEntry;
