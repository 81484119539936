/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import { useState } from "react";
import { ContentList } from "../../design-library";
import { QuestionStamp } from "../Question";
import { TipDisplayModal } from "../Tip";

function PractitionerQuestions({ practitioner }) {
  const [modalTipId, setModalTipId] = useState();

  return (
    <Box>
      <ContentList
        entries={practitioner?.questions}
        CardComponent={QuestionStamp}
        onEntryClick={(entry, e) => setModalTipId(e.tip)}
        emptyState={10}
      />
      <TipDisplayModal tipId={modalTipId} onClose={() => setModalTipId()} />
    </Box>
  );
}

export default PractitionerQuestions;
