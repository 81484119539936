import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import {
  Button,
  ContentList,
  Modal,
  Spinner,
  Text,
  Badge,
  Divider,
} from "../../design-library";
import { Box, Grid } from "@material-ui/core";
import {
  PractitionerActivities,
  PractitionerBio,
  PractitionerByline,
  PractitionerQuestions,
  PractitionerUpdateModal,
} from ".";
import { PractitionerResource, UserResource } from "../../resources";
import { StudentCard, StudentSelector } from "../Student";
import { useHistory } from "react-router";

function PractitionerDisplayModal({ practitionerId, onClose }) {
  const [siderContent, setSiderContent] = useState({});
  const [modalContent, setModalContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const breadcrumbs = ["Practitioners"];
  if (practitionerId) breadcrumbs.push(practitionerId);

  useEffect(() => {
    if (practitionerId) {
      setSiderContent({});
      PractitionerResource.getOne(practitionerId).then((prac) =>
        prac.loadQuestions()
      );
    }
  }, [practitionerId]);

  const practitioner = PractitionerResource.pick(practitionerId);

  const loginAs = () => {
    setIsLoading(true);
    practitioner
      .getShortTermJWT()
      .then((jwt) => {
        const newWindow = window.open(
          `/home?jwt=${jwt}`,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      })
      .catch((e) => UserResource.alert.error(e.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={!!practitionerId}
      onClose={onClose}
      breadcrumbs={breadcrumbs}
      sider={
        siderContent.questions ? (
          <PractitionerQuestions practitioner={practitioner} />
        ) : null
      }
      footer={
        <Grid container justifyContent="space-between">
          <Grid item style={{ display: "flex" }}>
            {practitioner?.role !== UserResource.ROLES.ADMIN && (
              <Button
                label="Login As"
                inline
                isLoading={isLoading}
                style={{ marginLeft: "-4px" }}
                onClick={loginAs}
              />
            )}
          </Grid>
          <Grid item style={{ display: "flex" }}>
            <Button
              label="Back"
              onClick={() =>
                _.isEmpty(siderContent) ? onClose() : setSiderContent({})
              }
            />
            <Button
              label="Edit"
              style={{ marginLeft: "10px" }}
              primary
              onClick={() => setModalContent({ edit: true })}
            />
          </Grid>
        </Grid>
      }
    >
      {practitioner ? (
        <Fragment>
          <Badge
            content={practitioner.questions?.length}
            max={9}
            style={{ float: "right" }}
          >
            <Button
              icon="chat"
              style={{ padding: "8px", margin: "-8px" }}
              onClick={() => setSiderContent({ questions: [] })}
            />
          </Badge>
          <PractitionerByline practitioner={practitioner} />
          <Divider />
          <Text type="label" content="Bio" style={{ margin: "12px 0" }} />
          <PractitionerBio practitioner={practitioner} />
          {practitioner.role === UserResource.ROLES.DLP && (
            <>
              <Text
                type="label"
                content="Professional Goal"
                style={{ margin: "12px 0 0 0" }}
              />
              <Text
                content={
                  practitioner.professional_goal?.split("\n").map((entry) => (
                    <Fragment key={Math.random()}>
                      &bull; {entry}
                      <br />
                    </Fragment>
                  )) || "N/A"
                }
              />
            </>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ margin: "12px 0" }}
          >
            <Text type="label" content="Assigned Students" />
            <StudentSelector
              AnchorComponent={(props) => (
                <Button
                  icon="plus"
                  style={{ padding: "8px", margin: "-8px", height: "100%" }}
                  {...props}
                />
              )}
              multiSelect
              onSelect={(ids) => practitioner?.assignStudents(ids)}
            />
          </Box>
          <ContentList
            folds={2}
            entries={practitioner.assignedStudents}
            CardComponent={StudentCard}
            onEntryClick={(st) => history.push(`/students/${st.id}/tips`)}
            onEntryCross={(entry) => practitioner.unassignStudents([entry.id])}
          />
          <Text
            type="label"
            content="Recent Activities"
            style={{ margin: "12px 0" }}
          />
          <PractitionerActivities practitioner={practitioner} />
        </Fragment>
      ) : (
        <Spinner size={30} style={{ height: "100%" }} />
      )}
      <PractitionerUpdateModal
        practitioner={modalContent.edit && practitioner}
        onClose={() => setModalContent({})}
      />
    </Modal>
  );
}

export default PractitionerDisplayModal;
