import { SvgIcon } from "@material-ui/core";

function Lock(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M5.91304 22H18.087C18.5912 22 19 21.593 19 21.0909V10.7879C19 10.2858 18.5912 9.87879 18.087 9.87879H17.1739V7.15151C17.1739 4.31097 14.8529 2 12 2C9.1471 2 6.82615 4.31097 6.82615 7.15151V9.87879H5.91304C5.4088 9.87879 5 10.2858 5 10.7879V21.0909C5 21.593 5.4088 22 5.91304 22ZM8.65223 7.15151C8.65223 5.31351 10.1541 3.81818 12 3.81818C13.846 3.81818 15.3478 5.31351 15.3478 7.15151V9.87879H8.65223V7.15151Z" />
      </svg>
    </SvgIcon>
  );
}

export default Lock;
