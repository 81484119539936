import { colors, createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00B5B2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#B5B5B5",
    },
    default: {
      main: colors.green[500],
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: "Lato",
  },
  overrides: {
    MuiTab: {
      root: {
        minHeight: 40,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
        boxShadow: "none",
      },
    },
  },
});

export default theme;
