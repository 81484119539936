import { Text } from "../../../design-library";

function ReadTipEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          read tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; for{" "}
          <b>
            {meta.minutes} minute{meta.minutes > 1 ? "s" : ""}
          </b>
          .
        </>
      }
    />
  );
}

export default ReadTipEntry;
