import PropTypes from "prop-types";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Tooltip } from "..";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  label: {
    lineHeight: 0.8,
  },
  labelShrinked: {
    lineHeight: 1,
    marginLeft: "-2px",
  },
}));

function MySelect({
  options = [],
  value,
  onChange = _.noop,
  fullWidth = false,
  label,
  style = {},
}) {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth={fullWidth}
      style={style}
    >
      <InputLabel
        classes={{ root: classes.label, shrink: classes.labelShrinked }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        style={{ color: "#686868", fontSize: "14px" }}
      >
        {options.map(({ label, value, tooltip }) => (
          <MenuItem
            style={{ color: "#686868", fontSize: "14px" }}
            value={value}
            key={label}
          >
            <Tooltip placement="left" content={tooltip}>
              <Box width="100%">{label}</Box>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

MySelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  value: PropTypes.any,
};

export default MySelect;
