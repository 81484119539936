import PropTypes from "prop-types";
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  tooltip: {
    fontSize: "12px",
    borderRadius: "3px",
  },
});

function MyTooltip({ children, content, placement = "top" }) {
  const classes = useStyles();
  return (
    <Tooltip
      classes={classes}
      title={content || ""}
      placement={placement}
      arrow
    >
      {children}
    </Tooltip>
  );
}

MyTooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
  placement: PropTypes.string,
};
export default MyTooltip;
