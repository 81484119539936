import PropTypes from "prop-types";
import { makeStyles, MenuItem } from "@material-ui/core";

import { Icon, Tooltip } from "..";
import { forwardRef } from "react";

const useStyles = makeStyles({
  root: {
    fontWeight: "bold",
    color: "#555",
  },
  selected: {
    backgroundColor: "#00B5B222 !important",
    border: 0,
    color: "#00B5B2",
  },
});

const MyMenuItem = forwardRef(
  (
    { selected = false, label, icon, style = {}, onClick, tooltip, ...props },
    ref
  ) => {
    const classes = useStyles();

    const elem = (
      <MenuItem
        ref={ref}
        selected={selected}
        classes={classes}
        style={style}
        onClick={onClick}
        {...props}
      >
        {icon && (
          <Icon name={icon} size={6} style={{ marginRight: label ? 8 : 0 }} />
        )}
        {label}
      </MenuItem>
    );

    return tooltip ? <Tooltip content={tooltip}>{elem}</Tooltip> : elem;
  }
);

MyMenuItem.propTypes = {
  selected: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
};

export default MyMenuItem;
