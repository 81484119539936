/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import {
  SearchBar,
  DateRangePicker,
  Spinner,
  Emptyness,
  FilterPanel,
} from "../../design-library";
import { ActivityResource } from "../../resources";
import { ActivityLog } from "../../shared-components/Activity";
import { activityFilters } from "../../shared-components/Tip/filters";
import { useURLState } from "../../shared-components/utils";

const defaultRange = {
  start_date: moment().subtract(6, "weeks").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};

function formatParams(params, page) {
  // If 'types' is empty, API returns all types
  // But we want empty response in this case. To achieve that,
  // We are sending a non-existent 'no-type' value
  const types = params.types === "" ? "no-type" : params.types;

  return {
    query: params.query,
    start_date: params.start_date || defaultRange.start_date,
    end_date: params.end_date || defaultRange.end_date,
    page: page || 1,
    page_size: 25,
    ordering: "-id",
    types,
  };
}

function ActivityTab() {
  const config = ActivityResource.getConfig("ACTIVITY_TAB_CONFIG");

  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState();
  const [params, setParams] = useURLState(config.query, [
    "query",
    "start_date",
    "end_date",
  ]);

  const loadNextActivities = (fromFirst = true) => {
    setIsLoading(true);
    const page = fromFirst ? 1 : nextPage;
    const fparams = formatParams(params, page);
    ActivityResource.list(fparams, "ACTIVITY_TAB_CONFIG")
      .then((entries) => {
        setNextPage(entries.length < 25 ? -1 : page + 1);
        setIsLoading(false);
      })
      .catch(() => setNextPage(-1));
  };

  useEffect(loadNextActivities, [params]);

  const activities = ActivityResource.pickMany(config.ids);

  return (
    <Box height="100%">
      <Box height="60px">
        <Grid container justifyContent="space-between">
          <Grid item style={{ display: "flex" }}>
            <SearchBar
              initialValue={params.query || ""}
              onSearch={(q) => setParams({ ...params, query: q })}
              placeholder="Search a practitioner"
            />
            <DateRangePicker
              style={{ marginLeft: "12px" }}
              noBorder
              range={{
                startDate: new Date(
                  params.start_date || defaultRange.start_date
                ),
                endDate: new Date(params.end_date || defaultRange.end_date),
              }}
              onChange={({ startDate, endDate }) =>
                setParams({
                  ...params,
                  start_date: moment(startDate).format("YYYY-MM-DD"),
                  end_date: moment(endDate).format("YYYY-MM-DD"),
                })
              }
            />
            <FilterPanel
              filters={activityFilters}
              onChange={({ types }) =>
                setParams({ ...params, types: types.join(",") })
              }
              style={{ marginLeft: "8px" }}
            />
            {isLoading && (
              <Spinner
                style={{
                  display: "inline-flex",
                  height: "40px",
                  marginLeft: "8px",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        height="calc(100% - 60px)"
        overflow="auto"
        position="relative"
        paddingLeft="3px"
        marginLeft="-3px"
      >
        {!activities ? (
          <Spinner style={{ height: "100%" }} size={30} />
        ) : activities.length === 0 ? (
          <Emptyness />
        ) : (
          <Box
            style={{ background: "white", paddingTop: "6px" }}
            minHeight="100%"
          >
            <ActivityLog entries={activities} showUser />
            {!isLoading && nextPage !== -1 && (
              <Waypoint onEnter={() => loadNextActivities(false)} />
            )}
            {nextPage > 1 && isLoading && (
              <Spinner style={{ height: "40px" }} label="Loading more..." />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ActivityTab;
