import { useContext, useEffect, useState } from "react";

import { Button, Modal, Spinner } from "../../design-library";
import { AppContext } from "../../context";
import { Box } from "@material-ui/core";

import { CelebrationForm, CelebrationDetails } from ".";
import _ from "lodash";
import { CelebrationResource } from "../../resources";

function CelebrationDisplayModal({ celebrationId, onClose }) {
  const {
    appState: { celebrationsMap },
  } = useContext(AppContext);

  const [isBusy, setIsBusy] = useState(false);
  const [mode, setMode] = useState();
  const [celebrationEditForm, setCelebrationEditForm] = useState({});

  const breadcrumbs = ["Celebrations"];
  if (celebrationId) {
    breadcrumbs.push(celebrationId);
  }
  const celebration =
    celebrationsMap && celebrationId ? celebrationsMap[celebrationId] : null;

  useEffect(() => {
    if (celebrationId) CelebrationResource.getOne(celebrationId);
  }, [celebrationId]);

  const updateCelebration = () => {
    setIsBusy(true);
    celebration
      .update(celebrationEditForm)
      .then(() => setMode())
      .finally(() => setIsBusy(false));
  };

  return (
    <Modal
      open={!!celebrationId}
      onClose={onClose}
      breadcrumbs={breadcrumbs}
      footer={
        <Box textAlign="right">
          {mode ? (
            <>
              <Button
                label="Cancel"
                onClick={() => setMode()}
                style={{ marginRight: "10px" }}
                disabled={isBusy}
              />
              <Button
                isLoading={isBusy}
                label="Save"
                primary
                onClick={() => updateCelebration()}
              />
            </>
          ) : (
            <>
              <Button
                icon="edit"
                style={{
                  float: "left",
                  marginLeft: "-12px",
                }}
                onClick={() => setMode("edit")}
              />
              <Button label="Back" onClick={onClose} primary />
            </>
          )}
        </Box>
      }
    >
      {celebration ? (
        mode === "edit" ? (
          <CelebrationForm
            initialValues={_.pick(celebration, ["headline", "celebration"])}
            onUpdate={(form) => setCelebrationEditForm(form)}
          />
        ) : (
          <CelebrationDetails celebration={celebration} />
        )
      ) : (
        <Spinner />
      )}
    </Modal>
  );
}

export default CelebrationDisplayModal;
