import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  subtext: {
    color: "#93999A",
    fontSize: "12px",
    marginBottom: "2px",
  },
  root: {
    borderLeft: "3px solid #3d6e8d",
    backgroundColor: ({ selected }) => (selected ? "	#3d6e8d" : "#3d6e8d22"),
    cursor: ({ onClick }) => (onClick ? "pointer" : "default"),
    color: ({ selected }) => (selected ? "white" : "#686868"),
    fontSize: "14px",
    opacity: ({ struggle }) => (struggle.is_resolved ? 0.5 : 1),
    padding: "8px",
    marginBottom: "3px",
  },
}));

function StruggleCard({ subtext, entry, struggle, selected, onClick }) {
  struggle = entry || struggle;

  const classes = useStyles({ struggle, selected, onClick });

  return (
    <Box className={classes.root} onClick={onClick}>
      {subtext && (
        <span className={["truncate-1", classes.subtext].join(" ")}>
          {subtext}
        </span>
      )}
      <span className="truncate-1">{struggle.headline}</span>
    </Box>
  );
}

export default StruggleCard;
