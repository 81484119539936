import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { ContentSelector, Text } from "../../design-library";
import { TipResource } from "../../resources";
import { TipDetails, standardTipFilters, TipCard, CreateTipModal } from ".";
import _ from "lodash";
import { getUpdatedPageDict } from "../utils";

function Anchor({ onClick }) {
  return <Text content="Assign Tip" type="clickable" onClick={onClick} />;
}

function Detail({ entry }) {
  const {
    appState: { tipsMap },
  } = useContext(AppContext);

  const tip = tipsMap[entry.id];

  useEffect(() => tip?.id && TipResource.getOne(tip.id), [tip?.id]);
  return <TipDetails tip={tip} />;
}

function formatParams(params, page) {
  return {
    search_text: params.query,
    page: page || 1,
    page_size: params.pageSize || 20,
  };
}

function TipSelector({ AnchorComponent = Anchor, onSelect, multiSelect }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateTipModal, setShowCreateTipModal] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [pages, setPages] = useState();

  const searchTips = (query, fromFirst = true) => {
    setIsLoading(true);

    const page = fromFirst ? 1 : nextPage;
    const q = formatParams({ query }, page);
    TipResource.list(q)
      .then((entries) => {
        const ids = entries.map((e) => e.id);
        setPages(getUpdatedPageDict(q.page, ids, pages));
        setNextPage(entries.length < q.page_size ? -1 : page + 1);
        setIsLoading(false);
      })
      .catch(() => setNextPage(-1));
  };

  const handleSelect = multiSelect
    ? (entries) => onSelect(entries.map((en) => en.id))
    : (entry) => onSelect(entry.id);

  const tips = pages
    ? TipResource.pickMany(_.flatten(Object.values(pages)))
    : null;

  return (
    <>
      <ContentSelector
        contentName="Tip"
        onSearch={searchTips}
        AnchorComponent={AnchorComponent}
        entries={tips}
        onSelect={handleSelect}
        onAdd={() => setShowCreateTipModal(true)}
        CardComponent={TipCard}
        isLoading={isLoading}
        filters={standardTipFilters}
        multiSelect={multiSelect}
        DetailComponent={Detail}
        hasMorePages={nextPage !== -1}
      />
      <CreateTipModal
        open={showCreateTipModal}
        onClose={() => setShowCreateTipModal(false)}
        onCreate={(tip) => {
          const page1 = pages[1] || [];
          page1.unshift(tip.id);
          setPages({ ...pages, 1: page1 });
        }}
      />
    </>
  );
}

export default TipSelector;
