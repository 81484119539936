import axios from "axios";
import cookie from "js-cookie";
import _ from "lodash";
import moment from "moment";
import { pluralize } from "../shared-components/utils";

export class APIError {
  constructor(status, data = {}) {
    const causes = Object.keys(data).map(
      (key) => `[Error: ${key}] ${data[key]}`
    );
    causes.push(`API Error: Status ${status}`);

    this.status = status;
    this.causes = causes;
    this.message = causes[0];
  }
}

export default class BaseResource {
  static __baseUrl = process.env.REACT_APP_API_URL || "lsgrid-api-dev.com";
  static __cancelTokens = {};
  static __requiredFields = {
    createOne: [],
    update: [],
  };
  static appContext = null;
  static get isProd() {
    return this.__baseUrl === "lsgrid-api.com";
  }

  assign(data) {
    this.__properties.forEach((key) => {
      if (key in data) this[key] = data[key];
    });
  }

  static mapById(objList) {
    const map = {};
    objList.forEach((obj) => (map[obj.id] = obj));
    return map;
  }

  get __properties() {
    return ["id"];
  }

  get __resource() {
    return "";
  }

  get __endpoint() {
    return "";
  }

  static __request(method, api, ...args) {
    const url = `https://${this.__baseUrl}${api}`;
    const cancelTokenKey = `${method} ${url}`;
    this.__cancelTokens[cancelTokenKey]?.cancel();

    const source = axios.CancelToken.source();
    this.__cancelTokens[cancelTokenKey] = source;

    const config = args[args.length - 1];
    config.cancelToken = source.token;
    config.headers = config.headers || {};

    if (config.headers.Authorization === undefined) {
      const jwt = this.appContext.appState.authToken || cookie.get("token");
      if (jwt) config.headers.Authorization = `JWT ${jwt}`;
    }

    return axios[method](url, ...args)
      .then((res) => {
        delete this.__cancelTokens[cancelTokenKey];
        return res.data;
      })
      .catch((e) => {
        const resp = e.response;
        if (!resp) throw e;

        const data =
          resp.headers["content-type"] !== "application/json" ? {} : resp.data;
        throw new APIError(resp.status, data);
      });
  }

  static get http() {
    return {
      get: (api, params) => this.__request("get", api, { params }),
      post: (api, params, body, headers) =>
        this.__request("post", api, body, { params, headers }),
      patch: (api, params, body) =>
        this.__request("patch", api, body, { params }),
      delete: (api, params) => this.__request("delete", api, { params }),
    };
  }

  static async getOne(id, endpoint) {
    const res = await this.http.get(
      `${endpoint || this.prototype.__endpoint}/${id}/`
    );
    const data = this.responseProcessor_getOne(res);

    const obj = this.toObject(data);
    obj.updateStateObject();

    return obj;
  }

  static responseProcessor_getOne(data) {
    return data;
  }

  static async list(params, dispatchKey, endpoint) {
    // Need to add 1 day to extend the end-date till 23:59:59
    let end_date;
    if (params.end_date) {
      end_date = moment(params.end_date).add(1, "days").format("YYYY-MM-DD");
    }

    const res = await this.http.get(
      `${endpoint || this.prototype.__endpoint}/`,
      {
        page_size: 20,
        ordering: "-id",
        ...params,
        end_date,
      }
    );
    const data = this.responseProcessor_list(res.results);

    const objs = data.map((data) => this.toObject(data));
    this.storeStateObjects(objs);

    if (dispatchKey) this.storeConfig(objs, params, dispatchKey);

    return objs;
  }

  static responseProcessor_list(data) {
    return data;
  }

  static async createOne(data, skipAlert = false, skipTrack = false) {
    if (!this.validate(data, this.__requiredFields.createOne || [])) {
      return Promise.reject();
    }

    try {
      const res = await this.http.post(
        `${this.prototype.__endpoint}/`,
        {},
        data
      );
      const obj = this.toObject(res);
      obj.is_new = true;
      obj.updateStateObject();

      if (!skipAlert) {
        const msg = "Resource created.";
        this.alert.success(msg);
      }

      if (!skipTrack) this.track("create", obj);

      return obj;
    } catch (err) {
      if (!skipAlert) this.alert.error(err.message);

      throw err;
    }
  }

  async update(data, skipAlert = false, skipTrack = false) {
    if (
      !this.constructor.validate(
        data,
        this.constructor.__requiredFields.update || [],
        true
      )
    ) {
      return Promise.reject();
    }

    try {
      const res = await this.constructor.http.patch(
        `${this.__endpoint}/${this.id}/`,
        {},
        data
      );

      const obj = this.constructor.toObject(res);
      obj.updateStateObject();

      if (!skipAlert) {
        const msg = "Resource updated.";
        this.constructor.alert.success(msg);
      }

      if (!skipTrack) this.constructor.track("edit", obj);

      return obj;
    } catch (err) {
      if (!skipAlert) this.constructor.alert.error(err.message);

      throw err;
    }
  }

  updateStateObject() {
    BaseResource.appContext.appDispatch({
      type: `${this.__resource}:UPDATE`,
      payload: this,
    });
  }

  static storeStateObjects(objs) {
    const storeName = `${pluralize(_.camelCase(this.prototype.__resource))}Map`;

    this.appContext.appDispatch({
      type: `${this.prototype.__resource}S:SET`,
      payload: {
        ...this.appContext.appState[storeName],
        ...this.mapById(objs),
      },
    });
  }

  static storeConfig(objs, params, dispatchKey) {
    const state = this.appContext.appState[_.camelCase(dispatchKey)];

    let pages = state.pages;
    if (params.page > 1) {
      pages[params.page] = objs.map((ep) => ep.id);
    } else {
      pages = { 1: objs.map((ep) => ep.id) };
    }

    this.appContext.appDispatch({
      type: `${dispatchKey}:SET`,
      payload: {
        query: _.omit(params, ["page", "page_size", "ordering"]),
        pages,
      },
    });
  }

  static getConfig(dispatchKey) {
    const state = this.appContext.appState[_.camelCase(dispatchKey)];
    return {
      ...state,
      ids: state.pages && _.flatten(Object.values(state.pages)),
    };
  }

  static pick(id) {
    const storeName = `${pluralize(_.camelCase(this.prototype.__resource))}Map`;
    const store = this.appContext.appState[storeName];
    return store && store[id];
  }

  static pickMany(ids) {
    if (!ids) return null;

    const storeName = `${pluralize(_.camelCase(this.prototype.__resource))}Map`;
    const store = this.appContext.appState[storeName];

    return store && ids.map((id) => store[id]).filter(Boolean);
  }

  async reload() {
    await this.constructor.getOne(this.id);
  }

  static get alert() {
    const showAlert = this.appContext.showAlert;

    return {
      success: (msg) => showAlert({ type: "success", message: msg }),
      error: (msg) => showAlert({ type: "error", message: msg }),
      warning: (msg) => showAlert({ type: "warning", message: msg }),
    };
  }

  static validate(form, requiredFields, partialForm = false) {
    for (const { key, label } of requiredFields) {
      if (form[key] === undefined && partialForm) continue;

      if (!form[key]) {
        this.appContext.showAlert({
          type: "warning",
          message: `'${label}' field cannot be empty`,
        });
        return false;
      }
    }
    return true;
  }

  static track(action, obj) {}

  static toObject(data) {
    const obj = Object.create(this.prototype);
    obj.assign(data);
    return obj;
  }
}
