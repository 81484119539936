import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Avatar, Spinner, Text } from "../../design-library";
import TipsTab from "./TipsTab";
import PrintNProgressTab from "./PrintNProgressTab";
import EpisodesTab from "./EpisodesTab";
import { StudentResource } from "../../resources";
import StrugglesTab from "./StrugglesTab";
import CelebrationTab from "./CelebrationTab";

const useStyles = makeStyles(() => ({
  top: {
    height: "100px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    "@media print": {
      display: "none !important",
    },
  },
  tabs: {
    height: "60px",
    "@media print": {
      display: "none !important",
    },
  },
}));

export default function Page() {
  const [isLoading, setIsLoading] = useState(true);

  const { id, tab } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const student = StudentResource.pick(id);

  useEffect(() => {
    setIsLoading(true);
    StudentResource.getOne(id).finally(() => setIsLoading(false));
  }, [id]);

  useEffect(() => {
    document.title = `Learning Seeds | ${student?.nickname}`;
  }, [student?.nickname]);

  return (
    <Box height="100%">
      <Box className={classes.top}>
        {student ? (
          <Box height="100%" display="flex">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar size={7} name={student.nickname} image={student.image} />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box>
                <Text
                  type="title"
                  content={student.nickname}
                  style={{ margin: "0 0 0 20px" }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Spinner size={24} style={{ justifyContent: "flex-start" }} />
        )}
      </Box>
      <Box className={classes.tabs}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          style={{ backgroundColor: "white" }}
          onChange={(e, tab) => history.push(`/students/${student.id}/${tab}`)}
        >
          <Tab label="Tips" value="tips" />
          <Tab label="Episodes" value="episodes" />
          <Tab label="Struggles" value="struggles" />
          <Tab label="Celebrations" value="celebrations" />
          <Tab label="Print & Progress" value="print-n-progress" />
        </Tabs>
      </Box>
      <Box height="calc(100% - 160px)">
        {!isLoading && student ? (
          <>
            {tab === "tips" && <TipsTab student={student} />}
            {tab === "episodes" && <EpisodesTab student={student} />}
            {tab === "struggles" && <StrugglesTab student={student} />}
            {tab === "celebrations" && <CelebrationTab student={student} />}
            {tab === "print-n-progress" && (
              <PrintNProgressTab student={student} />
            )}
          </>
        ) : (
          <Spinner size={30} style={{ height: "100%" }} />
        )}
      </Box>
    </Box>
  );
}
