import { Text } from "../../../design-library";

function AskQuestionEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          asked a question about tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          .
        </>
      }
    />
  );
}

export default AskQuestionEntry;
