import { SvgIcon } from "@material-ui/core";

function Admin(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.9998 14.059C11.1671 14.059 10.4678 14.6389 10.2832 15.4159C11.3608 15.9597 12.6384 15.9599 13.7165 15.4159C13.5319 14.6389 12.8325 14.059 11.9998 14.059V14.059Z" />
      <path d="M12.0013 8.17639C9.89295 8.17639 8.17773 9.89161 8.17773 11.9999C8.17773 13.0481 8.60181 13.9991 9.28734 14.6905C9.52244 14.1294 9.92687 13.6566 10.4361 13.3352C10.1286 12.9754 9.94244 12.5091 9.94244 11.9999C9.94244 10.8647 10.866 9.9411 12.0013 9.9411C13.1365 9.9411 14.0601 10.8647 14.0601 11.9999C14.0601 12.5091 13.8739 12.9754 13.5665 13.3352C14.0757 13.6566 14.4801 14.1294 14.7152 14.6905C15.4007 13.9991 15.8248 13.0482 15.8248 11.9999C15.8248 9.89161 14.1096 8.17639 12.0013 8.17639V8.17639Z" />
      <path d="M11.9986 12.8821C12.4859 12.8821 12.8809 12.4871 12.8809 11.9998C12.8809 11.5125 12.4859 11.1174 11.9986 11.1174C11.5113 11.1174 11.1162 11.5125 11.1162 11.9998C11.1162 12.4871 11.5113 12.8821 11.9986 12.8821Z" />
      <path d="M22 13.6748V10.3252L19.3502 9.88357C19.192 9.33286 18.972 8.80275 18.6933 8.29996L20.2553 6.11318L17.8868 3.74467L15.7 5.30667C15.1973 5.028 14.6671 4.80808 14.1164 4.6498L13.6748 2H10.3252L9.88357 4.6498C9.3329 4.80808 8.80267 5.02804 8.29996 5.30667L6.11318 3.74467L3.74467 6.11318L5.30667 8.29996C5.028 8.80271 4.80804 9.3329 4.6498 9.88357L2 10.3252V13.6748L4.6498 14.1164C4.80808 14.6671 5.028 15.1973 5.30667 15.7L3.74467 17.8868L6.11318 20.2553L8.29996 18.6933C8.80275 18.972 9.33286 19.192 9.88357 19.3502L10.3252 22H13.6748L14.1164 19.3502C14.6671 19.192 15.1973 18.972 15.7 18.6933L17.8868 20.2553L20.2553 17.8868L18.6933 15.7C18.972 15.1973 19.192 14.6671 19.3502 14.1164L22 13.6748ZM12 17C9.24298 17 7 14.757 7 12C7 9.24298 9.24298 7 12 7C14.757 7 17 9.24298 17 12C17 14.757 14.757 17 12 17Z" />
    </SvgIcon>
  );
}

export default Admin;
