import _ from "lodash";
import { ActivityResource, QuestionResource, StudentResource } from ".";
import { depaginate, paginate } from "../shared-components/utils";
import Base from "./base";
import ExampleResource from "./example";

const motivatorMap = {
  1: "Avoidance motivation",
  2: "Object/sensory motivation",
  3: "Reaction motivation",
  4: "Interaction flow motivation",
  5: "Planning motivation",
};

export default class TipResource extends Base {
  static __requiredFields = {
    createOne: [{ key: "title", label: "Title" }],
    update: [],
  };

  static COLORS = {
    1: "#F05B70",
    2: "#EAA533",
    3: "#b2b347",
    4: "#81C77F",
    5: "#819BD0",
  };

  get __properties() {
    return [
      "id",
      "levels",
      "title",
      "updated_by",
      "updated",
      "created_by",
      "created_at",
      "helpful",
      "howto",
      "sub_goal",
      "overarching_goal",
      "description",
      "persuasive",
      "child_context",
      "child_context_flattened",
      "environment_context",
      "environment_context_flattened",
      "example_ids",
      "linked_tips",
      "when",
      "reviews",
      "average_rating",
      "clarity_average_rating",
      "uniqueness_average_rating",
      "relevance_average_rating",
      "marked_for_editing",
      "try_count",
      "read_count",
      "helpful_count",
      "is_rated",
      "questionPages",
      "tip_summary",
    ];
  }

  get __resource() {
    return "TIP";
  }

  get __endpoint() {
    return "/v1/tips";
  }

  static track(action, obj, meta) {
    switch (action) {
      case "create":
        return ActivityResource.activities.CREATE_TIP({ tip: obj.id });
      case "edit":
        return ActivityResource.activities.EDIT_TIP({ tip: obj.id });
      case "read":
        return ActivityResource.activities.READ_TIP({
          tip: obj.id,
          minutes: meta.minutes,
        });
      case "suggest":
        return ActivityResource.activities.SUGGEST_TIP({
          tip: obj.id,
          students: meta.students,
        });
      case "rate":
        return ActivityResource.activities.RATE_TIP({
          tip: obj.id,
          ...meta,
        });
      case "try":
        return ActivityResource.activities.TRY_TIP({
          tip: obj.id,
          ...meta,
        });
      case "set_edit_mark":
        return ActivityResource.activities.TOGGLE_TIP_EDIT_MARK({
          tip: obj.id,
          is_marked: meta.is_marked,
        });
      case "attach_related_tips":
        return ActivityResource.activities.ATTACH_RELATED_TIPS_TO_TIP({
          tip: obj.id,
          related_tips: meta.related_tips,
        });
      default:
        return null;
    }
  }

  async loadExamples() {
    const examples = await ExampleResource.list({ tip: this.id });
    this.example_ids = examples.map((ex) => ex.id);

    this.updateStateObject();

    return this;
  }

  async loadReviews() {
    const reviews = await this.constructor.http.get(
      `/v1/tips/${this.id}/ratings/`,
      {
        ordering: "-id",
      }
    );

    this.reviews = reviews.results;
    this.updateStateObject();

    return this;
  }

  async addReview(data) {
    const review = await this.constructor.http.post(
      `/v1/tips/${this.id}/ratings/`,
      {},
      data
    );

    this.reviews = [review, ...(this.reviews || [])];
    this.is_rated = true;
    this.updateStateObject();
    TipResource.track(
      "rate",
      this,
      _.pick(review, ["clarity", "relevance", "uniqueness"])
    );
  }

  async loadQuestions(params = { page: 1 }) {
    const questions = await QuestionResource.list({
      tip: this.id,
      ...params,
    });

    const ids = questions.map((q) => q.id);
    this.questionPages = paginate(this.questionPages, params.page, ids);
    this.updateStateObject();

    return this;
  }

  async addQuestion({ body }) {
    const question = await QuestionResource.createOne({ tip: this.id, body });
    this.questionPages[1].unshift(question.id);
    this.updateStateObject();
  }

  async suggest(studentIds, isQueued = false) {
    try {
      await this.constructor.http.post(
        `/v1/tips/${this.id}/suggest/`,
        {},
        { students: studentIds, is_queued: isQueued }
      );
      TipResource.alert.success("Added the tip to selected student-grids.");
      TipResource.track("suggest", this, {
        students: studentIds.map((id) => ({
          id,
          nickname: StudentResource.pick(id)?.nickname,
        })),
      });
    } catch (err) {
      TipResource.alert.error("Failed to add tip to student-grids.");
    }
  }

  async try({ helpful = true, retryLater = true }) {
    try {
      await this.constructor.http.post(
        `${this.__endpoint}/${this.id}/try/`,
        {},
        { helpful, retry_later: retryLater }
      );

      this.try_count += 1;
      if (helpful) this.helpful_count += 1;
      this.updateStateObject();

      const { user } = this.constructor.appContext.appState;
      await user?.practitioner.reload();

      const msg = retryLater
        ? "Your response is saved."
        : "The tip will be removed from the grid.";
      TipResource.alert.success(msg);
      TipResource.track("try", this, { helpful, retry_later: retryLater });
    } catch (err) {
      TipResource.alert.error("Operation failed.");
    }
  }

  async toggleEditMark() {
    try {
      const is_marked = !this.marked_for_editing;
      const ii = !is_marked ? "unmarked" : "marked";
      await this.update({ marked_for_editing: is_marked }, undefined, true);
      TipResource.alert.success(`The tip is ${ii} for editing.`);
      TipResource.track("set_edit_mark", this, { is_marked });
    } catch (err) {
      TipResource.alert.error("Failed to toggle the edit-mark.");
      throw err;
    }
  }

  async linkTips(tipIds) {
    const uniqueIds = new Set([...this.linked_tips, ...tipIds]);
    return this.update({ linked_tips: [...uniqueIds] }, undefined, true).then(
      () => {
        TipResource.track("attach_related_tips", this, {
          related_tips: tipIds,
        });
        return this;
      }
    );
  }

  async unlinkTips(tipIds) {
    const ids = this.linked_tips?.filter((id) => !tipIds.includes(id));
    if (ids) this.update({ linked_tips: ids }, undefined, true);
  }

  static async getTipsAndExamples(tipIds, tick) {
    const tips = [];
    let count = 0;
    for (const id of tipIds) {
      let tip = this.pick(id);
      if (!tip?.loaded) tip = await this.getOne(id);
      if (!tip.example_ids) await tip.loadExamples();
      tips.push(tip);
      count += 1;
      tick(`${count}/${tipIds.length}`);
    }

    return tips;
  }

  static clearPrintStage(studentId) {
    const printStage = this.appContext.appState.printStage || {};
    printStage[studentId] = [];

    this.appContext.appDispatch({
      type: "PRINT_STAGE:SET",
      payload: printStage,
    });
  }

  get motivator() {
    return motivatorMap[this.levels];
  }

  get author() {
    return this.updated_by?.full_name;
  }

  get examples() {
    return ExampleResource.pickMany(this.example_ids);
  }

  get questions() {
    return QuestionResource.pickMany(depaginate(this.questionPages));
  }

  get color() {
    return TipResource.COLORS[this.levels] || "#B5B5B5";
  }

  get relatedTips() {
    return TipResource.pickMany(this.linked_tips);
  }

  get loaded() {
    return !!this.updated;
  }

  get legacyDescription() {
    return [this.description, this.when].join("\n").trim() || undefined;
  }

  get status() {
    if (!this.read_count) return "new";
    if (!this.is_rated) return "needs_rating";
    if (!this.try_count) return "not_tried";
    return "";
  }
}
