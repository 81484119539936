import { Box } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { ContentList, Emptyness } from "../../design-library";
import { QuestionResource } from "../../resources";
import { QuestionCard } from "../../shared-components/Question";
import { TipDisplayModal } from "../../shared-components/Tip";
import WidgetContainer from "./WidgetContainer";

function RecentQuestionsWidget({ label, idx, onRearrange }) {
  const {
    appState: { recentQuestionIds },
  } = useContext(AppContext);

  const [modalContent, setModalContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const questions = QuestionResource.pickMany(recentQuestionIds);

  useEffect(() => {
    setIsLoading(true);
    QuestionResource.getRecent().finally(() => setIsLoading(false));
  }, []);

  return (
    <WidgetContainer
      label={label}
      isLoading={isLoading}
      idx={idx}
      onRearrange={onRearrange}
    >
      {questions?.length ? (
        <Box width="100%" paddingTop="8px">
          <ContentList
            entries={questions}
            CardComponent={QuestionCard}
            onEntryClick={(entry) =>
              setModalContent({ tip: entry.tip, questions: [entry.id] })
            }
          />
          <TipDisplayModal
            tipId={modalContent.tip}
            onClose={() => setModalContent({})}
            initialSiderContent={{ questions: modalContent.questions }}
          />
        </Box>
      ) : (
        questions && <Emptyness size={10} />
      )}
    </WidgetContainer>
  );
}

export default RecentQuestionsWidget;
