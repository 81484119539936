import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { UserResource } from "../../resources";
import DashboardPage from "./DashboardPage";
import DLPSurveyPage from "./DLPSurveyPage";

export default function Page() {
  const user = UserResource.current;

  useEffect(() => {
    document.title = "Learning Seeds | Home";
  }, []);

  return (
    <Box height="100%">
      {user.isDLP && user.yetToFillSurvey ? (
        <DLPSurveyPage />
      ) : (
        <DashboardPage />
      )}
    </Box>
  );
}
