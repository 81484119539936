/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { DatePicker, Divider, Input, Text } from "../../design-library";
import { PractitionerResource, UserResource } from "../../resources";
import { PractitionerCard, PractitionerSelector } from "../Practitioner";
import HeadsUpInput from "./HeadsUpInput";

const inputProps = {
  fullWidth: true,
  multiline: true,
  style: { marginTop: "8px" },
};

const labelProps = {
  type: "label",
  style: { marginTop: "12px" },
};

function EpisodeForm({ onUpdate, initialValues = {} }) {
  const [form, setForm] = useState({
    practitioner: UserResource.current.id,
    date: new Date(),
    ...initialValues,
  });

  const onInput = (key, value) => setForm({ ...form, [key]: value });

  const pracId = form.practitioner;
  const practitioner = pracId && PractitionerResource.pick(pracId);

  useEffect(() => onUpdate && onUpdate(form), [form]);
  useEffect(() => {
    if (pracId && !practitioner) PractitionerResource.getOne(form.practitioner);
  }, []);

  return (
    <Box>
      <Text {...labelProps} content="Title" style={{ marginTop: 0 }} />
      <Input
        {...inputProps}
        multiline={false}
        placeholder="Enter title..."
        initialValue={initialValues?.title}
        onChange={(e) => onInput("title", e.target.value)}
        variant="standard"
      />

      <Divider />

      <Text {...labelProps} content="Date" />
      <Grid container style={{ marginTop: "8px" }}>
        <Grid item xs={6}>
          <DatePicker
            value={form.date}
            fullWidth
            onChange={(d) => onInput("date", d)}
          />
        </Grid>
      </Grid>

      <Text {...labelProps} content="Practitioner" />
      <Grid container style={{ marginTop: "8px" }}>
        <Grid item xs={6}>
          {practitioner ? (
            <PractitionerCard
              entry={practitioner}
              onCross={() => onInput("practitioner", undefined)}
            />
          ) : (
            <PractitionerSelector
              onSelect={(id) => onInput("practitioner", id)}
            />
          )}
        </Grid>
      </Grid>

      <Text {...labelProps} content="Heads Up" />
      <HeadsUpInput
        style={{ marginTop: "4px" }}
        initialValue={initialValues.heads_up_json}
        onChange={(v) => onInput("heads_up_json", v)}
      />

      <Text {...labelProps} content="Description" />
      <Input
        {...inputProps}
        initialValue={initialValues?.description}
        placeholder="Enter description..."
        onChange={(e) => onInput("description", e.target.value)}
      />

      <Text {...labelProps} content="Transcript" />
      <Input
        {...inputProps}
        initialValue={initialValues?.transcript}
        placeholder="Enter transcript..."
        onChange={(e) => onInput("transcript", e.target.value)}
      />
    </Box>
  );
}

export default EpisodeForm;
