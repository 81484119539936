import PropTypes from "prop-types";
import { Chip, makeStyles } from "@material-ui/core";
import { forwardRef } from "react";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "11px",
    color: (props) => (props.filled ? "white" : props.color),
    height: "20px",
    border: (props) => `1px solid ${props.color}`,
    backgroundColor: (props) => (props.filled ? props.color : "white"),
  },
  label: {
    lineHeight: 1,
  },
}));

const MyChip = forwardRef(
  (
    {
      filled = false,
      size = "small",
      content,
      onClick,
      color = "#00B5B2",
      style = {},
      ...props
    },
    ref
  ) => {
    const classes = useStyles({ color, filled });

    return (
      <Chip
        ref={ref}
        color={filled ? "primary" : "default"}
        onClick={onClick}
        classes={classes}
        variant={filled ? "default" : "outlined"}
        style={style}
        label={content}
        {...props}
      />
    );
  }
);

MyChip.propTypes = {
  filled: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
};

export default MyChip;
