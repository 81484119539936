import { Text } from "../../../design-library";

function RateTipEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          rated tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; - &nbsp;
          <b>clarity: {meta.clarity}</b>,&nbsp;
          <b>relevance: {meta.relevance}</b> and&nbsp;
          <b>uniqueness: {meta.uniqueness}</b>.
        </>
      }
    />
  );
}

export default RateTipEntry;
