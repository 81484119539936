/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";

import {
  Button,
  Checkbox,
  ContentList,
  Emptyness,
  Input,
  Spinner,
} from "../../design-library";

import { AppContext } from "../../context";
import { useContext, useEffect, useState } from "react";
import { StudentResource, UserResource } from "../../resources";
import _ from "lodash";
import {
  StudentCreationModal,
  StudentStamp,
} from "../../shared-components/Student";
import { useHistory } from "react-router";
import { Waypoint } from "react-waypoint";
import { parseBool, useURLState } from "../../shared-components/utils";

function formatParams(params, page) {
  return {
    name: params.name,
    ordering: params.ordering || "nickname",
    caseload_only: params.caseload_only,
    page_size: 20,
    page: page || 1,
  };
}

export default function Page() {
  const { appDispatch } = useContext(AppContext);
  const config = StudentResource.getConfig("STUDENTS_PAGE_CONFIG");

  const [isLoading, setIsLoading] = useState();
  const [nextPage, setNextPage] = useState(1);
  const [modalContent, setModalContent] = useState({});
  const [params, setParams] = useURLState(config.query, [
    "name",
    "caseload_only",
  ]);

  const history = useHistory();

  const loadNextStudents = (fromFirst = true) => {
    setIsLoading(true);

    const page = fromFirst ? 1 : nextPage;
    const fparams = formatParams(params, page);

    StudentResource.list(fparams, "STUDENTS_PAGE_CONFIG")
      .then((students) => {
        setNextPage(students.length < 20 ? -1 : page + 1);
        setIsLoading(false);
      })
      .catch(() => setNextPage(-1));
  };

  useEffect(loadNextStudents, [params]);

  useEffect(() => {
    document.title = "Learning Seeds | Students";
  }, []);

  const user = UserResource.current;
  const userCaseload = user.practitioner?.assigned_student_ids || [];
  const students = StudentResource.pickMany(config.ids);

  return (
    <Box height="100%">
      <Box
        height="100px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Input
              onChange={_.debounce(
                (e) => setParams({ ...params, name: e.target.value }),
                500
              )}
              onEnterPress={(e) =>
                setParams({ ...params, name: e.target.value })
              }
              placeholder="Search a student"
              noBorder
              icon="search"
              initialValue={params.name}
            />
            <Box
              display="inline-flex"
              marginLeft="12px"
              height="40px"
              color="#686868"
            >
              <Checkbox
                label="Filter your caseload"
                initialState={parseBool(params.caseload_only)}
                onChange={(e, val) =>
                  setParams({ ...params, caseload_only: val })
                }
              />
            </Box>
            {isLoading && (
              <Spinner
                style={{
                  display: "inline-flex",
                  height: "40px",
                  marginLeft: "12px",
                }}
              />
            )}
          </Grid>
          <Grid item>
            {user.role === UserResource.ROLES.ADMIN && (
              <Button
                label="Add Student"
                primary
                onClick={() => setModalContent({ addStudent: true })}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        height="calc(100% - 100px)"
        width="100%"
        position="relative"
        overflow="hidden auto"
      >
        {students?.length === 0 ? (
          <Emptyness style={{ height: "100%" }} />
        ) : !students ? (
          <Spinner size={30} style={{ height: "100%" }} />
        ) : (
          <>
            <ContentList
              entries={students}
              CardComponent={(props) => (
                <StudentStamp
                  assigned={userCaseload.includes(props.entry.id)}
                  {...props}
                />
              )}
              folds="flex"
              onEntryClick={(st) => history.push(`/students/${st.id}/tips`)}
            />
            {!isLoading && nextPage !== -1 && (
              <Waypoint onEnter={() => loadNextStudents(false)} />
            )}
            {nextPage > 1 && isLoading && (
              <Spinner style={{ height: "40px" }} label="Loading more..." />
            )}
          </>
        )}
      </Box>
      <StudentCreationModal
        open={modalContent.addStudent}
        onClose={() => setModalContent({})}
        onCreate={(stu) => {
          config.pages[1]?.unshift(stu.id);
          appDispatch({
            type: "STUDENTS_PAGE_CONFIG:SET",
            payload: _.pick(config, ["pages", "query"]),
          });
        }}
      />
    </Box>
  );
}
