import { Fragment } from "react";
import { Text } from "../../../design-library";

function AttachRelatedTipsEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          attached the following tips to tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; as related tips: &nbsp;
          {meta.related_tips.map((id, idx) => (
            <Fragment key={id}>
              {idx > 0 && <>,&nbsp;</>}
              <b className="clickable" onClick={() => onClick({ tip: id })}>
                #{id}
              </b>
            </Fragment>
          ))}
          .
        </>
      }
    />
  );
}

export default AttachRelatedTipsEntry;
