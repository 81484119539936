import { Text } from "../../../design-library";

function TryTipEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          tried tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          {meta.helpful ? (
            <>
              , it was <b>helpful</b>
            </>
          ) : (
            <>
              {meta.retry_later ? (
                <>
                  , it was <b>not helpful</b> but will try again
                </>
              ) : (
                <>
                  , it was <b>not helpful</b> and will not try anymore
                </>
              )}
            </>
          )}
          .
        </>
      }
    />
  );
}

export default TryTipEntry;
