/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Select } from "../../../design-library";
import { rowDescriptor } from "../../Tip/TipRowV2";
import _ from "lodash";

const motivatorOptions = [
  {
    label: "Avoidance Motivation",
    value: "1",
    tooltip: rowDescriptor[1],
  },
  {
    label: "Object/Sensory Motivation",
    value: "2",
    tooltip: rowDescriptor[2],
  },
  {
    label: "Reaction Motivation",
    value: "3",
    tooltip: rowDescriptor[3],
  },
  {
    label: "Interaction Flow Motivation",
    value: "4",
    tooltip: rowDescriptor[4],
  },
  {
    label: "Planning Motivation",
    value: "5",
    tooltip: rowDescriptor[5],
  },
];

function StateSelector({ initialForm = {}, onChange = _.noop, style = {} }) {
  const [form, setForm] = useState(initialForm);
  const onFormInput = (key, value) => setForm({ ...form, [key]: value });

  useEffect(() => onChange(form), [form]);

  return (
    <Grid container style={style}>
      <Grid item xs={4}>
        <Select
          label="Motivator"
          value={form.levels}
          options={motivatorOptions}
          onChange={(e) => onFormInput("levels", e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

StateSelector.propTypes = {
  initialForm: PropTypes.object,
  onChange: PropTypes.func,
};

export default StateSelector;
