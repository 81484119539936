/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Box, ClickAwayListener, Paper, Popper } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import Button from "../Button/Button";

function Anchor({ onClick }) {
  return <Button label="Open" primary onClick={onClick} />;
}

function MyPopper({
  AnchorComponent = Anchor,
  placement = "right",
  children,
  onOpen = _.noop,
}) {
  const [anchorEl, setAnchorEl] = useState();
  const isOpen = !!anchorEl;

  useEffect(() => {
    if (isOpen) onOpen();
  }, [isOpen]);

  return (
    <Box>
      <AnchorComponent
        onClick={(e) => setAnchorEl(e.currentTarget)}
        isActive={isOpen}
      />
      <Popper anchorEl={anchorEl} open={isOpen} placement={placement}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper>{children}</Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}

MyPopper.propTypes = {
  AnchorComponent: PropTypes.func,
  placement: PropTypes.string,
  onOpen: PropTypes.func,
};
export default MyPopper;
