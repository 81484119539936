import PropTypes from "prop-types";
import Illustration from "./Illustration";
import { Box } from "@material-ui/core";

function UnderConstruction({ size = 32 }) {
  const dim = `${size * 8}px`;
  return (
    <Box textAlign="center" sx={{ opacity: 0.15, userSelect: "none" }}>
      <Illustration style={{ width: dim, height: dim }} />
      <Box fontSize={size} fontWeight="bold" fontFamily="lato">
        Under Construction
      </Box>
    </Box>
  );
}

UnderConstruction.propTypes = {
  size: PropTypes.number,
};

export default UnderConstruction;
