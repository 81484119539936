import Base from "./base";
import TipResource from "./tip";

export default class StudentTipResource extends Base {
  get __properties() {
    return [
      "id",
      "status",
      "is_suggested",
      "is_read",
      "is_graduated",
      "has_new_info",
      "studentId",
      "tipId",
      "is_queued",
      "is_rated",
    ];
  }

  get __resource() {
    return "STUDENT_TIP";
  }

  static async getOne(studentId, id) {
    const endpoint = `/v1/students/${studentId}/tips`;
    return super.getOne(id, endpoint);
  }

  static async list(studentId, params, dispatchKey) {
    const endpoint = `/v1/students/${studentId}/tips`;
    return super.list(params, dispatchKey, endpoint);
  }

  static responseProcessor_list(data) {
    const tips = data.map((d) => TipResource.toObject(d.tip));
    TipResource.storeStateObjects(tips);

    return data;
  }

  static responseProcessor_getOne(data) {
    const tip = TipResource.toObject(data.tip);
    tip.updateStateObject();

    return data;
  }

  async markAsRead() {
    await this.constructor.http.get(
      `/v1/students/${this.studentId}/tips/${this.id}/`
    );
    StudentTipResource.getOne(this.studentId, this.id);
  }

  togglePrintTag() {
    const { appState, appDispatch } = this.constructor.appContext;
    const printStage = appState.printStage || {};
    let tipIds = printStage[this.studentId] || [];
    const exists = tipIds.includes(this.tipId);

    tipIds = exists
      ? tipIds.filter((id) => id !== this.tipId)
      : [this.tipId, ...tipIds];
    printStage[this.studentId] = tipIds;

    appDispatch({ type: "PRINT_STAGE:SET", payload: printStage });

    const msg = !exists
      ? "The tip is tagged for printing."
      : "Print tag is removed from the tip";

    this.constructor.alert.success(msg);
  }

  static tagForPrinting(studentId, tipIds) {
    const printStage = this.appContext.appState.printStage || {};
    printStage[studentId] = tipIds;

    this.appContext.appDispatch({
      type: "PRINT_STAGE:SET",
      payload: printStage,
    });

    const ii = tipIds.length > 1 ? "s are" : " is";
    this.alert.success(`${tipIds.length} tip${ii} tagged for printing`);
  }

  static toObject(data) {
    const tip = new StudentTipResource();
    tip.assign(data);
    tip.tipId = data.tip.id;
    tip.studentId = data.student_id;
    return tip;
  }

  get printTagged() {
    const printStage = this.constructor.appContext.appState.printStage || {};
    const tipIds = printStage[this.studentId];
    return tipIds?.includes(this.tipId);
  }

  get tip() {
    return TipResource.pick(this.tipId);
  }
}
