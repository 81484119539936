import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { ContentCard, Emptyness, Spinner } from "..";

function ContentList({
  entries,
  highlightedIds = [],
  folds = 1,
  spacing = 1,
  emptyState = 0,
  onEntryClick,
  onEntryCross,
  CardComponent = ContentCard,
  style = {},
}) {
  return (
    <>
      {!entries ? (
        <Spinner />
      ) : emptyState && !entries.length ? (
        <Emptyness size={emptyState} />
      ) : (
        <Grid container spacing={spacing} style={style}>
          {entries.map((entry) => (
            <Grid xs={folds !== "flex" && 12 / folds} item key={entry.id}>
              <CardComponent
                entry={entry}
                highlighted={highlightedIds?.includes(entry.id)}
                truncate={1}
                onClick={onEntryClick ? (e) => onEntryClick(entry, e) : null}
                onCross={onEntryCross ? () => onEntryCross(entry) : null}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

ContentList.propTypes = {
  entries: PropTypes.array,
  highlightedIds: PropTypes.array,
  folds: PropTypes.oneOf([1, 2, 3, 4, 6, "flex"]),
  spacing: PropTypes.number,
  onEntryClick: PropTypes.func,
  onEntryCross: PropTypes.func,
  CardComponent: PropTypes.func,
};

export default ContentList;
