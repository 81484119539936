/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import {
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Box,
  Breadcrumbs,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import Icon from "../Icon/Icon";
import ModalFooter from "./ModalFooter";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    height: "calc(100% - 40px)",
    width: "min(1300px, calc(100% - 40px))",
    fontFamily: "Lato",
    outline: 0,
    margin: "20px",
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Lato",
    fontSize: "12px",
    padding: "0 2px 0 6px",
    height: "24px",
    boxSizing: "border-box",
  },
  body: {
    padding: "20px",
    boxSizing: "border-box",
    overflow: "auto",
    width: "700px",
    height: "100%",
  },
  sider: {
    padding: "20px",
    boxSizing: "border-box",
    overflow: "auto",
    height: "100%",
    width: "598px",
    borderLeft: "2px dotted #00B5B2",
  },
  footer: {
    height: "60px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 20px",
  },
}));

function MyModal({
  open = false,
  AnchorComponent,
  FooterComponent,
  onOpen = _.noop,
  onClose = _.noop,
  breadcrumbs = [],
  footerActions,
  footer,
  sider,
  children,
}) {
  const classes = useStyles();
  const paperStyle = {};
  if (!sider) paperStyle.width = "700px";

  const [show, setShow] = useState(open || false);

  useEffect(() => setShow(open || false), [open]);
  useEffect(() => show && onOpen(), [show]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <>
      {AnchorComponent && <AnchorComponent onClick={() => setShow(true)} />}
      <Modal
        open={show}
        onClose={handleClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <Fade in={show}>
          <Box className={classes.paper} style={paperStyle}>
            <Box className={classes.title}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Breadcrumbs style={{ color: "white" }}>
                    {breadcrumbs?.map((crumb) => (
                      <Typography key={crumb} style={{ fontSize: "14px" }}>
                        {crumb}
                      </Typography>
                    ))}
                  </Breadcrumbs>
                </Grid>
                <Grid item>
                  <Box display="flex">
                    <Icon
                      name="close"
                      style={{ color: "white" }}
                      onClick={handleClose}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                height:
                  footerActions || footer || FooterComponent
                    ? "calc(100% - 84px)"
                    : "calc(100% - 24px)",
              }}
              display="flex"
            >
              <Box className={classes.body}>{children}</Box>
              {sider && <Box className={classes.sider}>{sider}</Box>}
            </Box>
            {footerActions ? (
              <>
                <Divider />
                <Box className={classes.footer}>
                  <ModalFooter actions={footerActions} />
                </Box>
              </>
            ) : FooterComponent ? (
              <>
                <Divider />
                <Box className={classes.footer}>
                  <FooterComponent onClose={handleClose} />
                </Box>
              </>
            ) : (
              footer && (
                <>
                  <Divider />
                  <Box className={classes.footer}>{footer}</Box>
                </>
              )
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

MyModal.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(PropTypes.any),
  open: PropTypes.bool,
  AnchorComponent: PropTypes.func,
  FooterComponent: PropTypes.func,
  onOpen: PropTypes.func,
  footerActions: PropTypes.array,
  footer: PropTypes.node,
  sider: PropTypes.node,
};

export default MyModal;
