/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import cookie from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { format as formatTimeAgo } from "timeago.js";

export const levelColor = {
  1: "#F05B70",
  2: "#EAA533",
  3: "#b2b347",
  4: "#81C77F",
  5: "#819BD0",
};

export const formatDate = (date) => {
  if (!date) return "";
  return moment(date).format("MMM DD, YYYY");
};

export const relativeTimeFormat = (datetime) => {
  if (!datetime) return "Never";
  return formatTimeAgo(new Date(datetime));
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  arr = [...arr];
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const roundDec = (val, fracDigit = 0) => {
  return Number(val.toFixed(fracDigit));
};

export const writeCookie = (key, data) => {
  const b64 = Buffer.from(JSON.stringify(data)).toString("base64");
  cookie.set(key, b64, { expires: 30 });
};

export const readCookie = (key) => {
  try {
    const b64 = cookie.get(key);
    return JSON.parse(Buffer.from(b64, "base64").toString());
  } catch (err) {
    return null;
  }
};

export const useCookieState = (key, value) => {
  const [state, setState] = useState(readCookie(key) || value);

  const store = useCallback(
    _.debounce((data) => writeCookie(key, data), 1000),
    []
  );

  useEffect(() => store(state), [state]);

  return [state, setState];
};

const parseQueryString = (qs) => {
  const urlState = {};

  const query = new URLSearchParams(qs);
  for (const [key, value] of query) urlState[key] = value && decodeURI(value);

  return urlState;
};

const makeQueryString = (qs, data) => {
  const query = new URLSearchParams(qs);

  for (const [key, val] of Object.entries(data)) {
    if (val) query.set(key, encodeURI(val));
    else query.delete(key);
  }

  return query.toString();
};

export const useURLState = (initialData = {}, fields = []) => {
  const location = useLocation();
  const qs = location.search;
  const [state, setState] = useState(qs ? parseQueryString(qs) : initialData);
  const history = useHistory();

  useEffect(() => {
    const ob = {};
    for (const key of fields) ob[key] = state[key] || "";

    const q = makeQueryString(qs, ob);
    history.push({ search: q });
  }, [state]);

  return [state, setState];
};

export const tickFn = (fn, done) => {
  done(false);
  return fn().finally(() => done(true));
};

export const execFn = async (fn, setLoading = _.noop, setError = _.noop) => {
  setLoading(true);
  setError();

  return fn()
    .catch((e) => setError(e))
    .finally(() => setLoading(false));
};

export const pluralize = (str) => {
  return str.endsWith("y") ? str.slice(0, str.length - 1) + "ies" : str + "s";
};

export const paginate = (pages, pageNo, ids) => {
  if (pageNo === 1) pages = {};
  pages[pageNo] = ids;
  return pages;
};

export const depaginate = (pages) => {
  if (!pages) return null;
  return _.flatten(Object.values(pages));
};

export const timeElapsedSince = (dt) => {
  if (!dt) return 0;
  const minutes = (new Date().getTime() - dt.getTime()) / (1000 * 60);
  return parseInt(Math.round(minutes));
};

export const getFormInputFn = (setForm) => {
  return (key, value) => setForm((form) => ({ ...form, [key]: value }));
};

export const parseBool = (val = "") => {
  const valStr = typeof val === "string" ? val : JSON.stringify(val);
  return ["true", "1"].includes(valStr.toLowerCase());
};

export const focusDom = (ref) => {
  const dom = ref.current;
  dom.scrollIntoView({ behavior: "smooth" });
  dom.style.background = "#fbfb2d5e";
  return setTimeout(() => (dom.style.background = null), 2000);
};

export const getUpdatedPageDict = (pageNo, idList, pageDict) => {
  if (pageNo === 1) return { 1: idList };

  const updatedPageDict = pageDict ? { ...pageDict } : {};
  updatedPageDict[pageNo] = idList;

  return updatedPageDict;
};
