/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import cookie from "js-cookie";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { Alert } from "../design-library";
import BaseResource from "../resources/base";
import { readCookie, writeCookie } from "../shared-components/utils";
import { useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import theme from "../theme";

const resources = [
  ["STUDENT", "studentsMap"],
  ["TIP", "tipsMap"],
  ["STRUGGLE", "strugglesMap"],
  ["EXAMPLE", "examplesMap"],
  ["EPISODE", "episodesMap"],
  ["ACTIVITY", "activitiesMap"],
  ["NOTIFICATION", "notificationsMap"],
  ["QUESTION", "questionsMap"],
  ["STUDENT_TIP", "studentTipsMap"],
  ["PRACTITIONER", "practitionersMap"],
  ["CELEBRATION", "celebrationsMap"],
];

const recentIds = [
  ["RECENT_STRUGGLE_IDS", "recentStruggleIds"],
  ["RECENT_QUESTION_IDS", "recentQuestionIds"],
];

const initialState = {
  user: null,
  browseTipsConfig: { query: {}, tipIds: null },
  studentsPageConfig: { query: {}, pages: null },
  printStage: readCookie("print_stage") || {},
  activityTabConfig: { query: {}, pages: null },
  managementTabConfig: { query: {}, pages: null },
  notificationsConfig: { pages: null },
  flags: { hasNewNotifications: false },
  authToken: null,
};

// eslint-disable-next-line no-unused-vars
for (const [name, mapKey] of resources) {
  initialState[mapKey] = null;
}

// eslint-disable-next-line no-unused-vars
for (const [name, mapKey] of recentIds) {
  initialState[mapKey] = null;
}

const AppContext = createContext({
  appState: initialState,
  appDispatch: () => null,
});

function assignNonEmptyValues(objMap, id, data) {
  if (objMap[id]) {
    objMap[id].assign(data);
  } else {
    objMap[id] = data;
  }
}

const Reducer = (state, action) => {
  let obj;
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case "TOKEN:SET":
      const { token, remember } = action.payload;
      cookie.set("token", token, { expires: remember ? 7 : undefined });
      break;

    case "FLAGS:SET":
      newState.flags = { ...newState.flags, ...action.payload };
      break;

    case "USER:SET":
      newState.user = action.payload;
      break;

    case "BROWSE_TIPS_CONFIG:SET":
      newState.browseTipsConfig = action.payload;
      break;

    case "STUDENTS_PAGE_CONFIG:SET":
      newState.studentsPageConfig = action.payload;
      break;

    case "PRINT_STAGE:SET":
      newState.printStage = action.payload;
      writeCookie("print_stage", action.payload);
      break;

    case "PRINT_STAGE:CLEAR":
      newState.printStage = null;
      cookie.remove("print_stage");
      break;

    case "ACTIVITY_TAB_CONFIG:SET":
      newState.activityTabConfig = action.payload;
      break;

    case "MANAGEMENT_TAB_CONFIG:SET":
      newState.managementTabConfig = action.payload;
      break;

    case "NOTIFICATIONS_CONFIG:SET":
      newState.notificationsConfig = action.payload;
      break;

    case "RESET_ALL":
      Object.assign(newState, initialState);
      cookie.remove("token");
      break;

    default:
      break;
  }

  for (const [name, mapKey] of resources) {
    if (action.type !== `${name}:UPDATE`) continue;

    obj = (newState[mapKey] || {})[action.payload.id];
    if (obj) {
      obj.assign(action.payload);
    } else {
      obj = action.payload;
    }
    newState[mapKey] = { ...newState[mapKey], [obj.id]: obj };

    break;
  }

  for (const [name, mapKey] of resources) {
    if (action.type !== `${name}S:SET`) continue;

    if (!newState[mapKey]) newState[mapKey] = {};
    Object.entries(action.payload).forEach(([key, value]) => {
      assignNonEmptyValues(newState[mapKey], key, value);
    });

    break;
  }

  for (const [name, mapKey] of recentIds) {
    if (action.type !== `${name}:SET`) continue;
    newState[mapKey] = action.payload;
  }

  return newState;
};

const AppProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const jwt = q.get("jwt");

  const [appState, appDispatch] = useReducer(Reducer, {
    ...initialState,
    authToken: jwt,
  });

  const [alert, setAlert] = useState();
  BaseResource.appContext = { appState, appDispatch, showAlert: setAlert };

  useEffect(() => {
    if (jwt) history.push({ search: "" });
  }, []);

  return (
    <AppContext.Provider value={{ appState, appDispatch, showAlert: setAlert }}>
      {children}
      {alert && (
        <ThemeProvider theme={theme}>
          <Alert
            open={true}
            type={alert?.type}
            message={alert?.message}
            onClose={() => setAlert(null)}
          />
        </ThemeProvider>
      )}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
