/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "./design-library";
import { NotificationResource, UserResource } from "./resources";
import BaseResource from "./resources/base";
import { NavigationController, RouteController } from "./Routing";
import theme from "./theme";

const useStyles = makeStyles(() => ({
  leftPanel: {
    height: "100vh",
    width: ({ collapsed }) => (collapsed ? "44px" : "250px"),
    padding: ({ collapsed }) => (collapsed ? "0" : "0 16px"),
    backgroundColor: "white",
    boxShadow: "0 0 1px 0 #777",
    "@media print": {
      display: "none !important",
    },
  },
  logoContainer: {
    height: "100%",
    width: "calc(100% - 44px)",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  menuBtnContainer: {
    height: "100%",
    width: "44px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  routeContainer: {
    height: "100vh",
    width: ({ showNav, collapsed }) =>
      !showNav
        ? "100%"
        : collapsed
        ? "calc(100% - 44px)"
        : "calc(100% - 250px)",
    padding: "0 16px",
    "@media print": {
      width: "100% !important",
      padding: "0 !important",
    },
  },
  stagingBanner: {
    position: "absolute",
    top: "20px",
    left: "-40px",
    background: "red",
    fontFamily: "lato",
    fontWeight: "bold",
    color: "white",
    width: "150px",
    textAlign: "center",
    transform: "rotate(-45deg)",
    lineHeight: "30px",
    pointerEvents: "none",
  },
}));

function App() {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1100);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const showNav = !location.pathname.startsWith("/login");

  const classes = useStyles({ showNav, collapsed });

  useEffect(() => {
    setLoading(true);
    UserResource.getCurrentUser()
      .then(() => NotificationResource.startListener())
      .catch((e) => {})
      .finally(() => setLoading(false));
  }, [UserResource.current?.id]);

  useEffect(() => {
    document.title = "Learning Seeds";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100vh"
        >
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Grid container>
          {showNav && (
            <Box className={classes.leftPanel}>
              <Box display="flex" height="100px" pt={2} pb={2}>
                <Box>
                  <Box className={classes.menuBtnContainer}>
                    <Button
                      icon={collapsed ? "menu" : "menuClose"}
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  </Box>
                </Box>
                {!collapsed && (
                  <Box className={classes.logoContainer}>
                    <img src="/logo.png" alt="learning-seeds" width="140px" />
                  </Box>
                )}
              </Box>
              <Divider />
              <Box height="calc(100vh - 101px)">
                <NavigationController collapsed={collapsed} />
              </Box>
            </Box>
          )}
          <Box className={classes.routeContainer}>
            <RouteController />
          </Box>
          {!BaseResource.isProd && (
            <Box className={classes.stagingBanner}>STAGING</Box>
          )}
        </Grid>
      )}
    </ThemeProvider>
  );
}

export default App;
