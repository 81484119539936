import { Box, Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Text } from "../../design-library";
import ActivityTab from "./ActivityTab";
import ManagementTab from "./ManagementTab";

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default function Page() {
  const { tab } = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Learning Seeds | Admin";
  }, []);

  const classes = useStyles();

  return (
    <Box height="100%">
      <Box
        height="100px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box height="100%" className={classes.pageHeader}>
              <Text
                type="title"
                style={{ fontSize: "24px", margin: 0 }}
                content="Admin Panel"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box height="60px">
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          style={{ backgroundColor: "white" }}
          onChange={(e, tab) => history.push(`/admin/${tab}`)}
        >
          <Tab label="Activity Monitor" value="activity" />
          <Tab label="Management" value="management" />
        </Tabs>
      </Box>
      <Box height="calc(100% - 160px)" width="100%" position="relative">
        {tab === "activity" && <ActivityTab />}
        {tab === "management" && <ManagementTab />}
      </Box>
    </Box>
  );
}
