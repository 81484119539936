import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Button,
  ContentList,
  Divider,
  Dropdown,
  Modal,
  Spinner,
  Text,
} from "../../design-library";

import { Box } from "@material-ui/core";
import { EpisodeResource, ExampleResource } from "../../resources";
import { EpisodeDetails, EpisodeForm } from ".";
import { ExampleCard, ExampleForm, ExampleSider } from "../Example";
import { StruggleCard, StruggleDisplayModal, StruggleForm } from "../Struggle";
import { TipDisplayModal } from "../Tip";

function EpisodeDisplayModal({ episodeId, initialSiderContent = {}, onClose }) {
  const [mode, setMode] = useState();
  const [siderContent, setSiderContent] = useState(initialSiderContent);
  const [modalContent, setModalContent] = useState({});
  const [episodeForm, setEpisodeForm] = useState();
  const [exampleForm, setExampleForm] = useState();
  const [struggleForm, setStruggleForm] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbs = ["Episodes"];
  if (episodeId) breadcrumbs.push(episodeId);

  useEffect(() => {
    if (episodeId)
      EpisodeResource.getOne(episodeId)
        .then((epi) => epi.loadExamples())
        .then((epi) => epi.loadStruggles());
  }, [episodeId]);

  const episode = EpisodeResource.pick(episodeId);

  const updateEpisode = () => {
    setIsLoading(true);
    episode
      .update(episodeForm)
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  const createExample = () => {
    setIsLoading(true);
    const form = { ...exampleForm };
    form.description = form.situation;
    form.heading = form.headline;
    episode
      .addExample(form)
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  const updateExample = () => {
    setIsLoading(true);
    ExampleResource.pick(siderContent.example)
      ?.update(exampleForm)
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  const createStruggle = () => {
    setIsLoading(true);
    episode
      .addStruggle(struggleForm)
      .then(() => setMode())
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      open={!!episodeId}
      onClose={onClose}
      breadcrumbs={breadcrumbs}
      sider={
        siderContent.example && (
          <ExampleSider
            exampleId={siderContent.example}
            onFormUpdate={(form) => setExampleForm(form)}
            editMode={mode === "edit-example"}
            setModalContent={setModalContent}
          />
        )
      }
      footer={
        <Box textAlign="right">
          {!mode ? (
            <>
              {_.isEmpty(siderContent) ? (
                <Button
                  icon="edit"
                  style={{ float: "left", marginLeft: "-12px" }}
                  onClick={() => setMode("edit-episode")}
                />
              ) : (
                <Dropdown
                  icon="edit"
                  options={[
                    { label: "Edit Example", value: "example" },
                    { label: "Edit Episode", value: "episode" },
                  ]}
                  style={{ float: "left", marginLeft: "-12px" }}
                  onSelect={(op) => setMode(`edit-${op}`)}
                />
              )}
              <Button
                label="Back"
                onClick={() =>
                  !_.isEmpty(siderContent) ? setSiderContent({}) : onClose()
                }
                style={{ marginRight: "10px" }}
              />
              <Dropdown
                primary
                label="Breakdown"
                options={[
                  { label: "Add Example", value: "example" },
                  { label: "Add Struggle", value: "struggle" },
                ]}
                onSelect={(op) => setMode(`add-${op}`)}
              />
            </>
          ) : (
            <>
              <Button
                label="Cancel"
                disabled={isLoading}
                onClick={() => setMode()}
                style={{ marginRight: "10px" }}
              />
              <Button
                primary
                label="Save"
                isLoading={isLoading}
                onClick={() => {
                  if (mode === "edit-episode") return updateEpisode();
                  if (mode === "edit-example") return updateExample();
                  if (mode === "add-example") return createExample();
                  if (mode === "add-struggle") return createStruggle();
                }}
              />
            </>
          )}
        </Box>
      }
    >
      {!episode ? (
        <Spinner size={30} style={{ height: "100%" }} />
      ) : (
        <>
          {mode === "edit-episode" ? (
            <EpisodeForm
              onUpdate={(form) => setEpisodeForm(form)}
              initialValues={{
                practitioner: episode.practitioner?.id,
                ..._.pick(episode, [
                  "title",
                  "description",
                  "transcript",
                  "heads_up_json",
                  "date",
                ]),
              }}
            />
          ) : (
            <>
              <EpisodeDetails episode={episode} />
              <Divider />
              {mode === "add-example" ? (
                <ExampleForm onUpdate={(form) => setExampleForm(form)} />
              ) : mode === "add-struggle" ? (
                <StruggleForm onUpdate={(form) => setStruggleForm(form)} />
              ) : (
                <>
                  <Text content="Examples" type="label" />
                  <ContentList
                    style={{ marginTop: "4px" }}
                    entries={episode.examples}
                    CardComponent={ExampleCard}
                    onEntryClick={(et) => setSiderContent({ example: et.id })}
                    highlightedIds={[siderContent.example]}
                  />
                  <Divider />
                  <Text content="Struggles" type="label" />
                  <ContentList
                    style={{ marginTop: "4px" }}
                    entries={episode.struggles}
                    CardComponent={StruggleCard}
                    onEntryClick={(et) => setModalContent({ struggle: et.id })}
                  />
                  <StruggleDisplayModal
                    struggleId={modalContent.struggle}
                    onClose={() => setModalContent({})}
                  />
                  <TipDisplayModal
                    tipId={modalContent.tip}
                    onClose={() => setModalContent({})}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  );
}

export default EpisodeDisplayModal;
