/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { TipByline, TipQuestions, TipSummary } from "..";
import {
  Button,
  Chip,
  ContentList,
  Modal,
  Spinner,
  Text,
  Icon,
  Dropdown,
  Badge,
  ErrorState,
} from "../../../design-library";
import { TipResource } from "../../../resources";
import { ExampleCard, ExampleSider } from "../../Example";
import { timeElapsedSince } from "../../utils";
import TipReviewForm from "../TipReviewForm";

function TipReaderDLP({
  tipId,
  initialSiderContent = {},
  onOpen = _.noop,
  onClose,
}) {
  const [ratingForm, setRatingForm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [siderContent, setSiderContent] = useState(initialSiderContent);
  const [tipOpenedAt, setTipOpenedAt] = useState();
  const [error, setError] = useState();

  const breadcrumbs = ["Tips"];
  if (tipId) breadcrumbs.push(tipId);

  useEffect(() => {
    setRatingForm();
    if (tipId) {
      setSiderContent(initialSiderContent);
      setError();
      TipResource.getOne(tipId)
        .then((tip) => tip.loadExamples())
        .then((tip) => tip.loadQuestions())
        .then(() => setTipOpenedAt(new Date()))
        .then(onOpen)
        .catch((e) => setError(e));
    }
  }, [tipId]);

  const onCloseFn = () => {
    const minutes = timeElapsedSince(tipOpenedAt);
    if (tip && minutes) TipResource.track("read", tip, { minutes });

    setTipOpenedAt();
    onClose();
  };

  const tip = TipResource.pick(tipId);
  const submitRating = () => {
    setIsLoading(true);
    tip.addReview(ratingForm).finally(() => setIsLoading(false));
  };

  const handleTryDropdown = (value) => {
    const body = { helpful: true, retryLater: true };
    if (value > 1) body.helpful = false;
    if (value > 2) body.retryLater = false;
    setIsLoading(true);
    tip?.try(body).finally(() => setIsLoading(false));
  };

  const isRated = tip?.is_rated;
  const footerActions = [
    {
      Component: Button,
      hidden: isRated,
      label: "Submit Rating",
      disabled: !ratingForm,
      isLoading,
      onClick: submitRating,
    },
    {
      Component: Dropdown,
      label: "I tried",
      title: "Was the tip helpful?",
      disabled: !tip?.loaded || !isRated,
      isLoading,
      onSelect: handleTryDropdown,
      options: [
        { label: "Yes", value: 1 },
        { label: "No, but will try again", value: 2 },
        { label: "No, won't try anymore", value: 3 },
      ],
    },
    {
      Component: Button,
      label: "Back",
      onClick: () =>
        _.isEmpty(siderContent) ? onCloseFn() : setSiderContent({}),
    },
  ];

  return (
    <Modal
      open={!!tipId}
      onClose={onCloseFn}
      breadcrumbs={breadcrumbs}
      footerActions={tip && footerActions}
      sider={
        error ? null : siderContent.exampleId ? (
          <ExampleSider exampleId={siderContent.exampleId} readOnly />
        ) : siderContent.questions ? (
          <TipQuestions tip={tip} />
        ) : null
      }
    >
      {error ? (
        <ErrorState size={12} />
      ) : !tip ? (
        <Spinner size={30} style={{ height: "100%" }} />
      ) : (
        <>
          <Badge
            content={tip.questions?.length}
            max={9}
            style={{ float: "right" }}
          >
            <Button
              icon="chat"
              style={{ padding: "8px", margin: "-8px" }}
              onClick={() => setSiderContent({ questions: [] })}
            />
          </Badge>
          <Box>
            <Chip
              filled
              style={{ marginBottom: "8px" }}
              color={tip.color}
              content={<span>{_.capitalize(tip.motivator)}</span>}
            />
            <TipByline tip={tip} />
          </Box>
          <Divider style={{ margin: "20px 0" }} />
          <Box>
            <TipSummary tip={tip} />
            <Divider style={{ margin: "20px 0" }} />
            <Box display="flex" alignItems="center" marginBottom="10px">
              <Text type="label" content="Examples" />
              {!isRated && (
                <Icon
                  name="lock"
                  style={{
                    fontSize: "15px",
                    marginLeft: "4px",
                    color: "#686868",
                  }}
                />
              )}
            </Box>

            {!isRated ? (
              <>
                <Text
                  content="Please rate the tip to unlock the examples:"
                  style={{ fontStyle: "italic" }}
                />
                <TipReviewForm
                  style={{ marginTop: "8px" }}
                  onChange={(form) => setRatingForm(form)}
                />
              </>
            ) : (
              <ContentList
                entries={tip.examples?.slice(0, 2)}
                CardComponent={ExampleCard}
                onEntryClick={(ex) => setSiderContent({ exampleId: ex.id })}
                highlightedIds={
                  siderContent.exampleId ? [siderContent.exampleId] : []
                }
              />
            )}
          </Box>
        </>
      )}
    </Modal>
  );
}

export default TipReaderDLP;
