import { Text } from "../../../design-library";

function CreateExampleEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          created a new example{" "}
          <b
            className="clickable"
            onClick={() =>
              onClick({
                tip: meta.tip,
                episode: meta.episode,
                example: meta.example,
              })
            }
          >
            #{meta.example}
          </b>
          {meta.tip && (
            <>
              {" "}
              for tip{" "}
              <b
                className="clickable"
                onClick={() => onClick({ tip: meta.tip })}
              >
                #{meta.tip}
              </b>
            </>
          )}
          {meta.episode && (
            <>
              {" "}
              for episode &nbsp;
              <b
                className="clickable"
                onClick={() => onClick({ episode: meta.episode })}
              >
                #{meta.episode}
              </b>
            </>
          )}
          .
        </>
      }
    />
  );
}

export default CreateExampleEntry;
