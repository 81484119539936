import { Box, Grid, makeStyles } from "@material-ui/core";
import { Divider, Text } from "../../design-library";
import { PractitionerCard } from "../Practitioner";

import { formatDate } from "../utils";

const useStyles = makeStyles(() => ({
  itemHeader: { marginTop: "12px" },
  itemBody: { marginTop: "6px" },
}));

function EpisodeDetails({ episode }) {
  const headsUps = episode?.heads_up_fields?.filter((f) => f.value);
  const classes = useStyles();

  return (
    <Box>
      <Text content={episode.title} type="title" />
      <Box>
        <Text
          content={`Created at ${formatDate(episode.date)} by ${
            episode.user?.full_name
          }`}
          type="subtitle"
        />
      </Box>
      <Divider />
      <Text content="Practitioner" type="label" />
      <Grid container style={{ marginTop: "8px" }}>
        <Grid item xs={6}>
          <PractitionerCard entry={episode.practitioner} />
        </Grid>
      </Grid>
      <Text content="Heads up" type="label" style={{ marginTop: "8px" }} />
      {!headsUps ? (
        <Text
          content={episode.heads_up || ""}
          type="paragraph"
          className={classes.itemBody}
        />
      ) : !headsUps.length ? (
        <Box />
      ) : (
        <Grid container spacing={2} className={classes.itemBody}>
          {headsUps.map(({ label, key, value }) => (
            <Grid item xs={6} key={key}>
              <Text
                label={label}
                content={value}
                type="labeledBox"
                style={{ marginTop: "-8px" }}
                fullHeight
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Text type="label" content="Description" className={classes.itemHeader} />
      <Text
        content={episode.description || ""}
        type="paragraph"
        className={classes.itemBody}
      />
      <Text type="label" content="Transcript" className={classes.itemHeader} />
      <Text
        content={episode.transcript || ""}
        type="paragraph"
        className={classes.itemBody}
      />
    </Box>
  );
}

export default EpisodeDetails;
