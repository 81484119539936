import PropTypes from "prop-types";
import { Box, Button, IconButton } from "@material-ui/core";
import Icon from "../Icon/Icon";
import React, { useState } from "react";
import { Spinner } from "..";
import _ from "lodash";

const MyButton = React.forwardRef(
  (
    {
      label = null,
      primary = false,
      inline = false,
      disabled = false,
      isLoading = false,
      onClick = _.noop,
      icon = null,
      style = {},
      statefulLoader = false,
      iconStyle = {},
      ...moreProps
    },
    ref
  ) => {
    const [wait, setWait] = useState(false);
    if (statefulLoader) isLoading = wait;

    const statefulClickHandler = () => {
      const promise = onClick();
      if (promise instanceof Promise) {
        setWait(true);
        return promise.finally(() => setWait(false));
      }
    };

    const props = {
      variant: inline ? "text" : primary ? "contained" : "outlined",
      color: "primary",
      onClick: statefulLoader ? statefulClickHandler : onClick,
      ref,
      ...moreProps,
    };

    const spinner = (
      <Spinner
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    );

    return icon && !label ? (
      <IconButton
        {...props}
        size="small"
        disabled={isLoading || disabled}
        style={{ padding: "6px", ...style }}
      >
        <Icon
          name={icon}
          style={{ opacity: isLoading ? 0.3 : 1, ...iconStyle }}
        />
        {isLoading && spinner}
      </IconButton>
    ) : (
      <Button
        {...props}
        disabled={isLoading || disabled}
        style={
          inline
            ? { padding: "0 4px", minWidth: 0, fontWeight: "bold", ...style }
            : style
        }
      >
        <Box position="relative">
          <Box style={{ opacity: isLoading ? 0.3 : 1 }}>
            {icon && <Icon name={icon} />}
            {label}
          </Box>
          {isLoading && spinner}
        </Box>
      </Button>
    );
  }
);

MyButton.propTypes = {
  label: PropTypes.string,
  primary: PropTypes.bool,
  inline: PropTypes.bool,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  statefulLoader: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MyButton;
