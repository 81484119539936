/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { AppContext } from "../../context";
import {
  Button,
  FilterPanel,
  SearchBar,
  Spinner,
  Table,
  Tooltip,
} from "../../design-library";
import { PractitionerResource, UserResource } from "../../resources";
import {
  PractitionerCreationModal,
  PractitionerDisplayModal,
} from "../../shared-components/Practitioner";
import { useURLState } from "../../shared-components/utils";

const config = {
  columns: [
    {
      colId: "fullName",
      label: "Full Name",
    },
    {
      colId: "username",
      label: "Username",
    },
    {
      colId: "email",
      label: "Email",
    },
    {
      colId: "roleLabel",
      label: "Role",
    },
    {
      colId: "assignedStudents",
      label: "Assigned Students",
      formatter: (students) => {
        if (!students?.length) return "-";

        const first = students[0].nickname;
        if (students.length === 1) return first;

        return (
          <Tooltip content={students.map((st) => st.nickname).join(", ")}>
            <span>
              {first}
              <span
                style={{
                  fontStyle: "italic",
                  color: "#999",
                  fontSize: "12px",
                }}
              >
                {" "}
                + {students.length - 1} more
              </span>
            </span>
          </Tooltip>
        );
      },
    },
  ],
};

const filters = [
  {
    label: "Roles",
    dataKey: "role",
    options: Object.entries(UserResource.ROLE_LABELS).map(([value, label]) => ({
      label,
      value: parseInt(value),
    })),
  },
  {
    label: "Student Caseload",
    dataKey: "hasCaseload",
    options: [
      { label: "Empty", value: false },
      { label: "Not Empty", value: true },
    ],
  },
];

function formatParams(params, page) {
  return {
    username: params.username,
    page_size: params.pageSize || 20,
    page: page || 1,
    ordering: params.ordering || "full_name",
  };
}

function ManagementTab() {
  const { appDispatch } = useContext(AppContext);
  const tabConfig = PractitionerResource.getConfig("MANAGEMENT_TAB_CONFIG");

  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [params, setParams] = useURLState(tabConfig.query, ["username"]);
  const [modalContent, setModalContent] = useURLState({}, ["practitionerId"]);

  const loadNextUsers = (fromFirst = true) => {
    setIsLoading(true);
    const page = fromFirst ? 1 : nextPage;
    const fparams = formatParams(params, page);
    PractitionerResource.list(fparams, "MANAGEMENT_TAB_CONFIG")
      .then((pracs) => {
        setNextPage(pracs.length < 20 ? -1 : page + 1);
        setIsLoading(false);
      })
      .catch(() => setNextPage(-1));
  };

  useEffect(loadNextUsers, [params]);

  const rows = PractitionerResource.pickMany(tabConfig.ids)?.filter(
    (prac) =>
      filterParams.role?.includes(prac.role) &&
      filterParams.hasCaseload?.includes(prac.assignedStudents?.length > 0)
  );

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box height="60px">
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              onSearch={(query) => setParams({ username: query })}
              placeholder="Search a practitioner"
              initialValue={params.username || ""}
            />
            <Box display="inline-flex" marginLeft="12px">
              <FilterPanel
                asModal
                filters={filters}
                onChange={(vals) => setFilterParams(vals)}
              />
            </Box>
            {isLoading && (
              <Spinner
                style={{
                  display: "inline-flex",
                  height: "40px",
                  marginLeft: "12px",
                }}
              />
            )}
          </Grid>
          <Grid>
            <Button
              label="Add User"
              primary
              onClick={() => setModalContent({ addUser: true })}
            />
          </Grid>
        </Grid>
      </Box>
      <Box height="calc(100% - 60px)" overflow="auto" position="relative">
        <Table
          columns={config.columns}
          data={rows}
          onRowClick={(row) => setModalContent({ practitionerId: row.id })}
        />
        {!isLoading && nextPage !== -1 && (
          <>
            .
            <Waypoint onEnter={() => loadNextUsers(false)} />
          </>
        )}
        {nextPage > 1 && isLoading && (
          <Spinner style={{ height: "40px" }} label="Loading more..." />
        )}
      </Box>
      <PractitionerDisplayModal
        practitionerId={parseInt(modalContent.practitionerId)}
        onClose={() => setModalContent({})}
      />
      <PractitionerCreationModal
        open={modalContent.addUser}
        onClose={() => setModalContent({})}
        onCreate={(prac) => {
          tabConfig.pages[1]?.unshift(prac.id);
          appDispatch({
            type: "MANAGEMENT_TAB_CONFIG:SET",
            payload: _.pick(tabConfig, ["pages", "query"]),
          });
        }}
      />
    </Box>
  );
}

export default ManagementTab;
