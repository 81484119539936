import { SvgIcon } from "@material-ui/core";

function Student(props) {
  return (
    <SvgIcon {...props}>
      <path d="M6.14763 5.45978C6.3332 5.56843 7.58227 6.19623 8.18571 6.49854C8.18808 6.74221 8.19326 7.16359 8.20121 7.17075C8.26772 7.77415 8.38189 8.44451 8.41496 8.63381C8.36009 8.66546 8.0781 8.85731 8.0781 9.45403C8.07834 9.45739 8.07863 9.4605 8.07888 9.4639C8.07847 9.47955 8.0781 9.49533 8.0781 9.51154C8.19947 11.1701 8.8335 10.4553 8.96424 11.1818C9.18171 12.3897 10.2097 13.2637 10.8497 13.5654C11.2195 13.7397 11.6001 13.8286 12.0001 13.8371C12.4002 13.8286 12.7808 13.7397 13.1506 13.5654C13.7905 13.2637 14.8186 12.3897 15.0361 11.1818C15.1668 10.4553 15.8008 11.1701 15.9222 9.51154C15.9222 9.49529 15.9218 9.47955 15.9214 9.4639C15.9217 9.46054 15.9219 9.45744 15.9222 9.45403C15.9222 8.85727 15.6402 8.66546 15.5853 8.63381C15.6184 8.44451 15.7326 7.77419 15.7991 7.17075C15.807 7.16359 15.8122 6.74225 15.8146 6.49854C16.146 6.33247 16.672 6.06832 17.1098 5.84586V7.44917C16.9882 7.52384 16.9027 7.65563 16.9027 7.81278C16.9027 7.9519 16.9706 8.06977 17.0695 8.14768C17.0695 8.14768 16.8937 9.17613 16.8368 9.4786C16.78 9.781 17.8397 9.72034 17.792 9.4786C17.7443 9.23679 17.5594 8.14768 17.5594 8.14768C17.6583 8.06977 17.7262 7.9519 17.7262 7.81278C17.7262 7.65559 17.6411 7.52388 17.5196 7.44917V5.63637C17.6827 5.55217 17.8041 5.48815 17.8526 5.45978C17.9839 5.38291 18.0546 5.28457 18.0397 5.1824C18.0577 5.07586 17.9836 4.96136 17.7849 4.86013C16.3667 4.13791 12.7512 2.29713 12.3937 2.11972C12.2377 2.04234 12.1093 2.00815 12.0001 2C11.891 2.00815 11.7626 2.04234 11.6066 2.11972C11.2491 2.29713 7.63357 4.13795 6.21543 4.86013C6.01664 4.96136 5.94263 5.07586 5.96061 5.1824C5.94561 5.28457 6.01635 5.38291 6.14763 5.45978Z" />
      <path d="M16.8137 15.8342C16.1465 15.5839 14.9852 14.8624 14.7862 14.7511C14.7347 14.7199 14.6749 14.7017 14.611 14.7017C14.4713 14.7017 14.3512 14.787 14.2952 14.9103C13.7451 15.8684 12.7252 18.6545 12.0136 18.7061C12.0093 18.7058 12.0048 18.7049 12.0004 18.7044C11.996 18.7049 11.9916 18.7058 11.9872 18.7061C11.2756 18.6545 10.2557 15.8684 9.70563 14.9103C9.64964 14.787 9.52951 14.7017 9.3899 14.7017C9.32592 14.7017 9.2662 14.7199 9.21461 14.7511C9.01566 14.8624 7.85432 15.5839 7.18714 15.8342C4.94766 16.6743 3.94043 17.5311 3.94043 18.1153C3.94043 18.1322 3.94043 18.1516 3.94043 18.1728C3.94043 18.8843 3.94043 21.9425 3.94043 21.9425C3.94043 21.9798 3.94043 22 3.94043 22H11.9872H12.0004H12.0136H20.0604C20.0604 22 20.0604 21.9798 20.0604 21.9425C20.0604 21.9425 20.0604 18.8842 20.0604 18.1728C20.0604 18.1516 20.0604 18.1322 20.0604 18.1153C20.0604 17.5311 19.0532 16.6743 16.8137 15.8342Z" />
    </SvgIcon>
  );
}

export default Student;
