/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

import { Button, Modal } from "../../design-library";
import { Box } from "@material-ui/core";

import { CelebrationForm } from ".";
import { CelebrationResource } from "../../resources";

function CreateCelebrationModal({ open, studentId, onClose, onCreate }) {
  const [form, setForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const onSave = () => {
    setIsSaving(true);
    const data = { student: studentId, heading: form.headline, ...form };
    CelebrationResource.createOne(data)
      .then((celebration) => {
        if (onCreate) onCreate(celebration.id);
        onClose();
        setForm({});
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      breadcrumbs={["Celebrations", "Create New"]}
      footer={
        <Box textAlign="right">
          <Button
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "12px" }}
          />
          <Button primary label="Save" onClick={onSave} isLoading={isSaving} />
        </Box>
      }
    >
      <CelebrationForm
        initialValues={form}
        onUpdate={(form) => setForm(form)}
      />
    </Modal>
  );
}

export default CreateCelebrationModal;
