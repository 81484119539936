import { SvgIcon } from "@material-ui/core";

function Illustration1(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            d="m161.898 419.023h-54.389c-48.719 0-88.354-39.636-88.354-88.355v-78.332c0-19.33 15.67-35 35-35s35 15.67 35 35v78.332c0 10.121 8.234 18.355 18.354 18.355h54.389c19.33 0 35 15.67 35 35s-15.67 35-35 35z"
            fill="#9bd069"
          />
          <path
            d="m138.255 419.017h-30.75c-48.72 0-88.35-39.63-88.35-88.35v-78.33c0-19.33 15.67-35 35-35 5.51 0 10.73 1.28 15.37 3.55-11.62 5.69-19.63 17.63-19.63 31.45v78.33c0 48.72 39.64 88.35 88.36 88.35z"
            fill="#6ec17d"
          />
          <path
            d="m190.817 140.12c-31.222 0-56.532 25.31-56.532 56.532v303.848h113.064v-303.848c0-31.222-25.31-56.532-56.532-56.532z"
            fill="#9bd069"
          />
          <path
            d="m208.775 143.032c-22.42 7.5-38.58 28.68-38.58 53.62v303.85h-35.91v-303.85c0-31.22 25.31-56.53 56.53-56.53 6.28 0 12.32 1.02 17.96 2.91z"
            fill="#6ec17d"
          />
          <path
            d="m257.247 308.644h-30.395c-19.33 0-35-15.67-35-35s15.67-35 35-35h30.395c10.121 0 18.354-8.234 18.354-18.354v-43.272c0-19.33 15.67-35 35-35s35 15.67 35 35v43.272c0 48.718-39.635 88.354-88.354 88.354z"
            fill="#9bd069"
          />
          <path
            d="m323.261 144.377c-13.08 5.07-22.35 17.77-22.35 32.64v43.27c0 10.12-8.24 18.35-18.36 18.35h-25.3c10.12 0 18.35-8.23 18.35-18.35v-43.27c0-19.33 15.67-35 35-35 4.47.001 8.74.84 12.66 2.36z"
            fill="#6ec17d"
          />
        </g>
        <path
          d="m411.836 457.833h28.482c25.513 0 46.269-20.756 46.269-46.269v-21.019c0-10.123-8.206-18.329-18.329-18.329s-18.329 8.206-18.329 18.329v21.019c0 5.3-4.312 9.612-9.612 9.612h-28.482c-10.123 0-18.329 8.206-18.329 18.329s8.207 18.328 18.33 18.328z"
          fill="#9bd069"
        />
        <path
          d="m476.019 373.954c-6.25 2.91-10.57 9.24-10.57 16.59v21.02c0 5.3-4.32 9.62-9.62 9.62h-15.52c5.3 0 9.62-4.32 9.62-9.62v-21.02c0-10.12 8.2-18.32 18.32-18.32 2.78 0 5.41.62 7.77 1.73z"
          fill="#6ec17d"
        />
        <path
          d="m396.692 331.78c16.35 0 29.604 13.254 29.604 29.604v139.116h-59.209v-139.116c0-16.35 13.255-29.604 29.605-29.604z"
          fill="#9bd069"
        />
        <path
          d="m408.007 334.024c-10.75 4.44-18.3 15.01-18.3 27.36v139.12h-22.62v-139.12c0-16.35 13.26-29.6 29.61-29.6 4.01 0 7.83.79 11.31 2.24z"
          fill="#6ec17d"
        />
        <path
          d="m351.904 431.954h15.917c10.123 0 18.329-8.206 18.329-18.329s-8.206-18.329-18.329-18.329h-15.917c-5.3 0-9.612-4.312-9.612-9.612v-22.66c0-10.123-8.206-18.329-18.329-18.329s-18.329 8.206-18.329 18.329v22.66c.001 25.514 20.757 46.27 46.27 46.27z"
          fill="#9bd069"
        />
        <path
          d="m368.135 431.935c-.1.01-.21.01-.31.01h-15.92c-25.51 0-46.27-20.75-46.27-46.26v-22.67c0-10.12 8.2-18.32 18.33-18.32 2.99 0 5.82.72 8.31 2-5.94 3.02-10.01 9.19-10.01 16.32v22.67c0 25.38 20.53 46.04 45.87 46.25z"
          fill="#6ec17d"
        />
        <circle cx="428.844" cy="79.656" fill="#ffe07d" r="75.656" />
        <path
          d="m453.684 151.142c-7.778 2.707-16.143 4.177-24.843 4.177-41.776 0-75.654-33.877-75.654-75.663s33.877-75.654 75.654-75.654c8.7 0 17.064 1.47 24.843 4.178-29.588 10.272-50.82 38.39-50.82 71.476s21.232 61.214 50.82 71.486z"
          fill="#ffd064"
        />
        <g fill="#6ec17d">
          <path d="m184.19 342.724c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.357 7.5-7.5 7.5z" />
          <path d="m213.454 201.837c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m176.653 215.139c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m211.237 440.767c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m218.775 383.792c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m179.535 472.99c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m103.494 399.088c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m79.107 370.711c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.143-3.357 7.5-7.5 7.5z" />
          <path d="m57.825 292.231c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.143-3.358 7.5-7.5 7.5z" />
          <path d="m226.756 270.506c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m280.406 279.374c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m317.207 195.573c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m404.222 387.117c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m398.791 455.842c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m451.11 443.87c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.142-3.358 7.5-7.5 7.5z" />
          <path d="m340.594 418.21c-4.142 0-7.5-3.358-7.5-7.5v-.5c0-4.142 3.358-7.5 7.5-7.5s7.5 3.358 7.5 7.5v.5c0 4.143-3.358 7.5-7.5 7.5z" />
        </g>
        <path
          d="m504.5 508h-497c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h497c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
          fill="#ffd064"
        />
      </g>
    </SvgIcon>
  );
}

function Illustration2(props) {
  return (
    <SvgIcon viewBox="0 -15 504 503" {...props}>
      <path
        d="m400 200.515625c-4.425781 0-8 3.578125-8 8v32c0 4.417969-3.585938 8-8 8h-8c-4.425781 0-8 3.578125-8 8 0 4.425781 3.574219 8 8 8h8c13.230469 0 24-10.769531 24-24v-32c0-4.421875-3.574219-8-8-8zm0 0"
        fill="#8cc152"
      />
      <path
        d="m384 240.515625c0-4.421875-3.574219-8-8-8h-8c-4.414062 0-8-3.582031-8-8v-16c0-4.421875-3.574219-8-8-8s-8 3.578125-8 8v16c0 13.230469 10.769531 24 24 24h8c4.425781 0 8-3.574219 8-8zm0 0"
        fill="#8cc152"
      />
      <path
        d="m376 304.515625c-4.425781 0-8-3.574219-8-8v-104c0-4.421875 3.574219-8 8-8s8 3.578125 8 8v104c0 4.425781-3.574219 8-8 8zm0 0"
        fill="#a0d468"
      />
      <path
        d="m120 64.515625c30.960938 0 56 25.039063 56 56 0 30.878906-25.039062 56-56 56s-56-25.121094-56-56c0-30.960937 25.039062-56 56-56zm0 0"
        fill="#ffce54"
      />
      <path
        d="m120 64.515625c-4.128906 0-8.136719.480469-12 1.320313 25.167969 5.488281 44 27.839843 44 54.679687 0 26.769531-18.832031 49.175781-44 54.679687 3.863281.839844 7.871094 1.320313 12 1.320313 30.960938 0 56-25.121094 56-56 0-30.960937-25.039062-56-56-56zm0 0"
        fill="#f6bb42"
      />
      <path
        d="m136 296.515625c25.601562 0 57.441406 19.839844 82.960938 39.761719-26.488282 9.839844-55.601563 27.839844-82.960938 47.597656 0 0 24-39.359375 0-87.359375zm0 0"
        fill="#f6bb42"
      />
      <path
        d="m136 296.515625c24 48 0 87.359375 0 87.359375-.238281.242188-.480469.402344-.800781.640625h-127.199219s76.558594-88 128-88zm0 0"
        fill="#ffce54"
      />
      <path
        d="m504 384.515625h-127.199219s31.199219-32-.800781-88c51.441406 0 128 88 128 88zm0 0"
        fill="#f6bb42"
      />
      <path
        d="m376 296.515625c32 56 .800781 88 .800781 88-27.511719-20.078125-57.039062-38.320313-83.761719-48.238281 25.519532-19.921875 57.359376-39.761719 82.960938-39.761719zm0 0"
        fill="#ffce54"
      />
      <path
        d="m480 472.515625h-232s48-56 8-144c11.28125 0 23.839844 2.878906 37.039062 7.761719 26.722657 9.917968 56.242188 28.167968 83.761719 48.238281 55.597657 40.398437 103.199219 88 103.199219 88zm0 0"
        fill="#f6bb42"
      />
      <g fill="#ffce54">
        <path d="m135.199219 384.515625c.320312-.238281.554687-.398437.800781-.640625 27.359375-19.757812 56.480469-37.757812 82.960938-47.597656 13.191406-4.875 25.757812-7.761719 37.039062-7.761719 40 88-8 144-8 144h-216s47.601562-47.601563 103.199219-88zm0 0" />
        <path d="m32 128.515625h-24c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h24c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
        <path d="m232 128.515625h-24c-4.425781 0-8-3.574219-8-8 0-4.421875 3.574219-8 8-8h24c4.425781 0 8 3.578125 8 8 0 4.425781-3.574219 8-8 8zm0 0" />
        <path d="m40.816406 207.699219c-2.046875 0-4.097656-.785157-5.65625-2.34375-3.128906-3.128907-3.128906-8.183594 0-11.3125l16.96875-16.96875c3.128906-3.125 8.183594-3.125 11.3125 0 3.125 3.128906 3.125 8.1875 0 11.3125l-16.96875 16.96875c-1.570312 1.570312-3.617187 2.34375-5.65625 2.34375zm0 0" />
        <path d="m182.230469 66.285156c-2.046875 0-4.09375-.785156-5.65625-2.34375-3.125-3.128906-3.125-8.183594 0-11.3125l16.96875-16.96875c3.128906-3.128906 8.183593-3.128906 11.3125 0 3.128906 3.128906 3.128906 8.183594 0 11.3125l-16.96875 16.96875c-1.558594 1.558594-3.605469 2.34375-5.65625 2.34375zm0 0" />
        <path d="m120 240.5c-4.425781 0-8-3.574219-8-8v-24c0-4.425781 3.574219-8 8-8s8 3.574219 8 8v24c0 4.425781-3.574219 8-8 8zm0 0" />
        <path d="m120 40.5c-4.425781 0-8-3.574219-8-8v-24c0-4.425781 3.574219-8 8-8s8 3.574219 8 8v24c0 4.425781-3.574219 8-8 8zm0 0" />
        <path d="m199.183594 207.691406c-2.046875 0-4.09375-.785156-5.65625-2.34375l-16.96875-16.976562c-3.125-3.128906-3.125-8.183594 0-11.3125 3.128906-3.125 8.183594-3.125 11.3125 0l16.96875 16.976562c3.128906 3.128906 3.128906 8.183594 0 11.3125-1.558594 1.566406-3.609375 2.34375-5.65625 2.34375zm0 0" />
        <path d="m57.769531 66.277344c-2.050781 0-4.097656-.785156-5.65625-2.34375l-16.96875-16.96875c-3.128906-3.128906-3.128906-8.183594 0-11.3125 3.128907-3.128906 8.183594-3.128906 11.3125 0l16.96875 16.96875c3.125 3.125 3.125 8.183594 0 11.3125-1.570312 1.558594-3.609375 2.34375-5.65625 2.34375zm0 0" />
      </g>
    </SvgIcon>
  );
}

function Illustration3(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <g>
        <path
          d="m264.538 438.936.441-1.512-3.251-2.221c-29.372-20.065-68.043-20.065-97.415 0l-36.816 25.149c-2.637 1.802-1.362 5.929 1.832 5.929h93.406l4.24-1.685z"
          fill="#fccd7f"
        />
        <g>
          <g>
            <path
              d="m124.581 249.629c21.35 0 42.01 5.843 60.128 16.919l44.123-43.097-55.922-54.621-91.146 89.026c13.53-5.413 28.007-8.227 42.817-8.227z"
              fill="#fccd7f"
            />
          </g>
          <path
            d="m166.013 218.136c4.416-4.416 6.897-10.406 6.897-16.652v-32.654l-91.146 89.026c13.529-5.414 28.007-8.227 42.816-8.227 20.239 0 39.86 5.243 57.281 15.222l-14.332-12.022c-10.584-8.879-11.286-24.922-1.516-34.693z"
            fill="#e9b665"
          />
          <path
            d="m115.207 250.017 57.703-56.361v-24.825l-91.146 89.026c10.66-4.266 21.908-6.91 33.443-7.84z"
            fill="#d8aa5e"
          />
          <path
            d="m189.683 269.743 26.687 18.23c9.317-.948 18.717-1.438 28.175-1.438 16.552 0 32.929 1.477 48.985 4.36l30.964-21.152c19.262-13.158 41.774-20.113 65.102-20.113 9.522 0 18.903 1.178 27.987 3.445l-110.255-107.69-123.503 120.629c1.98 1.187 3.939 2.418 5.858 3.729z"
            fill="#fccd7f"
          />
          <path
            d="m189.683 269.743 26.687 18.23c9.317-.948 18.717-1.438 28.175-1.438 16.552 0 32.929 1.477 48.985 4.36l22.299-15.233-13.825-13.825c-12.943-12.942-10.531-34.55 4.946-44.321 10.792-6.813 15.75-19.962 12.143-32.204l-11.765-39.928-123.503 120.63c1.98 1.187 3.939 2.418 5.858 3.729z"
            fill="#e9b665"
          />
          <path
            d="m189.683 269.743 14.178 9.686 111.014-108.432-7.547-25.613-123.503 120.63c1.98 1.187 3.939 2.418 5.858 3.729z"
            fill="#d8aa5e"
          />
          <path
            d="m217.909 287.814-27.663-18.897c-39.598-27.05-91.733-27.05-131.331 0l-52.655 35.97c-2.637 1.802-1.362 5.929 1.832 5.929h123.236c27.368-12.347 56.543-20.115 86.581-23.002z"
            fill="#fdb441"
          />
          <g>
            <path
              d="m92.921 277.347c22.883-15.632 49.951-22.211 76.368-19.776-35.85-14.919-77.444-11.15-110.374 11.346l-52.655 35.97c-2.637 1.802-1.362 5.929 1.832 5.929h35.835z"
              fill="#fe9901"
            />
          </g>
          <path
            d="m507.917 304.887-52.655-35.969c-39.598-27.05-91.733-27.05-131.331 0l-31.804 21.726c22.656 3.947 44.675 10.717 65.635 20.173h148.324c3.193-.001 4.468-4.129 1.831-5.93z"
            fill="#fdb441"
          />
          <path
            d="m324.158 298.232 42.914-29.315c13.563-9.265 28.598-15.354 44.095-18.272-29.747-5.602-61.201.487-87.236 18.272l-31.804 21.726c10.838 1.888 21.53 4.425 32.031 7.589z"
            fill="#fe9901"
          />
          <g>
            <path
              d="m88.399 333.777-29.289 20.008c-2.637 1.802-1.362 5.929 1.832 5.929h367.204c3.194 0 4.469-4.127 1.832-5.929l-29.289-20.008c-94.159-64.323-218.129-64.323-312.29 0z"
              fill="#e9b665"
            />
          </g>
          <g>
            <path
              d="m139.595 342.034c52.364-35.771 113.947-51.638 174.496-47.623-75.954-19.699-158.428-6.583-225.692 39.366l-29.289 20.008c-2.637 1.802-1.362 5.929 1.832 5.929h52.773z"
              fill="#d8aa5e"
            />
          </g>
        </g>
        <g>
          <circle cx="410.704" cy="84.765" fill="#fed402" r="46.546" />
        </g>
        <g>
          <path
            d="m398.482 84.765c0-19.643 12.174-36.432 29.384-43.266-5.312-2.109-11.099-3.281-17.162-3.281-25.707 0-46.546 20.839-46.546 46.546s20.839 46.546 46.546 46.546c6.063 0 11.85-1.172 17.162-3.281-17.21-6.831-29.384-23.62-29.384-43.264z"
            fill="#fac600"
          />
        </g>
        <g>
          <path
            d="m113.024 415.925h-51.959c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h51.959c4.142 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z"
            fill="#fbc56d"
          />
        </g>
        <g>
          <path
            d="m161.615 460.352 36.816-25.149c9.751-6.661 20.528-11.097 31.648-13.335-22.377-4.504-46.145-.068-65.767 13.335l-36.816 25.149c-2.637 1.802-1.362 5.929 1.832 5.929h34.118c-3.193 0-4.468-4.127-1.831-5.929z"
            fill="#fbc56d"
          />
        </g>
        <g>
          <path
            d="m284.07 424.382-61.334 41.898h243.489c3.194 0 4.469-4.127 1.832-5.929l-52.655-35.97c-39.6-27.049-91.734-27.049-131.332.001z"
            fill="#e9b665"
          />
        </g>
        <g>
          <path
            d="m366.912 405.367c-28.429-4.234-58.086 2.104-82.842 19.015l-61.334 41.898h34.353l61.334-41.898c14.842-10.138 31.445-16.477 48.489-19.015z"
            fill="#d8aa5e"
          />
        </g>
        <g>
          <path
            d="m504.5 405.1h-69.6c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h69.6c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
            fill="#d8aa5e"
          />
        </g>
        <g>
          <path
            d="m78.943 473.781h-71.443c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h71.443c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
            fill="#e9b665"
          />
        </g>
        <g>
          <path
            d="m24.832 367.213h-15.155c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h15.155c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
            fill="#e9b665"
          />
        </g>
        <g>
          <path
            d="m504.5 367.213h-32.796c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h32.796c4.142 0 7.5 3.358 7.5 7.5s-3.358 7.5-7.5 7.5z"
            fill="#e9b665"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

const Illustrations = [Illustration1, Illustration2, Illustration3];
export default Illustrations;
