import { UserResource } from "../../../resources";
import TipReaderRegular from "./TipReaderRegular";
import TipReaderDLP from "./TipReaderDLP";

function TipDisplayModal(props) {
  const TipReader = UserResource.current?.isDLP
    ? TipReaderDLP
    : TipReaderRegular;

  return <TipReader {...props} />;
}

export default TipDisplayModal;
