/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useEffect, useState } from "react";
import { ExampleDetails, ExampleForm } from ".";
import { Spinner, Text } from "../../design-library";
import { ExampleResource, TipResource } from "../../resources";
import { TipCard, TipSelector } from "../Tip";

function ExampleSider({
  exampleId,
  editMode,
  readOnly,
  setModalContent,
  onFormUpdate = _.noop,
}) {
  const [form, setForm] = useState({});

  const example = ExampleResource.pick(exampleId);
  const tipId = example?.tipId;

  useEffect(() => onFormUpdate(form), [form]);
  useEffect(() => ExampleResource.getOne(exampleId), [exampleId]);
  useEffect(() => {
    if (tipId) TipResource.getOne(tipId);
  }, [tipId]);

  return (
    <>
      {!example ? (
        <Spinner size={30} style={{ height: "100%" }} />
      ) : readOnly ? (
        <ExampleDetails example={example} />
      ) : editMode ? (
        <ExampleForm
          initialValues={_.pick(example, [
            "headline",
            "outcome",
            "shadows_response",
            "situation",
          ])}
          onUpdate={(form) => setForm(form)}
        />
      ) : (
        <>
          <ExampleDetails example={example} />
          <Text type="label" content="Tip" style={{ margin: "12px 0 8px 0" }} />
          {!example.tipId ? (
            <TipSelector onSelect={(tipId) => example.setTip(tipId)} />
          ) : example.tip ? (
            <TipCard
              truncate={1}
              tip={example.tip}
              onClick={
                setModalContent &&
                (() => setModalContent({ tip: example.tipId }))
              }
              onCross={() => example.setTip(null)}
            />
          ) : (
            <Spinner />
          )}
        </>
      )}
    </>
  );
}

export default ExampleSider;
