/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { Button, SearchBar, Text } from "../../design-library";
import { TipResource, UserResource } from "../../resources";
import { CreateTipModal } from "../../shared-components/Tip";
import {
  standardTipFilters,
  dlpTipFilters,
  TipCard,
  TipGrid,
} from "../../shared-components/Tip";
import { useURLState } from "../../shared-components/utils";

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  newBadge: {
    background: ({ tipColor }) => tipColor,
    color: "white",
    padding: "0 2px",
    fontSize: "90%",
  },
}));

function formatParams(params) {
  return {
    search_text: params.search_text,
    page_size: 500,
    page: 1,
  };
}

function TipCardRegular(props) {
  const tip = props.entry || props.tip;
  return <TipCard {...props} highlighted={tip.is_new} truncate={1} />;
}

function TipCardDLP({ entry: tip, ...props }) {
  const classes = useStyles({ tipColor: tip.color });

  return (
    <TipCard
      entry={tip}
      {...props}
      truncate={2}
      subtext={
        !tip.read_count ? (
          <span className={classes.newBadge}>New</span>
        ) : !tip.is_rated ? (
          <>Please rate the tip</>
        ) : !tip.try_count ? (
          <>Never tried</>
        ) : (
          <>
            Tried {tip.try_count} &bull; Useful {tip.helpful_count}
          </>
        )
      }
    />
  );
}

export default function Page() {
  const { appDispatch } = useContext(AppContext);
  const config = TipResource.getConfig("BROWSE_TIPS_CONFIG");

  const [createTipModalOpen, setCreateTipModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useURLState(config.query, ["search_text"]);

  const loadTips = () => {
    setIsLoading(true);

    const fp = formatParams(params);
    TipResource.list(fp, "BROWSE_TIPS_CONFIG").then(() => setIsLoading(false));
  };

  useEffect(loadTips, [params]);

  useEffect(() => {
    document.title = "Learning Seeds | Browse Tips";
  }, []);

  const tips = TipResource.pickMany(config.ids);
  const user = UserResource.current;
  const tipsTried = user.practitioner?.unique_tried_tips_count;
  const filters = user.isDLP
    ? [...standardTipFilters, ...dlpTipFilters]
    : standardTipFilters;

  const classes = useStyles();

  return (
    <Box height="100%">
      <Box
        height="100px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              onSearch={(query) => setParams({ ...params, search_text: query })}
              placeholder="Search a tip"
              initialValue={params.search_text || ""}
            />
            {isLoading && (
              <Box
                display="inline-flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                marginLeft="12px"
              >
                <CircularProgress size={20} />
              </Box>
            )}
          </Grid>
          <Grid item>
            {user.isDLP ? (
              <Box height="100%" className={classes.pageHeader}>
                <Text content={`Tips tried: ${tipsTried}`} type="label" />
              </Box>
            ) : (
              <Button
                label="Create Tip"
                primary
                onClick={() => setCreateTipModalOpen(true)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box height="calc(100% - 100px)" overflow="auto" position="relative">
        <TipGrid
          tips={tips}
          filters={filters}
          CardComponent={user.isDLP ? TipCardDLP : TipCardRegular}
        />
      </Box>
      {!user.isDLP && (
        <CreateTipModal
          open={createTipModalOpen}
          onCreate={(tip) => {
            config.pages[1]?.unshift(tip.id);
            appDispatch({
              type: "BROWSE_TIPS_CONFIG:SET",
              payload: _.pick(config, ["pages", "query"]),
            });
          }}
          onClose={() => setCreateTipModalOpen(false)}
        />
      )}
    </Box>
  );
}
