import _ from "lodash";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

function MyAlert({ message, type = "info", open = false, onClose = _.noop }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={type}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

MyAlert.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool,
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
  onClose: PropTypes.func,
};

export default MyAlert;
