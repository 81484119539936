import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: ({ themeColor, clickable, highlighted }) => ({
    borderLeft: `3px solid ${themeColor}`,
    backgroundColor: `${themeColor}22`,
    cursor: clickable ? "pointer" : "auto",
    color: "#686868",
    fontSize: "14px",
    outline: highlighted ? `1px solid ${themeColor}` : "",
  }),
}));

function ContentCard({
  entry,
  highlighted = false,
  truncate = null,
  themeColor = "#00B5B2",
  onClick,
}) {
  const classes = useStyles({ themeColor, clickable: !!onClick, highlighted });
  return (
    <Box padding={1} className={classes.root} onClick={onClick}>
      <span className={truncate ? `truncate-${truncate}` : ""}>
        {entry.title}
      </span>
    </Box>
  );
}

ContentCard.propTypes = {
  entry: PropTypes.object.isRequired,
  highlighted: PropTypes.bool,
  truncate: PropTypes.oneOf([1, 2]),
  themeColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default ContentCard;
