import { Box } from "@material-ui/core";
import { useState } from "react";
import { PractitionerForm } from ".";
import { Modal, Text, Button, Divider, Select } from "../../design-library";
import { UserResource } from "../../resources";

function PractitionerUpdateModal({ practitioner, onClose }) {
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = () => {
    setIsLoading(true);
    practitioner
      .update(form)
      .then(onClose)
      .finally(() => setIsLoading(false));
  };

  const roles = Object.values(UserResource.ROLES).map((value) => ({
    label: UserResource.ROLE_LABELS[value],
    value,
  }));

  const isSelf = practitioner?.id === UserResource.current.id;
  const breadcrumbs = ["Practitioners", practitioner?.id || "-", "Update"];

  return (
    <Modal
      open={!!practitioner}
      onClose={onClose}
      breadcrumbs={isSelf ? ["Update Your Info"] : breadcrumbs}
      footer={
        <Box textAlign="right">
          <Button
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "12px" }}
            disabled={isLoading}
          />
          <Button
            primary
            label="Save"
            onClick={handleUpdate}
            isLoading={isLoading}
          />
        </Box>
      }
    >
      <Text type="label" content="Bio" style={{ marginBottom: "12px" }} />
      <PractitionerForm initialValue={practitioner} onUpdate={setForm} />
      {practitioner && !isSelf && (
        <>
          <Divider />
          <Text type="label" content="Role" style={{ margin: "12px 0" }} />
          <Select
            options={roles}
            value={form.role || practitioner?.role}
            onChange={(e) => setForm({ ...form, role: e.target.value })}
          />
        </>
      )}
    </Modal>
  );
}

export default PractitionerUpdateModal;
