import { Text } from "../../../design-library";

function AttachTipWithExampleEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          attached tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; with example &nbsp;
          <b
            className="clickable"
            onClick={() => onClick({ tip: meta.tip, example: meta.example })}
          >
            #{meta.example}
          </b>
          .
        </>
      }
    />
  );
}

export default AttachTipWithExampleEntry;
