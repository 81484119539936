import { Box, makeStyles } from "@material-ui/core";
import { Fragment } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Text } from "../../design-library";
import { UserResource } from "../../resources";
import { relativeTimeFormat } from "../utils";

const useStyles = makeStyles(() => ({
  root: {
    margin: "-4px -12px",
    padding: "4px 12px",
    "&:hover": {
      backgroundColor: "#00B5B210",
    },
  },
  dot: { color: "#00B5B2", float: "right", fontFamily: "Lato" },
}));

function Clickable({ id, label, type, extra = {}, onClick }) {
  const history = useHistory();
  if (type === "student")
    onClick = (ob) => history.push(`/students/${id}/tips`);

  return (
    <span
      className="clickable"
      onClick={() => onClick({ [type]: id, ...extra })}
    >
      {label ? label : <>#{id}</>}
    </span>
  );
}

const actionLineMap = {
  create_question: ({ obj, onClick }) => (
    <>
      asked a question about tip{" "}
      <Clickable
        id={obj.tip}
        extra={{ questions: [obj.question] }}
        onClick={onClick}
        type="tip"
      />
    </>
  ),
  reply_question: ({ obj, onClick }) => (
    <>
      replied in a thread about tip{" "}
      <Clickable
        id={obj.tip}
        extra={{ questions: [obj.question] }}
        onClick={onClick}
        type="tip"
      />
    </>
  ),
  suggest_tip: ({ obj, onClick }) => {
    const user = UserResource.current;

    return (
      <>
        added tip <Clickable id={obj.tip} onClick={onClick} type="tip" /> to{" "}
        {user?.role === UserResource.ROLES.DLP ? (
          <>your grid</>
        ) : (
          <>
            <Clickable
              id={obj.student}
              label={obj.student_nickname}
              onClick={onClick}
              type="student"
            />
            's grid
          </>
        )}
      </>
    );
  },
  create_episode: ({ obj, onClick }) => (
    <>
      created a new episode{" "}
      <Clickable id={obj.episode} onClick={onClick} type="episode" /> for{" "}
      <Clickable
        id={obj.student}
        label={obj.student_nickname}
        onClick={onClick}
        type="student"
      />
    </>
  ),
  assign_student: ({ obj, onClick }) => (
    <>
      assigned{" "}
      <Clickable
        id={obj.student}
        label={obj.student_nickname}
        onClick={onClick}
        type="student"
      />{" "}
      to your caseload
    </>
  ),
  attach_tip_with_example: ({ obj, onClick }) => (
    <>
      attached tip <Clickable id={obj.tip} onClick={onClick} type="tip" /> to
      example <Clickable id={obj.example} onClick={onClick} type="example" />
    </>
  ),
  set_tip_edit_mark: ({ obj, onClick }) => (
    <>
      marked tip <Clickable id={obj.tip} onClick={onClick} type="tip" /> for
      editing
    </>
  ),
  create_struggle: ({ obj, onClick }) => (
    <>
      created a new struggle{" "}
      <Clickable id={obj.struggle} onClick={onClick} type="struggle" /> for{" "}
      <Clickable
        id={obj.student}
        label={obj.student_nickname}
        onClick={onClick}
        type="student"
      />
    </>
  ),
  reply_struggle: ({ obj, onClick }) => (
    <>
      replied to struggle{" "}
      <Clickable id={obj.struggle} onClick={onClick} type="struggle" /> for{" "}
      <Clickable
        id={obj.student}
        label={obj.student_nickname}
        onClick={onClick}
        type="student"
      />
    </>
  ),
  attach_related_tips_with_tip: ({ obj, onClick }) => (
    <>
      marked the following tips as related to tip{" "}
      <Clickable id={obj.tip} onClick={onClick} type="tip" />:
      {obj.related_tips?.map((id) => (
        <Fragment key={id}>
          &nbsp;
          <Clickable id={id} onClick={onClick} type="tip" />
        </Fragment>
      ))}
    </>
  ),
};

function NotificationEntry({ entry, onClick }) {
  const classes = useStyles();
  const { verb, action_object } = entry;

  const ActionLine = actionLineMap[verb];
  if (!ActionLine) return <></>;

  return (
    <Box className={classes.root} onClick={() => entry.markAsRead()}>
      <Text content={relativeTimeFormat(entry.created_at)} type="subtitle" />
      {entry.unread && <span className={classes.dot}>&bull;</span>}
      <Text
        content={
          <>
            <b>{entry.actor?.full_name || "-"}</b>{" "}
            <ActionLine obj={action_object} onClick={onClick} />.
          </>
        }
      />
    </Box>
  );
}

export default NotificationEntry;
