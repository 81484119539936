import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Text } from "../../../design-library";

function SuggestTipEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          added tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; to the following student-grids: &nbsp;
          {meta.students.map((st, idx) => (
            <Fragment key={st.id || st}>
              {idx > 0 && ", "}
              <Link
                to={`/students/${st.id || st}/tips`}
                style={{ textDecoration: "none" }}
                className="clickable"
              >
                {`${st.nickname ? "" : "#"}`}
                {st.nickname || st.id || st}
              </Link>
            </Fragment>
          ))}
          .
        </>
      }
    />
  );
}

export default SuggestTipEntry;
