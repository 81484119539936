import { ActivityResource, TipResource } from ".";
import Base from "./base";

export default class ExampleResource extends Base {
  static __requiredFields = {
    createOne: [
      { key: "headline", label: "Headline" },
      { key: "situation", label: "Child Context" },
      { key: "shadows_response", label: "Shadow Reponse" },
      { key: "outcome", label: "Outcome" },
    ],
    update: [],
  };

  get __properties() {
    return [
      "id",
      "tipId",
      "episode",
      "episode_student_id",
      "description",
      "example_type",
      "context_notes",
      "shadows_response",
      "outcome",
      "headline",
      "situation",
      "description",
      "goal",
      "added",
      "added_by",
      "is_bookmarked",
    ];
  }

  get __resource() {
    return "EXAMPLE";
  }

  get __endpoint() {
    return "/v1/examples";
  }

  static track(action, obj, meta) {
    switch (action) {
      case "create":
        return ActivityResource.activities.CREATE_EXAMPLE({
          example: obj.id,
          tip: obj.tipId,
          episode: obj.episode,
        });
      case "edit":
        return ActivityResource.activities.EDIT_EXAMPLE({ example: obj.id });
      case "attach_tip":
        return ActivityResource.activities.ATTACH_TIP_WITH_EXAMPLE({
          example: obj.id,
          tip: meta.tip,
        });
      case "detach_tip":
        return ActivityResource.activities.DETACH_TIP_FROM_EXAMPLE({
          example: obj.id,
          tip: meta.tip,
        });
      case "toggle_bookmark":
        return ActivityResource.activities.TOGGLE_EXAMPLE_BOOKMARK({
          example: obj.id,
          is_bookmarked: meta.is_bookmarked,
        });
      default:
        return null;
    }
  }

  async setTip(tipId) {
    const attachedTip = tipId || this.tipId;

    try {
      await this.update({ tip: tipId }, true, true);
      await TipResource.pick(tipId)?.loadExamples();

      const ii = tipId ? "attach" : "detach";
      ExampleResource.alert.success(`The tip has been ${ii}ed.`);
      ExampleResource.track(`${ii}_tip`, this, { tip: attachedTip });

      return this;
    } catch (err) {
      ExampleResource.alert.error("Failed to update the example.");
      throw err;
    }
  }

  async toggleBookmark() {
    try {
      const is_bookmarked = !this.is_bookmarked;
      await this.update({ is_bookmarked }, undefined, true);

      const msg = is_bookmarked
        ? "The example is bookmarked"
        : "The example is removed from the bookmark";

      ExampleResource.alert.success(msg);
      ExampleResource.track(
        ActivityResource.types.TOGGLE_EXAMPLE_BOOKMARK,
        this,
        { is_bookmarked }
      );
    } catch (err) {
      ExampleResource.alert.error("Failed to toggle the bookmark.");
      throw err;
    }
  }

  static toObject(data) {
    data.tipId = data.tip;
    if (!data.situation) {
      data.situation = [data.description, data.goal].join("\n");
    }

    return super.toObject(data);
  }

  get tip() {
    return this.tipId && TipResource.pick(this.tipId);
  }
}
