import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";

function MyAvatar({ name, image = null, size = 8 }) {
  return (
    <Avatar
      alt={name}
      src={image}
      style={{ width: size * 8, height: size * 8 }}
    />
  );
}

MyAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  size: PropTypes.number,
};

export default MyAvatar;
