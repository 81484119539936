import PropTypes from "prop-types";
import { Badge, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  badge: {
    color: "white",
    backgroundColor: "#00B5B2",
    fontWeight: "bold",
    fontSize: "10px",
    height: "16px",
    minWidth: "16px",
    padding: "0 4px",
  },
}));

function MyBadge({ content = 0, children, max = 9, style = {} }) {
  const classes = useStyles();
  return (
    <Badge
      classes={{ badge: classes.badge }}
      badgeContent={content}
      max={max}
      style={style}
    >
      {children}
    </Badge>
  );
}

MyBadge.propTypes = {
  content: PropTypes.number,
  children: PropTypes.node.isRequired,
  max: PropTypes.number,
};

export default MyBadge;
