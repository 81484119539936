import { Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({ root: { margin: "12px 0" } }));

function MyDivider({ style }) {
  const classes = useStyles();
  return <Divider className={classes.root} style={style} />;
}

export default MyDivider;
