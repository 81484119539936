import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { forwardRef } from "react";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#686868",
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "6px",
  },
  default: {
    color: "#686868",
    fontSize: "14px",
  },
  subtitle: {
    color: "#C5C5C5",
    fontSize: "14px",
  },
  label: {
    color: "#686868",
    fontWeight: "bold",
    fontSize: "16px",
  },
  paragraph: {
    color: "#686868",
    fontSize: "15px",
    whiteSpace: "pre-wrap",
    textAlign: "justify",
    wordBreak: "break-word",
  },
  clickable: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    cursor: "pointer",
    fontWeight: "bold",
  },
  labeledBox: {
    color: "#686868",
    fontSize: "15px",
    whiteSpace: "pre-wrap",
    textAlign: "justify",
    wordBreak: "break-word",
    padding: "8px 12px",
    border: "1px solid #B5B5B5",
  },
  labeledBoxLabel: {
    fontSize: "12px",
    fontWeight: "bold",
    position: "absolute",
    color: "#686868",
    top: "8px",
    fontFamily: "Lato",
    left: "8px",
    padding: "0 4px",
    background: "white",
    transform: "translate(0, -50%)",
  },
}));

const Text = forwardRef(
  (
    {
      label,
      content,
      type = "default",
      style,
      truncate = false,
      onClick,
      className,
      fullHeight = false,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    const classNames = [className, classes[type]];
    if (truncate) {
      classNames.push("truncate-1");
    }

    const containerStyle = {};
    style = _.clone(style) || {};
    if (type === "labeledBox") {
      Object.keys(style).forEach((key) => {
        if (key.startsWith("margin")) {
          containerStyle[key] = style[key];
          style[key] = undefined;
        }
      });

      if (fullHeight) {
        style.height = "100%";
        containerStyle.height = "calc(100% + 8px)";
      }
    }

    const dom = (
      <Typography
        onClick={onClick}
        className={classNames.join(" ")}
        style={style}
        {...props}
        ref={ref}
      >
        {content}
      </Typography>
    );

    if (type !== "labeledBox") return dom;

    return (
      <Box position="relative" paddingTop="8px" style={containerStyle}>
        {dom}
        <span className={classes.labeledBoxLabel}>{label}</span>
      </Box>
    );
  }
);

Text.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf([
    "title",
    "subtitle",
    "label",
    "paragraph",
    "clickable",
    "labeledBox",
  ]),
};

export default Text;
