/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Breadcrumbs,
  Grid,
  makeStyles,
  Typography,
  Chip,
} from "@material-ui/core";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AppContext } from "../../context";
import {
  Button,
  ContentList,
  Spinner,
  Text,
  Divider,
  Select,
} from "../../design-library";
import { TipResource } from "../../resources";
import { ExampleDetails } from "../../shared-components/Example";
import {
  TipByline,
  TipCard,
  TipGrid,
  TipSummary,
} from "../../shared-components/Tip";

const useStyles = makeStyles((theme) => ({
  controls: {
    height: "60px",
    "@media print": {
      display: "none !important",
    },
  },
  content: {
    height: "calc(100% - 60px)",
    overflow: "auto",
    "@media print": {
      height: "100% !important",
      overflow: "initial !important",
    },
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "Lato",
    fontSize: "12px",
    padding: "0 2px 0 6px",
    height: "24px",
    boxSizing: "border-box",
  },
}));

function TipCardComponent({ entry: tip }) {
  return <TipCard entry={tip} truncate={0} />;
}

function ExampleDetailsCardComponent({ entry }) {
  return <ExampleDetails example={entry} printMode />;
}

function Print({ exampleSelection }) {
  const {
    appState: { printStage },
  } = useContext(AppContext);

  const { id: studentId } = useParams();
  const [tips, setTips] = useState();
  const [progress, setProgress] = useState("0/-");

  const classes = useStyles();
  const tipIds = printStage[studentId] || [];

  useEffect(() => {
    TipResource.getTipsAndExamples(tipIds, setProgress).then((tips) =>
      setTips(tips)
    );

    document.title = "Learning Seeds | Print";
  }, [tipIds.join(",")]);

  const bookmarkedExampleFilter = (ex) => ex.is_bookmarked;
  const stuExampleFilter = (ex) =>
    ex.episode_student_id === parseInt(studentId);

  const exampleFilter =
    exampleSelection === "bookmarked"
      ? bookmarkedExampleFilter
      : exampleSelection === "student"
      ? stuExampleFilter
      : _.constant(true);

  return !tips ? (
    <Spinner label={progress} />
  ) : (
    <Box>
      <Box style={{ pageBreakAfter: "always" }}>
        <TipGrid CardComponent={TipCardComponent} tips={tips} printMode />
      </Box>
      {tips.map((tip) => (
        <Box
          width="100%"
          style={{
            pageBreakAfter: "always",
            marginBottom: "12px",
            background: "white",
          }}
          key={tip.id}
        >
          <Box className={classes.title}>
            <Breadcrumbs style={{ color: "white" }}>
              {["Tips", tip.id].map((crumb) => (
                <Typography key={crumb} style={{ fontSize: "14px" }}>
                  {crumb}
                </Typography>
              ))}
            </Breadcrumbs>
          </Box>

          <Box padding={2}>
            <Chip
              size="small"
              style={{
                fontSize: "11px",
                color: "white",
                height: "20px",
                backgroundColor: tip.color,
                margin: "8px 0",
              }}
              label={<span>{_.capitalize(tip.motivator)}</span>}
            />
            <TipByline tip={tip} />
            <Box marginTop="12px">
              <TipSummary tip={tip} />
              {exampleSelection !== "none" && (
                <>
                  <Text
                    type="label"
                    content="Examples"
                    style={{ margin: "12px 0" }}
                  />

                  <ContentList
                    entries={tip.examples.filter(exampleFilter)}
                    CardComponent={ExampleDetailsCardComponent}
                    spacing={2}
                  />
                </>
              )}
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
}

export default function PrintNProgressTab({ student }) {
  const classes = useStyles();
  const history = useHistory();
  const [exampleSelection, setExampleSelection] = useState("all");

  const handleClear = () => {
    TipResource.clearPrintStage(student.id);
    history.push(`/students/${student.id}/tips`);
  };

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box className={classes.controls}>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid item>
            <Select
              label="Examples"
              options={[
                { label: "Show all", value: "all" },
                { label: `Show only ${student.nickname}'s`, value: "student" },
                { label: "Show only bookmarked", value: "bookmarked" },
                {
                  label: "Hide all",
                  value: "none",
                },
              ]}
              value={exampleSelection}
              onChange={(e) => setExampleSelection(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              label="Clear All"
              style={{ marginRight: "12px" }}
              onClick={handleClear}
            />
            <Button label="Print" primary onClick={() => window.print()} />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.content}>
        <Print exampleSelection={exampleSelection} />
      </Box>
    </Box>
  );
}
