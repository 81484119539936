import PropTypes from "prop-types";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import Icon from "../Icon/Icon";

const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
  input: {
    color: "#686868",
  },
  fullHeight: {
    height: "100%",
  },
}));

function MyInput({
  inputRef,
  label,
  placeholder,
  icon,
  type,
  helperText,
  error = false,
  value,
  multiline = false,
  readOnly = false,
  fullWidth = false,
  fullHeight = false,
  style = {},
  noBorder = false,
  variant = "outlined",
  onChange,
  onEnterPress,
  onClear,
  shrinkLabel = false,
  initialValue,
  onClick,
  onBlur,
}) {
  const classes = useStyles();
  const inputProps = { className: classes.input };

  if (noBorder) {
    inputProps.classes = { notchedOutline: classes.noBorder };
  }

  if (icon) {
    inputProps.startAdornment = (
      <InputAdornment position="start">
        <Icon name={icon} style={{ color: "#686868" }} />
      </InputAdornment>
    );
  }

  if (readOnly) {
    inputProps.readOnly = true;
  }

  const rootClasses = {};
  if (fullHeight) {
    rootClasses.root = classes.fullHeight;
  }

  return (
    <TextField
      classes={rootClasses}
      onClick={onClick}
      fullWidth={fullWidth}
      defaultValue={initialValue}
      value={value}
      multiline={multiline}
      inputRef={inputRef}
      label={label}
      placeholder={placeholder}
      style={{ background: "white", ...style }}
      variant={variant}
      size="small"
      type={type}
      onChange={(e) => {
        onChange(e);
        if (onClear && !e.target.value) {
          onClear();
        }
      }}
      onKeyPress={(e) => {
        if (onEnterPress && e.key === "Enter") {
          onEnterPress(e);
        }
      }}
      helperText={helperText}
      error={error}
      InputProps={{
        ...inputProps,
        onDragStart: (e) => e.preventDefault(),
        style: { height: "100%", alignItems: fullHeight ? "start" : "center" },
      }}
      InputLabelProps={{ shrink: shrinkLabel }}
      onBlur={onBlur}
    />
  );
}

MyInput.propTypes = {
  inputRef: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  style: PropTypes.object,
  noBorder: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  onClear: PropTypes.func,
  shrinkLabel: PropTypes.bool,
  initialValue: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
};

export default MyInput;
