import Base from "./base";

export default class CelebrationResource extends Base {
  static __requiredFields = {
    createOne: [
      { key: "headline", label: "Headline" },
      { key: "celebration", label: "Celebration" },
    ],
    update: [
      { key: "headline", label: "Headline" },
      { key: "celebration", label: "Celebration" },
    ],
  };

  get __properties() {
    return [
      "id",
      "headline",
      "celebration",
      "added_by",
      "created_at",
      "episode_id",
      "student_id",
    ];
  }

  get __resource() {
    return "CELEBRATION";
  }

  get __endpoint() {
    return "/v1/celebrations";
  }

  static toObject(data) {
    const celebration = super.toObject(data);
    celebration.student_id = data.student;
    celebration.episode_id = data.episode;

    return celebration;
  }

  get practitioner() {
    return this.added_by?.full_name;
  }
}
