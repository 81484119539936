import { SvgIcon } from "@material-ui/core";

function EditMark(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.4285 12.0005C18.034 12.0005 17.7142 12.3203 17.7142 12.7148V19.8575C17.7142 20.252 17.3944 20.5718 16.9999 20.5718H4.14285C3.74835 20.5718 3.42855 20.252 3.42855 19.8575V5.57202C3.42855 5.17753 3.74835 4.85773 4.14285 4.85773H12.7143C13.1088 4.85773 13.4286 4.53794 13.4286 4.14345C13.4286 3.74895 13.1088 3.4292 12.7143 3.4292H4.14285C2.95939 3.4292 2 4.38857 2 5.57202V19.8575C2 21.041 2.95939 22.0003 4.14285 22.0003H17C18.1834 22.0003 19.1428 21.041 19.1428 19.8575V12.7147C19.1428 12.3203 18.823 12.0005 18.4285 12.0005Z" />
      <path d="M21.1991 2.80098C20.6863 2.28804 19.9907 1.99993 19.2653 2.00001C18.5395 1.99792 17.8432 2.28653 17.3318 2.80144L7.92315 12.2091C7.84509 12.2877 7.7862 12.3833 7.75101 12.4884L6.32245 16.774C6.19777 17.1483 6.40013 17.5528 6.77442 17.6774C6.84703 17.7016 6.92308 17.7139 6.99958 17.714C7.07626 17.7139 7.15247 17.7016 7.22529 17.6776L11.511 16.2491C11.6163 16.2139 11.7119 16.1547 11.7903 16.0762L21.1989 6.66781C22.2667 5.60008 22.2668 3.86883 21.1991 2.80098Z" />
    </SvgIcon>
  );
}

export default EditMark;
