import _ from "lodash";
import { CelebrationResource } from ".";
import Base from "./base";
import EpisodeResource, { headsUpLabelMap } from "./episode";
import StruggleResource from "./struggle";
import StudentTipResource from "./student_tip";

function prepareHeadsUps(raw_heads_ups) {
  const headsUps = {};
  raw_heads_ups.forEach((obj) => {
    Object.entries(obj).forEach(([key, content]) => {
      if (key === "date") return;

      if (!headsUps[key]) headsUps[key] = [];
      headsUps[key].push({ date: obj.date, content });
    });
  });

  return Object.entries(headsUpLabelMap).map(([key, label]) => ({
    label,
    entries: _.orderBy(headsUps[key] || [], "date", "desc"),
  }));
}

export default class StudentResource extends Base {
  static __requiredFields = {
    createOne: [
      { key: "first_name", label: "First Name" },
      { key: "last_name", label: "Last Name" },
      { key: "nickname", label: "Nickname" },
    ],
    update: [],
  };

  get __properties() {
    return [
      "id",
      "first_name",
      "last_name",
      "image",
      "nickname",
      "team",
      "number_of_episodes", // New episodes of the past week
      "number_of_struggles", // Unresolved struggles of the past week
      "number_of_tips", // New tips of the past week
      "last_celebration",
      "studentTipIds",
      "episodePages",
      "strugglePages",
      "celebrationPages",
      "headsUps",
      "monitoring",
      "created_at",
    ];
  }

  get __resource() {
    return "STUDENT";
  }

  get __endpoint() {
    return "/v1/students";
  }

  async loadTips(params) {
    const sTips = await StudentTipResource.list(this.id, params);

    this.studentTipIds = sTips.map((st) => st.id);
    this.updateStateObject();

    return sTips;
  }

  async loadEpisodes(params) {
    const episodes = await EpisodeResource.list({
      student: this.id,
      ...params,
    });

    if (params.page > 1) {
      this.episodePages[params.page] = episodes.map((ep) => ep.id);
    } else {
      this.episodePages = { 1: episodes.map((ep) => ep.id) };
    }

    this.updateStateObject();

    return episodes;
  }

  async loadStruggles(params) {
    const struggles = await StruggleResource.list({
      student: this.id,
      ...params,
    });

    if (params.page > 1) {
      this.strugglePages[params.page] = struggles.map((ep) => ep.id);
    } else {
      this.strugglePages = { 1: struggles.map((ep) => ep.id) };
    }

    this.updateStateObject();

    return struggles;
  }

  async loadCelebrations(params) {
    const celebrations = await CelebrationResource.list({
      student: this.id,
      ...params,
    });

    if (params.page > 1) {
      this.celebrationPages[params.page] = celebrations.map((ep) => ep.id);
    } else {
      this.celebrationPages = { 1: celebrations.map((ep) => ep.id) };
    }

    this.updateStateObject();

    return celebrations;
  }

  static toObject(data) {
    if (data.last_month_heads_up) {
      data.headsUps = prepareHeadsUps(data.last_month_heads_up);
    }

    data.last_celebration = data.last_celebration?.headline;

    const student = new StudentResource();
    student.assign(data);
    return student;
  }

  get episodes() {
    if (!this.episodePages) return null;

    const ids = _.flatten(Object.values(this.episodePages));
    return EpisodeResource.pickMany(ids);
  }

  get struggles() {
    if (!this.strugglePages) return null;

    const ids = _.flatten(Object.values(this.strugglePages));
    return StruggleResource.pickMany(ids);
  }

  get celebrations() {
    if (!this.celebrationPages) return null;

    const ids = _.flatten(Object.values(this.celebrationPages));
    return CelebrationResource.pickMany(ids);
  }

  get studentTips() {
    return StudentTipResource.pickMany(this.studentTipIds);
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`.trim() || "-";
  }
}
