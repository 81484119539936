import { Box } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { ContentList, Emptyness } from "../../design-library";
import { StruggleResource } from "../../resources";
import {
  StruggleCard,
  StruggleDisplayModal,
} from "../../shared-components/Struggle";
import { formatDate } from "../../shared-components/utils";
import WidgetContainer from "./WidgetContainer";

function StruggleCardComponent({ entry, ...props }) {
  return (
    <StruggleCard
      subtext={
        <>
          <b>{entry.practitioner} </b>
          {entry.studentInitial && (
            <>
              {"with "}
              <b>{entry.studentInitial} </b>
            </>
          )}
          &bull; {formatDate(entry.created_at)}
        </>
      }
      struggle={entry}
      {...props}
    />
  );
}

function NewStrugglesWidget({ label, idx, onRearrange }) {
  const {
    appState: { recentStruggleIds },
  } = useContext(AppContext);

  const [modalStruggleId, setModalStruggleId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const struggles = StruggleResource.pickMany(recentStruggleIds);

  useEffect(() => {
    setIsLoading(true);
    StruggleResource.getRecent().finally(() => setIsLoading(false));
  }, []);

  return (
    <WidgetContainer
      label={label}
      isLoading={isLoading}
      idx={idx}
      onRearrange={onRearrange}
    >
      {struggles?.length ? (
        <Box width="100%" paddingTop="8px">
          <ContentList
            entries={struggles}
            CardComponent={StruggleCardComponent}
            onEntryClick={(entry) => setModalStruggleId(entry.id)}
          />
          <StruggleDisplayModal
            struggleId={modalStruggleId}
            onClose={() => setModalStruggleId()}
          />
        </Box>
      ) : (
        struggles && <Emptyness size={10} />
      )}
    </WidgetContainer>
  );
}

export default NewStrugglesWidget;
