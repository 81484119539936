import { Box } from "@material-ui/core";
import { Text } from "../../design-library";

function TipSummary({ tip }) {
  return (
    <Box>
      <Text content="Tip Summary" type="label" />
      <Text content={tip.tip_summary} type="paragraph" />
    </Box>
  );
}

export default TipSummary;
