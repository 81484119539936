import { Link } from "react-router-dom";
import { Text } from "../../../design-library";

function CreateEpisodeEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          created a new episode &nbsp;
          <b
            className="clickable"
            onClick={() => onClick({ episode: meta.episode })}
          >
            #{meta.episode}
          </b>
          &nbsp; for student{" "}
          <Link
            to={`/students/${meta.student}/episodes`}
            style={{ textDecoration: "none" }}
            className="clickable"
          >
            {meta.student_nickname || `#${meta.student}`}
          </Link>
          .
        </>
      }
    />
  );
}

export default CreateEpisodeEntry;
