import Base from "./base";

export default class ActivityResource extends Base {
  static types = {
    RATE_TIP: "rate_tip",
    READ_TIP: "read_tip",
    TRY_TIP: "try_tip",
    CREATE_TIP: "create_tip",
    EDIT_TIP: "update_tip",
    CREATE_EXAMPLE: "create_example",
    EDIT_EXAMPLE: "update_example",
    CREATE_EPISODE: "create_episode",
    EDIT_EPISODE: "update_episode",
    CREATE_STRUGGLE: "create_struggle",
    EDIT_STRUGGLE: "update_struggle",
    CREATE_QUESTION: "create_question",
    REPLY_QUESTION: "reply_question",
    SUGGEST_TIP: "suggest_tip",
    TOGGLE_TIP_EDIT_MARK: "set_tip_edit_mark",
    ATTACH_TIP_WITH_EXAMPLE: "attach_tip_with_example",
    DETACH_TIP_FROM_EXAMPLE: "detach_tip_from_example",
    ATTACH_RELATED_TIPS_TO_TIP: "attach_related_tips_with_tip",
    TOGGLE_EXAMPLE_BOOKMARK: "toggle_example_bookmark",
  };

  static __requiredFields = {
    createOne: [
      { key: "type", label: "Type" },
      { key: "meta", label: "Meta" },
    ],
    update: [],
  };

  get __resource() {
    return "ACTIVITY";
  }

  get __endpoint() {
    return "/v1/activities";
  }

  get __properties() {
    return ["id", "user", "type", "meta", "created_at"];
  }

  static async __new(body) {
    try {
      await this.createOne(body, true);
    } catch (err) {
      console.error(err);
    }
  }

  static activities = {
    READ_TIP: async ({ tip, minutes }) =>
      await this.__new({
        type: this.types.READ_TIP,
        meta: { tip, minutes },
      }),
    RATE_TIP: async ({ tip, clarity, relevance, uniqueness }) =>
      await this.__new({
        type: this.types.RATE_TIP,
        meta: { tip, clarity, relevance, uniqueness },
      }),
    TRY_TIP: async ({ tip, helpful, retry_later }) =>
      await this.__new({
        type: this.types.TRY_TIP,
        meta: { tip, helpful, retry_later },
      }),
    CREATE_TIP: async ({ tip }) =>
      await this.__new({ type: this.types.CREATE_TIP, meta: { tip } }),
    EDIT_TIP: async ({ tip }) =>
      await this.__new({ type: this.types.EDIT_TIP, meta: { tip } }),
    CREATE_EXAMPLE: async ({ example, tip, episode }) =>
      await this.__new({
        type: this.types.CREATE_EXAMPLE,
        meta: { example, tip, episode },
      }),
    EDIT_EXAMPLE: async ({ example }) =>
      await this.__new({ type: this.types.EDIT_EXAMPLE, meta: { example } }),
    CREATE_EPISODE: async ({ episode, student, student_nickname }) =>
      await this.__new({
        type: this.types.CREATE_EPISODE,
        meta: { episode, student, student_nickname },
      }),
    EDIT_EPISODE: async ({ episode }) =>
      await this.__new({ type: this.types.EDIT_EPISODE, meta: { episode } }),
    CREATE_STRUGGLE: async ({ struggle }) =>
      await this.__new({
        type: this.types.CREATE_STRUGGLE,
        meta: { struggle },
      }),
    EDIT_STRUGGLE: async ({ struggle }) =>
      await this.__new({ type: this.types.EDIT_STRUGGLE, meta: { struggle } }),
    SUGGEST_TIP: async ({ tip, students }) =>
      await this.__new({
        type: this.types.SUGGEST_TIP,
        meta: { tip, students },
      }),
    CREATE_QUESTION: async ({ question, tip }) =>
      await this.__new({
        type: this.types.CREATE_QUESTION,
        meta: { question, tip },
      }),
    REPLY_QUESTION: async ({ question, reply, tip, question_by }) =>
      await this.__new({
        type: this.types.REPLY_QUESTION,
        meta: { question, reply, tip, question_by },
      }),
    TOGGLE_TIP_EDIT_MARK: async ({ tip, is_marked }) =>
      await this.__new({
        type: this.types.TOGGLE_TIP_EDIT_MARK,
        meta: { tip, is_marked },
      }),
    ATTACH_TIP_WITH_EXAMPLE: async ({ tip, example }) =>
      await this.__new({
        type: this.types.ATTACH_TIP_WITH_EXAMPLE,
        meta: { tip, example },
      }),
    DETACH_TIP_FROM_EXAMPLE: async ({ tip, example }) =>
      await this.__new({
        type: this.types.DETACH_TIP_FROM_EXAMPLE,
        meta: { tip, example },
      }),
    ATTACH_RELATED_TIPS_TO_TIP: async ({ tip, related_tips }) =>
      await this.__new({
        type: this.types.ATTACH_RELATED_TIPS_TO_TIP,
        meta: { tip, related_tips },
      }),
    TOGGLE_EXAMPLE_BOOKMARK: async ({ example, is_bookmarked }) =>
      await this.__new({
        type: this.types.TOGGLE_EXAMPLE_BOOKMARK,
        meta: { example, is_bookmarked },
      }),
  };

  static toObject(data) {
    if (typeof data.meta.tip === "object") data.meta.tip = undefined;
    return super.toObject(data);
  }
}
