import PropTypes from "prop-types";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Emptyness } from "..";
import Spinner from "../Spinner/Spinner";
import DataRow from "./DataRow";
import HeaderRow from "./HeaderRow";
import _ from "lodash";

const useStyle = makeStyles(() => ({
  headerRow: { height: "100%", position: "relative", background: "white" },
  loader: {
    verticalAlign: "middle",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
}));

function MyTable({
  columns,
  data = null,
  onRowClick = _.noop,
  HeaderComponent = HeaderRow,
  RowComponent = DataRow,
}) {
  const classes = useStyle();

  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.headerRow}>
          <HeaderComponent columns={columns} />
        </TableHead>
        <TableBody>
          {data?.length ? (
            data.map((row) => (
              <RowComponent
                key={row.id}
                columns={columns}
                row={row}
                onClick={(e) => onRowClick(row, e)}
              />
            ))
          ) : (
            <TableRow style={{ height: "100%" }}>
              <TableCell colSpan={10} className={classes.loader}>
                {data ? (
                  <Emptyness />
                ) : (
                  <Spinner style={{ height: "100%" }} size={30} />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MyTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  HeaderComponent: PropTypes.func,
  RowComponent: PropTypes.func,
};

export default MyTable;
