import PropTypes from "prop-types";
import { Calendar } from "react-date-range";
import { useState } from "react";
import { Input } from "..";
import { formatDate } from "../../shared-components/utils";
import { Dialog } from "@material-ui/core";
import _ from "lodash";

function DatePicker({ value, noBorder = false, onChange = _.noop, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Input
        placeholder="Select a date"
        value={value ? formatDate(value) : ""}
        noBorder={noBorder}
        readOnly
        onClick={() => setOpen(true)}
        icon="calendar"
        {...props}
      />
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Calendar
          date={value ? new Date(value) : undefined}
          onChange={(v) => {
            onChange(v);
            setOpen(false);
          }}
          color="#00B5B2"
        />
      </Dialog>
    </>
  );
}

DatePicker.propTypes = {
  noBorder: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, Date]),
};

export default DatePicker;
