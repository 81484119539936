/* eslint-disable react-hooks/exhaustive-deps */
import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { Text, Tooltip } from "../../design-library";
import { formatDate } from "../../shared-components/utils";

import WidgetContainer from "./WidgetContainer";

function formatContributions(contributions) {
  const formatted = {};
  for (const { date, tips, examples } of contributions) {
    const dt = formatDate(new Date(date));
    formatted[dt] = { tips, examples };
  }

  return formatted;
}

const useStyles = makeStyles(() => ({
  root: {},
  heatEntryColumn: {
    width: "20px",
    height: "140px",
  },
  heatEntry: {
    height: "19px",
    width: "19px",
    margin: "1px",
    background: "#F5F5F5",
    cursor: "pointer",
  },
}));

const heats = {
  heat0: { threshold: "No Contribution", color: "#F5F5F5" },
  heat1: { threshold: "1 to 5", color: "#BCFFFE" },
  heat2: { threshold: "6 to 10", color: "#7DEAE8" },
  heat3: { threshold: "11 to 15", color: "#31C2C0" },
  heat4: { threshold: "15 to 20", color: "#1AA8A6" },
  heat5: { threshold: "21+", color: "#007977" },
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function Heatmap({ contributions, dataKey }) {
  const classes = useStyles();

  // Last day of the running week
  const lastDay = new Date();
  if (lastDay.getDay()) {
    lastDay.setDate(lastDay.getDate() + (7 - lastDay.getDay()));
  }

  const getContent = (r, c) => {
    const date = new Date(lastDay);
    date.setDate(date.getDate() - (70 - (r + 7 * c + 1)));
    const formattedDate = formatDate(date);

    let contrib = contributions && contributions[formattedDate];
    contrib = contrib && contrib[dataKey];

    const content = (
      <Box>
        {formattedDate}
        <br />
        {contrib > 0 ? <>Contribution: {contrib}</> : "No Contribution"}
      </Box>
    );

    let heat;
    if (contrib > 20) heat = "heat5";
    else if (contrib > 15) heat = "heat4";
    else if (contrib > 10) heat = "heat3";
    else if (contrib > 5) heat = "heat2";
    else if (contrib > 0) heat = "heat1";
    else heat = "heat0";

    return { content, color: heats[heat].color };
  };

  return (
    <Box display="flex">
      {_.range(10).map((c) => (
        <Box key={c} className={classes.heatEntryColumn}>
          {_.range(7).map((r) => {
            const { content, color } = getContent(r, c);

            return (
              <Tooltip key={r} content={content}>
                <Box
                  className={classes.heatEntry}
                  style={{ background: color }}
                />
              </Tooltip>
            );
          })}
        </Box>
      ))}
    </Box>
  );
}

function ContributionWidget({ label, idx, onRearrange }) {
  const {
    appState: { user },
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    user.loadContributions().finally(() => setIsLoading(false));
  }, []);

  const contributions = user.contributions
    ? formatContributions(user.contributions)
    : null;

  return (
    <WidgetContainer
      label={label}
      isLoading={isLoading}
      idx={idx}
      onRearrange={onRearrange}
    >
      {contributions && (
        <>
          <Box display="flex" justifyContent="space-between" paddingTop="8px">
            <Box height="140px">
              <Box height="28px"></Box>
              {days.map((d) => (
                <Box key={d} height="19px" style={{ margin: "1px" }}>
                  <Text style={{ lineHeight: "19px" }} content={d} />
                </Box>
              ))}
            </Box>
            <Box>
              <Text type="label" content="Tips" style={{ height: "28px" }} />
              <Heatmap contributions={contributions} dataKey="tips" />
            </Box>
            <Box>
              <Text
                type="label"
                content="Examples"
                style={{ height: "28px" }}
              />
              <Heatmap contributions={contributions} dataKey="examples" />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Text
              content="Less"
              style={{ lineHeight: "19px", marginRight: "12px" }}
            />
            <Box display="flex" height="19px">
              {Object.entries(heats).map(([heat, { color, threshold }]) => (
                <Tooltip key={heat} content={threshold}>
                  <Box
                    width="19px"
                    height="19px"
                    style={{
                      marginRight: "1px",
                      background: color,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
            <Text
              content="More"
              style={{ lineHeight: "19px", marginLeft: "11px" }}
            />
          </Box>
        </>
      )}
    </WidgetContainer>
  );
}

export default ContributionWidget;
