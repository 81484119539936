import PropTypes from "prop-types";
import { Box, Dialog } from "@material-ui/core";
import { useState } from "react";
import { Input } from "..";
import { formatDate } from "../../shared-components/utils";
import { DateRangePicker } from "react-date-range";
import Button from "../Button/Button";
import _ from "lodash";

function MyDateRangePicker({
  range,
  noBorder = false,
  style = {},
  onChange = _.noop,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Input
        style={{
          width: "280px",
          ...style,
        }}
        placeholder="Select a date range"
        value={
          range
            ? `${formatDate(range.startDate)} - ${formatDate(range.endDate)}`
            : ""
        }
        noBorder={noBorder}
        readOnly
        onClick={() => setOpen(true)}
        icon="dateRange"
      />
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Box height="384px">
          <DateRangePicker
            ranges={[
              {
                startDate: new Date(),
                endDate: new Date(),
                ...range,
                key: "selection",
              },
            ]}
            rangeColors={["#00B5B2"]}
            onChange={(range) => onChange(range.selection)}
          />
        </Box>
        <Box padding="12px" textAlign="right">
          <Button label="Close" primary onClick={() => setOpen(false)} />
        </Box>
      </Dialog>
    </>
  );
}

MyDateRangePicker.propTypes = {
  range: PropTypes.object,
  noBorder: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MyDateRangePicker;
