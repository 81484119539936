import { Box } from "@material-ui/core";
import { Text } from "../../design-library";
import { formatDate } from "../utils";

function PractitionerByline({ practitioner, style }) {
  return (
    <Box style={style}>
      <Text
        type="title"
        content={
          <>
            {practitioner.fullName}{" "}
            <span style={{ color: "#C5C5C5" }}>({practitioner.username})</span>
          </>
        }
      />
      <Text
        type="subtitle"
        content={`Joined on ${formatDate(practitioner.date_joined)}`}
      />
    </Box>
  );
}

export default PractitionerByline;
