/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import { Fragment } from "react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { StudentContext } from "../../context";
import {
  Button,
  DateRangePicker,
  SearchBar,
  Sidebar,
} from "../../design-library";
import { StudentTipResource, UserResource } from "../../resources";
import { StudentHeadsup } from "../../shared-components/Student";
import {
  standardTipFilters,
  TipCard,
  TipGrid,
} from "../../shared-components/Tip";
import { relativeTimeFormat, useURLState } from "../../shared-components/utils";

function getLevelDescriptors(headsUps, classes) {
  const makeEntry = (label) => {
    const entries = headsUps.find((hu) => hu.label === label)?.entries || [];
    return (
      <span>
        <span className={classes.descriptorTitle}>{label}</span>
        <br />
        {!entries.length ? (
          <span className={classes.emptyBody}>N/A</span>
        ) : (
          entries.map((e) => (
            <Fragment key={Math.random()}>
              &bull;{" "}
              <span className={classes.descriptorTime}>
                {relativeTimeFormat(e.date)}
              </span>{" "}
              {e.content}
              <br />
            </Fragment>
          ))
        )}
      </span>
    );
  };

  return {
    1: makeEntry("New behaviors"),
    2: makeEntry("Preferred objects"),
    3: (
      <Fragment>
        {makeEntry("New connections")}
        <br /> {makeEntry("Peer conflicts")}
      </Fragment>
    ),
    4: makeEntry("Play themes"),
    5: (
      <Fragment>
        {makeEntry("Routine changes")}
        <br /> {makeEntry("Upcoming events")}
      </Fragment>
    ),
  };
}

const useStyles = makeStyles(() => ({
  newBadge: {
    background: ({ tipColor }) => tipColor,
    color: "white",
    padding: "0 2px",
    fontSize: "90%",
  },
  descriptorTitle: { fontWeight: "600" },
  descriptorTime: { color: "#C5C5C5", fontSize: "90%" },
  emptyBody: { fontSize: "80%" },
}));

function getExtraSubtext(tip) {
  if (!tip.try_count) {
    return <span>Never tried</span>;
  } else {
    return (
      <span>
        Tried {tip.try_count} &bull; Useful {tip.helpful_count}
      </span>
    );
  }
}

function TipCardComponent({ entry: tip, ...props }) {
  const classes = useStyles({ tipColor: tip.color });

  const { stMap } = useContext(StudentContext);
  const sTip = stMap[tip.id];

  let subtext;
  if (!sTip) {
    subtext = <></>;
  } else if (sTip.is_queued) {
    subtext = <span>Queued</span>;
  } else if (!sTip.is_read) {
    subtext = <span className={classes.newBadge}>ATTN</span>;
  } else if (UserResource.current.isEducatorPlus) {
    subtext = !sTip.is_rated ? <span>Not rated</span> : getExtraSubtext(tip);
  }

  return (
    <TipCard
      entry={tip}
      {...props}
      printTagged={sTip?.printTagged}
      togglePrintTag={() => sTip?.togglePrintTag()}
      truncate={1}
      subtext={subtext}
    />
  );
}

const defaultRange = {
  start_date: moment().subtract(6, "weeks").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};

function formatParams(params, page) {
  return {
    search_text: params.search_text,
    title: params.title,
    start_date: params.start_date || defaultRange.start_date,
    end_date: params.end_date || defaultRange.end_date,
    page: page || 1,
    page_size: 500,
  };
}

export default function TipsTab({ student }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState();
  const [params, setParams] = useURLState({}, [
    "search_text",
    "title",
    "start_date",
    "end_date",
  ]);

  const loadTips = () => {
    setIsLoading(true);

    const fp = formatParams(params);
    if (!UserResource.current.isAdmin) fp.is_queued = false;

    student.loadTips(fp).then(() => setIsLoading(false));
  };

  useEffect(loadTips, [params, student.id]);

  const classes = useStyles();
  const levelDescriptors = getLevelDescriptors(student.headsUps || [], classes);

  const stMap = {};
  const tips = student.studentTips?.map((st) => {
    stMap[st.tip.id] = st;
    return st.tip;
  });

  const printAll = () => {
    const ids = tips
      ?.filter((tip) => !tip.marked_for_editing)
      .map((tip) => tip.id);
    StudentTipResource.tagForPrinting(student.id, ids);
    history.push(`/students/${student.id}/print-n-progress`);
  };

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box height="60px">
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              initialValue={params.search_text || ""}
              onSearch={(q) => setParams({ ...params, search_text: q })}
              placeholder="Search a tip"
            />
            <DateRangePicker
              style={{ marginLeft: "12px" }}
              noBorder
              range={{
                startDate: new Date(
                  params.start_date || defaultRange.start_date
                ),
                endDate: new Date(params.end_date || defaultRange.end_date),
              }}
              onChange={({ startDate, endDate }) =>
                setParams({
                  ...params,
                  start_date: moment(startDate).format("YYYY-MM-DD"),
                  end_date: moment(endDate).format("YYYY-MM-DD"),
                })
              }
            />
            {isLoading && (
              <Box
                display="inline-flex"
                height="40px"
                flexDirection="column"
                justifyContent="center"
                marginLeft="12px"
              >
                <CircularProgress size={20} />
              </Box>
            )}
          </Grid>
          <Grid item>
            <Button
              label="Print All"
              onClick={printAll}
              style={{ marginRight: "12px" }}
            />
            <Button
              primary
              label="Heads Ups"
              onClick={() => setShowSidebar((v) => !v)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        height="calc(100% - 60px)"
        width={showSidebar ? "calc(100% - 400px)" : "100%"}
        style={{ transition: "width 0.5s ease" }}
        overflow="auto"
        position="relative"
      >
        <StudentContext.Provider value={{ stMap, studentId: student.id }}>
          <TipGrid
            tips={tips}
            filters={standardTipFilters}
            CardComponent={TipCardComponent}
            onTipOpen={(tip) => stMap[tip.id]?.markAsRead()}
            descriptor={levelDescriptors}
          />
        </StudentContext.Provider>
      </Box>
      <Sidebar
        title="Heads Ups"
        open={showSidebar}
        onClose={() => setShowSidebar()}
      >
        <StudentHeadsup student={student} />
      </Sidebar>
    </Box>
  );
}
