import { Box } from "@material-ui/core";
import { useEffect } from "react";
import Illustration from "./Illustration";

export default function Page() {
  useEffect(() => {
    document.title = "Learning Seeds | 404";
  }, []);

  const dim = "280px";
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box textAlign="center" sx={{ opacity: 0.4, userSelect: "none" }}>
        <Illustration style={{ width: dim, height: dim }} />
      </Box>
    </Box>
  );
}
