import { Box, Divider, makeStyles } from "@material-ui/core";

import { Avatar, Text } from "../../design-library";
import { relativeTimeFormat } from "../utils";

const useStyles = makeStyles(() => ({
  subtext: {
    color: "#93999A",
    fontSize: "12px",
    marginBottom: "2px",
  },
  root: {
    borderLeft: "3px solid #555",
    backgroundColor: "white",
    cursor: "pointer",
    fontSize: "14px",
    marginTop: "3px",
    width: "350px",
    "&:hover": {
      background: "#efefef",
    },
  },
  title: { fontSize: "14px", marginLeft: "8px", color: "#555" },
  entry: { fontSize: "13px" },
}));

function StudentStamp({ subtext, entry, student, assigned, onClick }) {
  student = entry || student;

  const classes = useStyles();

  return (
    <Box padding={1} className={classes.root} onClick={onClick}>
      {subtext && (
        <span className={["truncate-1", classes.subtext].join(" ")}>
          {subtext}
        </span>
      )}
      <Box display="flex" alignItems="center">
        <Avatar size={4} name={student.nickname} image={student.image} />
        <Box>
          <Text
            type="label"
            truncate
            className={classes.title}
            content={student.nickname}
          />
          <Text
            truncate
            className={classes.title}
            style={{ fontSize: "10px" }}
            content={
              <>
                Joined {relativeTimeFormat(student.created_at)}{" "}
                {assigned && (
                  <>
                    &bull; <span style={{ color: "red" }}>Assigned to you</span>
                  </>
                )}
              </>
            }
          />
        </Box>
      </Box>
      <Divider style={{ margin: "8px 0" }} />
      <Box>
        <Text
          type="label"
          className={classes.entry}
          content="Weekly summary:"
        />
        <Text
          className={classes.entry}
          content={
            <>
              &bull; New tips: <b>{student.number_of_tips || "-"}</b>
            </>
          }
        />
        <Text
          className={classes.entry}
          content={
            <>
              &bull; New episodes: <b>{student.number_of_episodes || "-"}</b>
            </>
          }
        />
        <Text
          className={classes.entry}
          content={
            <>
              &bull; Unresolved struggles:{" "}
              <b>{student.number_of_struggles || "-"}</b>
            </>
          }
        />
        <Text
          className={classes.entry}
          truncate
          content={
            <>
              &bull; Latest celebration:{" "}
              <b>{student.last_celebration || "-"}</b>
            </>
          }
        />
      </Box>
    </Box>
  );
}

export default StudentStamp;
