import { Table, Tooltip } from "../../design-library";
import { formatDate } from "../utils";
import { useHistory, useLocation } from "react-router-dom";
import { EpisodeDisplayModal } from ".";

function EpisodeTable({ episodes }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const history = useHistory();
  const setModalEpisodeId = (id) => {
    if (!id) query.delete("id");
    else query.set("id", id);

    history.push({ search: query.toString() });
  };

  const modalEpisodeId = query.get("id");

  return (
    <>
      <Table
        columns={[
          { colId: "title", label: "Title" },
          {
            colId: "practitioner",
            label: "Practitioner",
            formatter: (prac) => prac?.full_name,
          },
          {
            colId: "contributors",
            label: "Writers",
            formatter: (writers) => {
              if (!writers?.length) return "-";

              const first = writers[0].full_name;
              if (writers.length === 1) return first;

              return (
                <Tooltip
                  content={writers.map((writer) => writer.full_name).join(", ")}
                >
                  <span>
                    {first}
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#999",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      + {writers.length - 1} more
                    </span>
                  </span>
                </Tooltip>
              );
            },
          },
          { colId: "date", label: "Date", formatter: formatDate },
        ]}
        data={episodes}
        onRowClick={(rowData) => setModalEpisodeId(rowData.id)}
      />
      <EpisodeDisplayModal
        episodeId={modalEpisodeId}
        onClose={() => setModalEpisodeId(null)}
      />
    </>
  );
}

export default EpisodeTable;
