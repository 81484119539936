import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Button, Divider, Input, Text } from "../../design-library";
import { UserResource } from "../../resources";
import { getFormInputFn } from "../../shared-components/utils";

const useStyles = makeStyles(() => ({
  root: {
    width: "440px",
    background: "white",
    boxShadow: "0 0 16px 2px rgba(0, 0, 0, 0.2)",
    padding: "8px 0 32px 0",
    zIndex: 10,
  },
}));

function RequestPasswordResetBox({ toggleBox }) {
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onInput = getFormInputFn(setForm);
  const onSubmit = () => {
    setIsLoading(true);
    UserResource.requestPasswordReset(form)
      .then(toggleBox)
      .finally(() => setIsLoading(false));
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Text
        content="Reset Password"
        style={{ color: "#00B5B2", fontSize: "150%", textAlign: "center" }}
      />
      <Divider />
      <Box padding="0 16px">
        <Text type="label" content="Email address" />
        <Input
          fullWidth
          placeholder="Enter your registered email address"
          onChange={(e) => onInput("email", e.target.value)}
        />
        <Box display="flex" justifyContent="right" marginTop="10px">
          <Button
            label="Back"
            disabled={isLoading}
            onClick={toggleBox}
            style={{ marginRight: "10px" }}
          />
          <Button
            label="Reset"
            primary
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default RequestPasswordResetBox;
