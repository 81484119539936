/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Box, CircularProgress, Divider, Grid } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  Modal,
  ContentCard,
  ContentList,
  FilterPanel,
  Text,
  Button,
  Emptyness,
  SearchBar,
  Spinner,
} from "..";
import { Waypoint } from "react-waypoint";

function Anchor({ onClick, style }) {
  return <Button primary label="Select" onClick={onClick} style={style} />;
}

function ContentSelector({
  contentName = "Content",
  onSearch = _.noop,
  entries,
  filters,
  onSelect = _.noop,
  onAdd,
  DetailComponent,
  isLoading = false,
  multiSelect = false,
  CardComponent = ContentCard,
  AnchorComponent = Anchor,
  customAction,
  hasMorePages = false,
  style,
}) {
  const [params, setParams] = useState({});
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [siderEntry, setSiderEntry] = useState();
  const [selectedEntries, setSelectedEntries] = useState([]);

  useEffect(() => {
    if (open) {
      onSearch(query);
      setSelectedEntries([]);
      setSiderEntry(null);
    }
  }, [open]);

  if (filters) {
    entries = entries?.filter((entry) => {
      for (const { dataKey } of filters) {
        if (!params[dataKey]?.includes(entry[dataKey])) return false;
      }
      return true;
    });
  }

  const isSelected = (entry) => {
    return selectedEntries.map((en) => en.id).includes(entry.id);
  };

  const handleToggle = (entry) => {
    if (isSelected(entry)) {
      setSelectedEntries(selectedEntries.filter((en) => en.id !== entry.id));
    } else if (multiSelect) {
      setSelectedEntries([...selectedEntries, entry]);
    } else {
      setSelectedEntries([entry]);
    }
  };

  const handleEntryClick = (entry) => {
    if (DetailComponent) setSiderEntry(entry);
    else handleToggle(entry);
  };

  const handleConfirm = () => {
    const selection = multiSelect ? selectedEntries : selectedEntries[0];
    const postSelect = () => {
      setOpen(false);
      setSelectedEntries([]);
    };

    const promise = onSelect(selection);
    if (promise instanceof Promise) return promise.then(postSelect);
    else {
      postSelect();
      return promise;
    }
  };

  const loadNextPage = () => onSearch(query, false);

  return (
    <>
      <AnchorComponent onClick={() => setOpen(true)} style={style} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        breadcrumbs={[`Select ${contentName}`]}
        footer={
          <Box textAlign="right">
            {onAdd && (
              <Button label="New" style={{ float: "left" }} onClick={onAdd} />
            )}
            {customAction}
            <Button
              label="Back"
              onClick={() =>
                siderEntry ? setSiderEntry(null) : setOpen(false)
              }
              style={{ marginRight: "12px" }}
            />
            {siderEntry && (
              <Button
                label={isSelected(siderEntry) ? "Unpick" : "Pick"}
                onClick={() => handleToggle(siderEntry)}
                style={{ marginRight: "12px" }}
              />
            )}
            <Button
              label="Confirm"
              primary
              disabled={!selectedEntries.length}
              statefulLoader
              onClick={handleConfirm}
            />
          </Box>
        }
        sider={
          DetailComponent &&
          siderEntry && <DetailComponent entry={siderEntry} />
        }
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              initialValue={query}
              onSearch={(q) => {
                setQuery(q);
                onSearch(q);
              }}
              placeholder="Search a tip"
              noBorder={false}
            />
            {isLoading && (
              <Box
                display="inline-flex"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                marginLeft="12px"
              >
                <CircularProgress size={20} />
              </Box>
            )}
          </Grid>
          <Grid item>
            {filters && (
              <FilterPanel
                header={`Filter ${contentName}`}
                filters={filters}
                onChange={(pp) => setParams(pp)}
                style={{ marginRight: "-8px" }}
              />
            )}
          </Grid>
        </Grid>
        {selectedEntries.length > 0 && (
          <>
            <Divider style={{ margin: "20px 0 12px 0" }} />
            <Text
              type="label"
              content={`Selected ${contentName}${multiSelect ? "s" : ""}`}
              style={{ marginBottom: "12px" }}
            />
            <ContentList
              folds={2}
              entries={selectedEntries}
              highlightedIds={siderEntry ? [siderEntry.id] : []}
              onEntryClick={handleEntryClick}
              onEntryCross={handleToggle}
              CardComponent={CardComponent}
            />
          </>
        )}
        <Divider style={{ margin: "20px 0" }} />
        {entries?.length === 0 ? (
          <Emptyness style={{ height: "60%" }} />
        ) : (
          <ContentList
            folds={2}
            entries={entries}
            highlightedIds={siderEntry ? [siderEntry.id] : []}
            onEntryClick={handleEntryClick}
            CardComponent={CardComponent}
          />
        )}
        {!isLoading && hasMorePages && (
          <>
            .
            <Waypoint onEnter={loadNextPage} />
          </>
        )}
        {entries?.length > 0 && hasMorePages && isLoading && (
          <Spinner style={{ height: "40px" }} label="Loading more..." />
        )}
      </Modal>
    </>
  );
}

ContentSelector.propTypes = {
  contentName: PropTypes.string,
  onSearch: PropTypes.func,
  entries: PropTypes.array,
  filters: PropTypes.array,
  onSelect: PropTypes.func,
  onAdd: PropTypes.func,
  DetailComponent: PropTypes.func,
  isLoading: PropTypes.bool,
  multiSelect: PropTypes.bool,
  CardComponent: PropTypes.func,
  AnchorComponent: PropTypes.func,
  hasMorePages: PropTypes.bool,
};

export default ContentSelector;
