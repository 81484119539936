import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Divider, Input, Text } from "../../design-library";
import { UserResource } from "../../resources";
import { getFormInputFn } from "../../shared-components/utils";

const useStyles = makeStyles(() => ({
  root: {
    width: "440px",
    background: "white",
    boxShadow: "0 0 16px 2px rgba(0, 0, 0, 0.2)",
    padding: "8px 0 32px 0",
    zIndex: 10,
  },
}));

function ConfirmPasswordResetBox({ token }) {
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const onInput = getFormInputFn(setForm);
  const onSubmit = () => {
    setIsLoading(true);
    UserResource.confirmPasswordReset({ ...form, token })
      .then(() => {
        query.delete("reset-request-token");
        history.push({ search: query.toString() });
      })
      .finally(() => setIsLoading(false));
  };

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Text
        content="Set New Password"
        style={{ color: "#00B5B2", fontSize: "150%", textAlign: "center" }}
      />
      <Divider />
      <Box padding="0 16px">
        <Text type="label" content="Password" />
        <Input
          fullWidth
          placeholder="Enter a unique password"
          type="password"
          onChange={(e) => onInput("password", e.target.value)}
        />
        <Text
          type="label"
          content="Re-type Password"
          style={{ marginTop: "10px" }}
        />
        <Input
          fullWidth
          placeholder="Enter the password again"
          type="password"
          onChange={(e) => onInput("password2", e.target.value)}
        />
        <Box display="flex" justifyContent="right" marginTop="10px">
          <Button
            label="Submit"
            primary
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ConfirmPasswordResetBox;
