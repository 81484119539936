import { Box, makeStyles } from "@material-ui/core";
import { Icon } from "../../design-library";

const useStyles = makeStyles(() => ({
  subtext: {
    color: "#93999A",
    fontSize: "12px",
    marginBottom: "2px",
  },
  cross: {
    float: "right",
    cursor: "pointer",
    margin: "-8px",
    padding: "8px",
    color: "#555",
    "&:hover": {
      background: "#555",
      color: "white",
    },
  },
}));

function StudentCard({
  subtext,
  entry,
  student,
  selected,
  highlighted,
  onClick,
  onCross,
}) {
  student = entry || student;

  const classes = useStyles();

  return (
    <Box
      padding={1}
      marginBottom="3px"
      style={{
        borderLeft: "3px solid #555",
        backgroundColor: selected ? "#555" : "#55555522",
        cursor: onClick ? "pointer" : "default",
        color: selected ? "white" : "#686868",
        fontSize: "14px",
        outline: highlighted ? "1px solid #555" : "",
      }}
      onClick={onClick}
    >
      {subtext && (
        <span className={["truncate-1", classes.subtext].join(" ")}>
          {subtext}
        </span>
      )}
      <Box display="flex">
        <span
          className="truncate-1"
          style={{ width: onCross ? "calc(100% - 17px)" : "100%" }}
        >
          {student.nickname}
        </span>
        {onCross && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.cross}
            onClick={(e) => {
              e.stopPropagation();
              onCross();
            }}
          >
            <Icon name="close" style={{ fontSize: "17px" }} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default StudentCard;
