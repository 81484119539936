import { Box, Grid } from "@material-ui/core";
import _ from "lodash";
import { useState } from "react";
import { PractitionerForm } from ".";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Select,
  Text,
} from "../../design-library";
import { PractitionerResource, UserResource } from "../../resources";

function PractitionerCreationModal({
  open,
  onClose = _.noop,
  onCreate = _.noop,
}) {
  const [form, setForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [nicknameInput, setNicknameInput] = useState();
  const [addStudentFlag, setAddStudentFlag] = useState(true);

  const nickname =
    nicknameInput ?? `${[form.first_name, form.last_name].join(".")}.Class`;
  const shouldAddStudent =
    form.role === UserResource.ROLES.DLP && addStudentFlag;

  const onSave = () => {
    setIsSaving(true);

    const stuForm = {
      first_name: form.first_name,
      last_name: `${form.last_name}'s Class`,
      nickname,
    };
    PractitionerResource.createOne({
      role: UserResource.ROLES.EDUCATOR,
      ...form,
    })
      .then((prac) => {
        onCreate(prac);
        onClose();
        setForm({});
        return prac;
      })
      .then((prac) => {
        if (shouldAddStudent && nickname)
          return prac.createAndAssignStudent(stuForm);
      })
      .finally(() => setIsSaving(false));
  };

  const roles = Object.values(UserResource.ROLES).map((value) => ({
    label: UserResource.ROLE_LABELS[value],
    value,
  }));

  return (
    <Modal
      open={open}
      onClose={onClose}
      breadcrumbs={["Add New User"]}
      footer={
        <Box textAlign="right">
          <Button
            disabled={isSaving}
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "10px" }}
          />
          <Button label="Save" onClick={onSave} primary isLoading={isSaving} />
        </Box>
      }
    >
      <Text type="label" content="Bio" style={{ marginBottom: "12px" }} />
      <PractitionerForm
        newEntry
        onUpdate={(newVals) => setForm({ ...form, ...newVals })}
      />
      <Divider />
      <Text type="label" content="Role" style={{ margin: "12px 0" }} />
      <Box display="flex">
        <Select
          style={{ marginRight: "12px" }}
          options={roles}
          value={form.role || UserResource.ROLES.EDUCATOR}
          onChange={(e) => setForm({ ...form, role: e.target.value })}
        />
        {form.role === UserResource.ROLES.DLP && (
          <Checkbox
            label="Create and assign a student"
            onChange={(e, v) => setAddStudentFlag(v)}
            initialState={addStudentFlag}
          />
        )}
      </Box>
      {shouldAddStudent && (
        <>
          <Divider />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <Input
                placeholder="Enter student nickname"
                label="Student Nickname"
                shrinkLabel
                fullWidth
                style={{ marginTop: "8px" }}
                onChange={(e) => setNicknameInput(e.target.value)}
                value={nickname}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  );
}

export default PractitionerCreationModal;
