import { Text } from "../../../design-library";

function ReplyToQuestionEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          replied to a question{" "}
          {meta.question_by && (
            <>
              asked by <b>{meta.question_by.full_name}</b>{" "}
            </>
          )}
          about tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          .
        </>
      }
    />
  );
}

export default ReplyToQuestionEntry;
