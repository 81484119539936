import PropTypes from "prop-types";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  headerRow: { height: "100%", position: "relative", background: "white" },
  headerCell: {
    backgroundColor: "#00B5B222",
    borderLeft: "4px solid #f5f5f5",
    borderBottom: "4px solid #f5f5f5",
    padding: "8px",
    fontWeight: "bold",
    fontSize: "100%",
    color: "#00B5B2",
    "&:first-child": {
      borderLeft: "2px solid #00B5B222",
    },
  },
}));

function HeaderRow({ columns }) {
  const classes = useStyles();

  return (
    <TableRow>
      {columns.map(({ label, colId }) => (
        <TableCell key={colId} className={classes.headerCell} align="center">
          {label}
        </TableCell>
      ))}
    </TableRow>
  );
}

HeaderRow.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default HeaderRow;
