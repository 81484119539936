/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from "../../design-library";
import { Box } from "@material-ui/core";

import { EpisodeForm } from ".";
import { EpisodeResource, StudentResource } from "../../resources";
import { useEffect, useState } from "react";
import { useCookieState } from "../utils";

function CreateEpisodeModal({ open, studentId, onClose, onCreate }) {
  const [form, setForm] = useCookieState(`newEpisodeForm_${studentId}`, {});
  const [isSaving, setIsSaving] = useState(false);

  const student = StudentResource.pick(studentId);

  const onSave = () => {
    setIsSaving(true);
    const data = { student: studentId, date: new Date(), ...form };
    EpisodeResource.createOne(data)
      .then((epi) => {
        if (onCreate) onCreate(epi.id);
        onClose();
        setForm({});
        student?.reload();
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    if (!student?.monitoring) return;

    const hu = form.heads_up_json || {};
    hu.monitoring = student.monitoring;

    setForm({ ...form, heads_up_json: hu });
  }, [student?.monitoring]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      breadcrumbs={["Episodes", "Create New"]}
      footer={
        <Box textAlign="right">
          <Button
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "12px" }}
            disabled={isSaving}
          />
          <Button primary label="Save" onClick={onSave} isLoading={isSaving} />
        </Box>
      }
    >
      <EpisodeForm initialValues={form} onUpdate={(form) => setForm(form)} />
    </Modal>
  );
}

export default CreateEpisodeModal;
