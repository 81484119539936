import { useHistory, useLocation } from "react-router-dom";
import { Table, Tooltip } from "../../design-library";
import { StruggleDisplayModal } from ".";
import { formatDate } from "../utils";

function StruggleTable({ struggles }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const history = useHistory();
  const setModalStruggleId = (id) => {
    if (!id) query.delete("id");
    else query.set("id", id);

    history.push({ search: query.toString() });
  };

  const modalStruggleId = query.get("id");

  return (
    <>
      <Table
        columns={[
          { colId: "headline", label: "Title" },
          { colId: "practitioner", label: "Practitioner" },
          {
            colId: "replied_by",
            label: "Replied by",
            formatter: (authors) => {
              if (!authors?.length) return "-";

              const first = authors[0].full_name;
              if (authors.length === 1) return first;

              return (
                <Tooltip content={authors.map((au) => au.full_name).join(", ")}>
                  <span>
                    {first}
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#999",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      + {authors.length - 1} more
                    </span>
                  </span>
                </Tooltip>
              );
            },
          },
          { colId: "created_at", label: "Date", formatter: formatDate },
        ]}
        data={struggles}
        onRowClick={(rowData) => setModalStruggleId(rowData.id)}
      />

      <StruggleDisplayModal
        struggleId={modalStruggleId}
        onClose={() => setModalStruggleId()}
      />
    </>
  );
}

export default StruggleTable;
