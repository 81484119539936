import { Box } from "@material-ui/core";
import { useCallback, useState } from "react";
import { ContentList } from "../../design-library";
import { TipDisplayModal } from "../Tip";
import { PractitionerDisplayModal } from "../Practitioner";
import ActivityEntry from "./ActivityEntry";
import { EpisodeDisplayModal } from "../Episode";

function ActivityLog({ entries, showUser, style }) {
  const [modalContent, setModalContent] = useState({});

  const CardComponent = useCallback(
    (props) => <ActivityEntry {...props} showUser={showUser} />,
    [showUser]
  );

  const onModalClose = useCallback(() => setModalContent({}), []);

  return (
    <Box style={style}>
      <ContentList
        entries={entries}
        CardComponent={CardComponent}
        spacing={0}
        onEntryClick={(entry, e) => setModalContent(e)}
      />
      {modalContent.tip && (
        <TipDisplayModal
          tipId={modalContent.tip}
          initialSiderContent={{ example: modalContent.example }}
          onClose={onModalClose}
        />
      )}
      {modalContent.practitioner && (
        <PractitionerDisplayModal
          practitionerId={modalContent.practitioner}
          onClose={onModalClose}
        />
      )}
      {modalContent.episode && (
        <EpisodeDisplayModal
          episodeId={modalContent.episode}
          initialSiderContent={{ example: modalContent.example }}
          onClose={onModalClose}
        />
      )}
    </Box>
  );
}

export default ActivityLog;
