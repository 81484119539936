import { SvgIcon } from "@material-ui/core";

function Filter(props) {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M9.11509 11.3409C9.36925 11.615 9.50879 11.973 9.50879 12.3434V23.2571C9.50879 23.9139 10.3087 24.2473 10.7821 23.7855L13.8545 20.2966C14.2656 19.8077 14.4924 19.5657 14.4924 19.0817V12.3458C14.4924 11.9755 14.6344 11.6174 14.8861 11.3433L23.7019 1.86422C24.3623 1.1531 23.854 0 22.8772 0H1.12393C0.147151 0 -0.363664 1.15063 0.299149 1.86422L9.11509 11.3409Z" />
      </svg>
    </SvgIcon>
  );
}

export default Filter;
