import PropTypes from "prop-types";
import { Box, Divider, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import { Button, Icon, Text } from "..";
import _ from "lodash";

function Dropdown({
  icon,
  label,
  options,
  onSelect = _.noop,
  closeOnSelect = true,
  selectedItems,
  title,
  statefulLoader = false,
  isLoading = false,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState();
  const [wait, setWait] = useState(false);

  const checked = (val) => selectedItems?.includes(val);

  if (statefulLoader) isLoading = wait;

  const statefulClickHandler = (value) => {
    const promise = onSelect(value);
    if (promise instanceof Promise) {
      setWait(true);
      return promise.finally(() => setWait(false));
    }
  };

  return (
    <>
      <Button
        isLoading={isLoading}
        icon={icon}
        label={label}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        {...props}
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Box>
          {title && (
            <>
              <Text
                type="label"
                content={title}
                style={{ textAlign: "center", padding: "0 8px" }}
              />
              <Divider style={{ margin: "6px 0" }} />
            </>
          )}
          {options.map((option) => (
            <MenuItem
              key={option.value}
              style={{ color: "#555" }}
              onClick={() => {
                closeOnSelect && setAnchorEl(null);
                statefulClickHandler(option.value);
              }}
            >
              {selectedItems && (
                <Icon
                  name={checked(option.value) ? "checked" : "unchecked"}
                  style={{
                    fontSize: "18px",
                    marginRight: "8px",
                    color: checked(option.value) ? "#00B5B2" : "#555",
                  }}
                />
              )}
              {option.label}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  );
}

Dropdown.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  selectedItems: PropTypes.array,
  title: PropTypes.string,
};
export default Dropdown;
