import PropTypes from "prop-types";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  cell: {
    borderLeft: "4px solid #f5f5f5",
    borderBottom: "4px solid #f5f5f5",
    padding: "8px",
    maxWidth: "400px",
    "&:first-child": {
      borderLeft: "2px solid #00B5B2",
    },
  },
  row: {
    backgroundColor: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00B5B210",
    },
  },
}));

function DataRow({ columns, row, onClick = _.noop }) {
  const classes = useStyles();

  return (
    <TableRow className={classes.row} onClick={onClick}>
      {columns.map(({ colId, formatter }, idx) => (
        <TableCell
          key={colId}
          className={`${classes.cell} truncate`}
          align={idx > 0 ? "center" : undefined}
        >
          {formatter ? formatter(row[colId]) : row[colId]}
        </TableCell>
      ))}
    </TableRow>
  );
}

DataRow.propTypes = {
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default DataRow;
