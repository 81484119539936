import _ from "lodash";
import {
  ActivityResource,
  QuestionResource,
  StudentResource,
  UserResource,
} from ".";
import Base from "./base";

export default class PractitionerResource extends Base {
  static __requiredFields = {
    update: [
      { key: "email", label: "Email Address" },
      { key: "first_name", label: "First Name" },
      { key: "last_name", label: "Last Name" },
    ],
    createOne: [
      { key: "first_name", label: "First Name" },
      { key: "last_name", label: "Last Name" },
      { key: "username", label: "Username" },
      { key: "email", label: "Email Address" },
    ],
  };

  get __properties() {
    return [
      "id",
      "first_name",
      "last_name",
      "role",
      "username",
      "last_activity",
      "contributions",
      "assigned_student_ids",
      "date_joined",
      "unique_tried_tips_count",
      "tipActs",
      "exampleActs",
      "struggleActs",
      "activityPages",
      "questionPages",
      "email",
      "professional_goal",
    ];
  }

  get __resource() {
    return "PRACTITIONER";
  }

  get __endpoint() {
    return "/v1/users";
  }

  static toObject(data) {
    const prac = new PractitionerResource();

    if (data.assigned_students)
      data.assigned_student_ids = data.assigned_students.map((stu) => stu.id);

    prac.assign(data);
    return prac;
  }

  static responseProcessor_list(data) {
    const rawStudents = [];
    for (const d of data) {
      rawStudents.push(...d.assigned_students);
    }

    const students = rawStudents.map((stu) => StudentResource.toObject(stu));
    StudentResource.storeStateObjects(students);

    return data;
  }

  static responseProcessor_getOne(data) {
    const students = data.assigned_students.map((stu) =>
      StudentResource.toObject(stu)
    );
    StudentResource.storeStateObjects(students);
    return data;
  }

  async createAndAssignStudent(form) {
    const student = await StudentResource.createOne(form);
    await this.assignStudents([student.id]);
  }

  async assignStudents(studentIds) {
    await PractitionerResource.http.post(
      `${this.__endpoint}/${this.id}/assign-students/`,
      {},
      { students: studentIds }
    );

    const allIds = [...studentIds, ...[this.assigned_student_ids || []]];
    this.assigned_student_ids = [...new Set(allIds)];
    this.updateStateObject();
  }

  async unassignStudents(studentIds) {
    await PractitionerResource.http.post(
      `${this.__endpoint}/${this.id}/unassign-students/`,
      {},
      { students: studentIds }
    );

    const allIds = this.assigned_student_ids;
    this.assigned_student_ids = allIds.filter((id) => !studentIds.includes(id));
    this.updateStateObject();
  }

  async loadActivities(params = { page: 1 }) {
    const activities = await ActivityResource.list({
      user: this.id,
      ...params,
    });

    if (params.page > 1) {
      this.activityPages[params.page] = activities.map((ep) => ep.id);
    } else {
      this.activityPages = { 1: activities.map((ep) => ep.id) };
    }

    this.updateStateObject();

    return activities;
  }

  async loadQuestions(params = { page: 1 }) {
    const questions = await QuestionResource.list({
      user: this.id,
      ...params,
    });

    if (params.page > 1) {
      this.questionPages[params.page] = questions.map((ep) => ep.id);
    } else {
      this.questionPages = { 1: questions.map((ep) => ep.id) };
    }

    this.updateStateObject();

    return questions;
  }

  async getShortTermJWT() {
    const resp = await this.constructor.http.post(
      `${this.__endpoint}/${this.id}/jwt/`
    );
    return resp.token;
  }

  get fullName() {
    return `${this.first_name} ${this.last_name}`.trim() || "-";
  }

  get assignedStudents() {
    const { studentsMap } = this.constructor.appContext.appState;
    if (!studentsMap || !this.assigned_student_ids) return null;
    return this.assigned_student_ids
      .map((id) => studentsMap[id])
      .filter(Boolean);
  }

  get tipEntries() {
    const { tipsMap } = this.constructor.appContext.appState;
    return (
      tipsMap &&
      this.tipActs
        ?.map(({ id, date }) => ({ tip: tipsMap[id], date, id }))
        .filter(Boolean)
    );
  }

  get exampleEntries() {
    const { examplesMap } = this.constructor.appContext.appState;
    return (
      examplesMap &&
      this.exampleActs
        ?.map(({ id, date }) => ({ example: examplesMap[id], date, id }))
        .filter(Boolean)
    );
  }

  get struggleEntries() {
    const { strugglesMap } = this.constructor.appContext.appState;
    return (
      strugglesMap &&
      this.struggleActs
        ?.map(({ id, date }) => ({ struggle: strugglesMap[id], date, id }))
        .filter(Boolean)
    );
  }

  get activities() {
    const { activitiesMap } = this.constructor.appContext.appState;

    if (!activitiesMap || !this.activityPages) return null;

    return _.flatten(Object.values(this.activityPages))
      .map((id) => activitiesMap[id])
      .filter(Boolean);
  }

  get questions() {
    const { questionsMap } = this.constructor.appContext.appState;

    if (!questionsMap || !this.questionPages) return null;

    return _.flatten(Object.values(this.questionPages))
      .map((id) => questionsMap[id])
      .filter(Boolean);
  }

  get roleLabel() {
    return UserResource.ROLE_LABELS[this.role] || "Unknown";
  }
}
