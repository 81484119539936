import PropTypes from "prop-types";
import { Input } from "..";
import _ from "lodash";

function SearchBar({
  initialValue = "",
  onSearch = _.noop,
  placeholder = "",
  noBorder = true,
}) {
  return (
    <Input
      onChange={_.debounce((e) => onSearch(e.target.value), 500)}
      onEnterPress={(e) => onSearch(e.target.value)}
      placeholder={placeholder}
      noBorder={noBorder}
      icon="search"
      initialValue={initialValue}
    />
  );
}

SearchBar.propTypes = {
  initialValue: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default SearchBar;
