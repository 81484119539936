import { Box, Link, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Text } from "../../design-library";
import ConfirmPasswordResetBox from "./ConfirmPasswordResetBox";
import RequestPasswordResetBox from "./RequestPasswordResetBox";
import SignInBox from "./SignInBox";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "#E8ECEF",
    margin: "0 -16px",
  },
  logo: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  logoRibbon: {
    height: "30px",
    width: "100%",
    marginTop: "-89px",
    background: "#D0D4D7",
  },
  sideRibbon: {
    position: "absolute",
    height: "calc(100vh - 200px)",
    width: "71px",
    background: "#BDC1CA",
    top: "200px",
    left: "187px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    padding: "6px 8px 8px 8px",
    textAlign: "center",
    background: "#D0D4D7",
  },
}));

export default function Page() {
  const [showResetBox, setShowResetBox] = useState();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const resetRequestToken = query.get("reset-request-token");

  useEffect(() => {
    document.title = "Learning Seeds | Login";
  }, []);

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {resetRequestToken ? (
        <ConfirmPasswordResetBox token={resetRequestToken} />
      ) : showResetBox ? (
        <RequestPasswordResetBox toggleBox={() => setShowResetBox(false)} />
      ) : (
        <SignInBox toggleBox={() => setShowResetBox(true)} />
      )}
      <Box className={classes.logo}>
        <Box className={classes.sideRibbon} />
        <Box marginLeft="180px">
          <img src="/logo-large.jpg" alt="" height="200px" />
        </Box>
        <Box className={classes.logoRibbon} />
      </Box>
      <Box className={classes.footer}>
        <Text
          content={
            <>
              <Link href="#" style={{ color: "inherit" }}>
                Terms
              </Link>
              &nbsp; | &nbsp;
              <Link href="#" style={{ color: "inherit" }}>
                Contact Us
              </Link>
              &nbsp; | &nbsp;
              <span>&copy;2022 Learning Seeds</span>
            </>
          }
        />
      </Box>
    </Box>
  );
}
