import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import _ from "lodash";

function MyCheckbox({
  initialState,
  value,
  label,
  disabled = false,
  indeterminate = false,
  onChange = _.noop,
}) {
  const checkbox = (
    <Checkbox
      disabled={disabled}
      defaultChecked={initialState}
      onChange={onChange}
      color="primary"
      style={{ padding: "6px" }}
      size="small"
      checked={value}
      indeterminate={indeterminate}
    />
  );

  return (
    <FormControlLabel
      style={{ margin: "-2px 0 -2px -8px", color: "#686868" }}
      control={checkbox}
      label={label}
      color="primary"
    />
  );
}

MyCheckbox.propTypes = {
  initialState: PropTypes.bool,
  label: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
};

export default MyCheckbox;
