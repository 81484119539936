import { Box, makeStyles } from "@material-ui/core";
import { Text } from "../../../design-library";
import { ActivityResource } from "../../../resources";
import { formatDate, relativeTimeFormat } from "../../utils";
import CreateTipEntry from "./CreateTipEntry";
import EditTipEntry from "./EditTipEntry";
import RateTipEntry from "./RateTipEntry";
import SuggestTipEntry from "./SuggestTipEntry";
import TryTipEntry from "./TryTipEntry";
import AttachRelatedTipsEntry from "./AttachRelatedTipsEntry";
import SetTipEditMarkEntry from "./SetTipEditMarkEntry";
import AttachTipWithExampleEntry from "./AttachTipWithExampleEntry";
import DetachTipFromExmapleEntry from "./DetachTipFromExampleEntry";
import CreateExampleEntry from "./CreateExampleEntry";
import AskQuestionEntry from "./AskQuestionEntry";
import ReplyToQuestionEntry from "./ReplyToQuestionEntry";
import CreateEpisodeEntry from "./CreateEpisodeEntry";
import EditEpisodeEntry from "./EditEpisodeEntry";
import ReadTipEntry from "./ReadTipEntry";
import EditExampleEntry from "./EditExampleEntry";
import ToggleExampleBookmarkEntry from "./ToggleExampleBookmarkEntry";

const componentMap = {
  [ActivityResource.types.RATE_TIP]: RateTipEntry,
  [ActivityResource.types.TRY_TIP]: TryTipEntry,
  [ActivityResource.types.CREATE_TIP]: CreateTipEntry,
  [ActivityResource.types.EDIT_TIP]: EditTipEntry,
  [ActivityResource.types.SUGGEST_TIP]: SuggestTipEntry,
  [ActivityResource.types.ATTACH_RELATED_TIPS_TO_TIP]: AttachRelatedTipsEntry,
  [ActivityResource.types.TOGGLE_TIP_EDIT_MARK]: SetTipEditMarkEntry,
  [ActivityResource.types.ATTACH_TIP_WITH_EXAMPLE]: AttachTipWithExampleEntry,
  [ActivityResource.types.DETACH_TIP_FROM_EXAMPLE]: DetachTipFromExmapleEntry,
  [ActivityResource.types.CREATE_EXAMPLE]: CreateExampleEntry,
  [ActivityResource.types.CREATE_QUESTION]: AskQuestionEntry,
  [ActivityResource.types.REPLY_QUESTION]: ReplyToQuestionEntry,
  [ActivityResource.types.CREATE_EPISODE]: CreateEpisodeEntry,
  [ActivityResource.types.EDIT_EPISODE]: EditEpisodeEntry,
  [ActivityResource.types.EDIT_EXAMPLE]: EditExampleEntry,
  [ActivityResource.types.READ_TIP]: ReadTipEntry,
  [ActivityResource.types.TOGGLE_EXAMPLE_BOOKMARK]: ToggleExampleBookmarkEntry,
};

function getDateTime(date) {
  const days = (new Date() - new Date(date)) / (1000 * 60 * 60 * 24);
  if (days > 2) return formatDate(date);
  if (days < 1) return relativeTimeFormat(date);

  return "Yesterday";
}

const useStyles = makeStyles(() => ({
  entry: {
    paddingLeft: "8px",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "1px",
      left: 0,
      top: "-6px",
      bottom: "-6px",
      borderLeft: "1px solid #B5B5B5",
    },
    "&::after": {
      content: "'•'",
      fontFamily: "Lato",
      position: "absolute",
      width: "1px",
      left: "-4px",
      top: 0,
      bottom: 0,
      color: "#B5B5B5",
    },
  },
  byline: {
    marginLeft: "12px",
  },
}));

function ActivityEntry({ entry, showUser, onClick }) {
  const classes = useStyles();
  const Component = componentMap[entry.type];

  return (
    <Box marginBottom="12px" display="flex" className={classes.entry}>
      <Box width="100px">
        <Text type="subtitle" content={getDateTime(entry.created_at)} />
      </Box>
      <Box color="#B5B5B5">|</Box>
      <Box width="calc(100% - 102px)" display="flex">
        {showUser && (
          <Text
            content={entry.user?.full_name || "-"}
            type="clickable"
            className={classes.byline}
            onClick={() => onClick({ practitioner: entry.user.id })}
          />
        )}
        <Box paddingLeft="8px">
          {Component ? (
            <Component meta={entry.meta} onClick={onClick} />
          ) : (
            <Text content="performed an unknown activity." />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ActivityEntry;
