import { Box, makeStyles } from "@material-ui/core";
import { Spinner, Text } from "../../design-library";
import { UserResource } from "../../resources";
import { formatDate } from "../utils";

const useStyles = makeStyles(() => ({
  activity: { color: "#e03650" },
  eduPlusRateBtn: {
    color: "#00B5B2",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

function ActivityInfoDLP({ tip }) {
  const classes = useStyles();

  return (
    <>
      &nbsp;&nbsp;&bull;&nbsp;&nbsp;
      <span className={classes.activity}>
        {!tip.is_rated
          ? "Please rate the tip"
          : !tip.try_count
          ? "You haven't tried it yet"
          : `Tried ${tip.try_count} - Useful ${tip.helpful_count}`}
      </span>
    </>
  );
}

function ActivityInfoEducatorPlus({ tip, sTip, onClick }) {
  const classes = useStyles();

  return (
    <>
      {tip.try_count > 0 && (
        <span>
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {`Tried ${tip.try_count} - Useful ${tip.helpful_count}`}
        </span>
      )}
      {onClick && !sTip?.is_rated && (
        <>
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          <span className={classes.eduPlusRateBtn} onClick={onClick}>
            RATE THE TIP
          </span>
        </>
      )}
    </>
  );
}

function TipByline({ tip, sTip, onRateClick }) {
  const user = UserResource.current;

  return (
    <>
      <Text content={tip.title} type="title" />
      {!tip.loaded ? (
        <Spinner />
      ) : (
        <Box>
          <Text
            content={
              <>
                Created at {formatDate(tip.created_at)} by{" "}
                {tip.created_by?.full_name || <i>Unknown</i>}
                {user.isDLP ? (
                  <ActivityInfoDLP tip={tip} />
                ) : user.isEducatorPlus ? (
                  <ActivityInfoEducatorPlus
                    tip={tip}
                    sTip={sTip}
                    onClick={onRateClick}
                  />
                ) : (
                  <></>
                )}
              </>
            }
            type="subtitle"
          />
        </Box>
      )}
    </>
  );
}

export default TipByline;
