/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Input, Text } from "../../design-library";

const bioFields = [
  {
    label: "First Name",
    dataKey: "first_name",
    placeholder: "Enter first name",
  },
  {
    label: "Last Name",
    dataKey: "last_name",
    placeholder: "Enter last name",
  },
  {
    label: "Nickname",
    dataKey: "nickname",
    placeholder: "Enter nickname",
  },
];

function StudentForm({ onUpdate }) {
  const [form, setForm] = useState({});
  const onInput = (key, val) => setForm((f) => ({ ...f, [key]: val }));

  useEffect(() => onUpdate(form), [form]);

  return (
    <Box>
      <Text type="label" content="Bio" style={{ marginBottom: "12px" }} />
      <Grid container spacing={2}>
        {bioFields.map(({ label, dataKey, placeholder }) => (
          <Grid xs={6} item key={dataKey}>
            <Input
              placeholder={placeholder}
              label={label}
              onChange={(e) => onInput(dataKey, e.target.value)}
              shrinkLabel
              fullWidth
              fullHeight
              multiline
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default StudentForm;
