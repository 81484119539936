/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Button,
  DateRangePicker,
  SearchBar,
  Spinner,
  Table,
} from "../../design-library";
import { formatDate, useURLState } from "../../shared-components/utils";
import {
  CelebrationDisplayModal,
  CreateCelebrationModal,
} from "../../shared-components/Celebration";
import { Waypoint } from "react-waypoint";

const defaultRange = {
  start_date: moment().subtract(6, "weeks").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};

function formatParams(params, page) {
  return {
    title: params.title,
    start_date: params.start_date || defaultRange.start_date,
    end_date: params.end_date || defaultRange.end_date,
    page_size: params.pageSize || 20,
    page: page || 1,
  };
}

export default function CelebrationTab({ student }) {
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [createCelebModalOpen, setCreateCelebModalOpen] = useState(false);

  const [params, setParams] = useURLState({}, [
    "title",
    "start_date",
    "end_date",
  ]);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const history = useHistory();
  const setModalCelebrationId = (id) => {
    if (!id) query.delete("id");
    else query.set("id", id);

    history.push({ search: query.toString() });
  };

  const modalCelebrationId = query.get("id");

  const loadNextCelebrations = (fromFirst = true) => {
    setIsLoading(true);
    const page = fromFirst ? 1 : nextPage;

    student
      .loadCelebrations(formatParams(params, page))
      .then((cel) => setNextPage(cel.length < 20 ? -1 : page + 1))
      .catch(() => setNextPage(-1))
      .finally(() => setIsLoading(false));
  };

  useEffect(loadNextCelebrations, [params, student.id]);

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box height="60px">
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              initialValue={params.title || ""}
              onSearch={(q) => setParams({ ...params, title: q })}
              placeholder="Search a celebration"
            />
            <DateRangePicker
              style={{ marginLeft: "12px" }}
              noBorder
              range={{
                startDate: new Date(
                  params.start_date || defaultRange.start_date
                ),
                endDate: new Date(params.end_date || defaultRange.end_date),
              }}
              onChange={({ startDate, endDate }) =>
                setParams({
                  ...params,
                  start_date: moment(startDate).format("YYYY-MM-DD"),
                  end_date: moment(endDate).format("YYYY-MM-DD"),
                })
              }
            />
            {isLoading && (
              <Spinner
                style={{
                  display: "inline-flex",
                  height: "40px",
                  marginLeft: "12px",
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              label="Create Celebration"
              primary
              onClick={() => setCreateCelebModalOpen(true)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box height="calc(100% - 60px)" overflow="auto" position="relative">
        <Table
          columns={[
            { colId: "headline", label: "Title" },
            { colId: "practitioner", label: "Practitioner" },
            { colId: "created_at", label: "Date", formatter: formatDate },
          ]}
          data={student.celebrations}
          onRowClick={(rowData) => setModalCelebrationId(rowData.id)}
        />
        {!isLoading && nextPage !== -1 && (
          <Waypoint onEnter={() => loadNextCelebrations(false)} />
        )}
        {nextPage > 1 && isLoading && (
          <Spinner style={{ height: "40px" }} label="Loading more..." />
        )}
      </Box>
      <CelebrationDisplayModal
        celebrationId={modalCelebrationId}
        onClose={() => setModalCelebrationId()}
      />
      <CreateCelebrationModal
        open={createCelebModalOpen}
        onClose={() => setCreateCelebModalOpen(false)}
        studentId={student.id}
        onCreate={(celebrationId) => {
          student.celebrationPages[1]?.unshift(celebrationId);
          student.updateStateObject();
        }}
      />
    </Box>
  );
}
