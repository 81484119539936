import _ from "lodash";
import moment from "moment";
import { ActivityResource, UserResource } from ".";
import Base from "./base";

export default class QuestionResource extends Base {
  static __requiredFields = {
    createOne: [
      { key: "tip", label: "Tip" },
      { key: "body", label: "Body" },
    ],
    update: [],
  };

  get __resource() {
    return "QUESTION";
  }

  get __endpoint() {
    return "/v1/questions";
  }

  get __properties() {
    return ["id", "added_by", "body", "tip", "created_at", "replies"];
  }

  static async getRecent() {
    const questions = await this.list({
      start_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
    });

    this.appContext.appDispatch({
      type: "RECENT_QUESTION_IDS:SET",
      payload: questions.map((q) => q.id),
    });

    return questions;
  }

  static track(action, obj, meta) {
    switch (action) {
      case "create":
        return ActivityResource.activities.CREATE_QUESTION({
          question: obj.id,
          tip: obj.tip,
        });
      case "reply":
        return ActivityResource.activities.REPLY_QUESTION({
          question: obj.id,
          tip: obj.tip,
          reply: meta.reply,
          question_by: _.pick(obj.added_by, ["id", "full_name"]),
        });

      default:
        return null;
    }
  }

  async addReply({ body }) {
    const reply = await Base.http.post(
      `${this.__endpoint}/${this.id}/replies/`,
      {},
      { body }
    );
    this.replies.unshift(reply);
    this.updateStateObject();

    QuestionResource.track("reply", this, { reply: reply.id });
  }

  static getAuthorName(entry) {
    return entry.added_by?.id === UserResource.current.id
      ? "You"
      : entry.added_by?.full_name;
  }
}
