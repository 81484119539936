import { Box, Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Button, Checkbox, Divider, Select, Text } from "../../design-library";
import { UserResource } from "../../resources";
import { getFormInputFn, useCookieState } from "../../shared-components/utils";

const questions = [
  {
    idx: 1,
    label: "Helping a child develop pretend play skills",
  },
  {
    idx: 2,
    label: "Helping a child who needs sensory support",
  },
  {
    idx: 3,
    label: "Helping a child who is aggressive towards adults and/or peers",
  },
  {
    idx: 4,
    label: "Helping a child who is struggling to cope with big feeling",
  },
  {
    idx: 5,
    label: "Helping a child develop their interest in other children",
  },
  {
    idx: 6,
    label: "Helping a child who bolts from non preferred activities",
  },
  {
    idx: 7,
    label: "Helping a child who struggles to transition between activities",
  },
  {
    idx: 8,
    label: "Helping a child who struggles to relinquish control/power in play",
  },
  {
    idx: 9,
    label: "Helping a child who is non responsive to adults or peers",
  },
  {
    idx: 10,
    label: "Helping a child deepen their play through object play",
  },
];

const answers = [
  { label: "Unprepared", value: 1 },
  { label: "Somehow unprepared", value: 2 },
  { label: "Somehow prepared", value: 3 },
  { label: "Prepared", value: 4 },
];

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  newBadge: {
    background: ({ tipColor }) => tipColor,
    color: "white",
    padding: "0 2px",
    fontSize: "90%",
  },
}));

export default function DLPSurveyPage() {
  const [form, setForm] = useCookieState("dlp_survey", {});
  const [suppItems, setSuppItems] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const onInput = getFormInputFn(setForm);
  const onToggleSuppItem = (key) => {
    if (!suppItems.includes(key)) {
      setSuppItems([...suppItems, key]);
    } else {
      setSuppItems(suppItems.filter((it) => it !== key));
    }
  };

  const user = UserResource.current;

  const handleSubmit = () => {
    if (Object.keys(form).length !== questions.length) {
      return UserResource.alert.warning("Please fill up all the fields");
    }

    if (suppItems.length !== 3) {
      return UserResource.alert.warning("Please select 3 checkboxes");
    }

    setIsSaving(true);

    const qs = {};
    for (const ob of questions) qs[ob.idx] = ob.label;
    const profGoal = suppItems.map((idx) => qs[idx]).join("\n");

    UserResource.saveSurveyResult({
      answers: form,
      supports: suppItems,
      questions: qs,
    })
      .then(() => user.practitioner.update({ professional_goal: profGoal }))
      .then(() => setForm({}))
      .finally(() => setIsSaving(false));
  };

  const classes = useStyles();

  return (
    <>
      <Box
        height="100px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box height="100%" className={classes.pageHeader}>
              <Text
                type="title"
                style={{ fontSize: "24px", margin: 0 }}
                content={
                  <>
                    Welcome back,{" "}
                    <span style={{ color: "#00B5B2" }}>{user.first_name}</span>!
                  </>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        height="calc(100% - 100px)"
        width="100%"
        overflow="auto"
        position="relative"
      >
        <Box style={{ paddingRight: "12px", paddingBottom: "12px" }}>
          <Text
            type="title"
            content="Professional Development Initial Survey"
          />
          <Text
            type="paragraph"
            content={
              <>
                The following is a list of social/emotional challenges that some
                children may experience in a classroom setting. On a scale from
                1 to 4, select how prepared you are responding to the following
                social or emotional situations in your classroom.
                <br />
                Please also select the 3 areas you most want to receive
                additional support for in the classroom by ticking the checkbox.
              </>
            }
          />
          {questions.map((q) => (
            <Box key={q.idx} style={{ marginTop: "10px" }}>
              <Text
                type="label"
                content={
                  <>
                    {q.idx}. {q.label}:
                  </>
                }
              />
              <Box display="flex" marginTop="10px">
                <Select
                  label="I am..."
                  options={answers}
                  value={form[q.idx] || ""}
                  style={{ width: "200px", marginRight: "12px" }}
                  onChange={(e) => onInput(q.idx, e.target.value)}
                />
                <Checkbox
                  disabled={suppItems.length >= 3 && !suppItems.includes(q.idx)}
                  value={suppItems.includes(q.idx)}
                  label="I want support"
                  onChange={() => onToggleSuppItem(q.idx)}
                />
              </Box>
            </Box>
          ))}
          <Divider />
          <Box>
            <Button
              style={{ marginRight: "10px" }}
              primary
              isLoading={isSaving}
              label="Submit"
              onClick={handleSubmit}
            />
            <Button
              disabled={isSaving}
              label="Reset"
              onClick={() => {
                setForm({});
                setSuppItems([]);
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
