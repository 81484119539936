import { Text } from "../../../design-library";

function EditEpisodeEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          made changes to episode &nbsp;
          <b
            className="clickable"
            onClick={() => onClick({ episode: meta.episode })}
          >
            #{meta.episode}
          </b>
          .
        </>
      }
    />
  );
}

export default EditEpisodeEntry;
