/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { Modal, ContentCard, ContentList, Button, Emptyness } from "..";

function Anchor({ onClick }) {
  return <Button primary label="Select" onClick={onClick} />;
}

function ContentListModal({
  breadcrumbs = ["Content"],
  entries,
  onEntryClick,
  CardComponent = ContentCard,
  AnchorComponent = Anchor,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AnchorComponent onClick={() => setOpen(true)} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        breadcrumbs={breadcrumbs}
        footer={
          <Box textAlign="right">
            <Button label="Back" primary onClick={() => setOpen(false)} />
          </Box>
        }
      >
        {entries?.length === 0 ? (
          <Emptyness style={{ height: "60%" }} />
        ) : (
          <ContentList
            folds={2}
            entries={entries}
            onEntryClick={onEntryClick}
            CardComponent={CardComponent}
          />
        )}
      </Modal>
    </>
  );
}

ContentListModal.propTypes = {
  breadcrumbs: PropTypes.array,
  entries: PropTypes.array,
  onEntryClick: PropTypes.func,
  CardComponent: PropTypes.func,
  AnchorComponent: PropTypes.func,
};

export default ContentListModal;
