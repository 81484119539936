import { Box, Divider, makeStyles } from "@material-ui/core";
import { useRef } from "react";
import { Icon, Spinner, Text, Tooltip } from "../../design-library";

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
  },
}));

function WidgetContainer({ idx, label, isLoading, children, onRearrange }) {
  const classes = useStyles();
  const ref = useRef();

  return (
    <Box
      width="500px"
      height="300px"
      className={classes.root}
      draggable={!!onRearrange}
      onDragStart={(e) => {
        const dom = ref.current;
        var rect = dom.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        e.dataTransfer.setDragImage(dom, x, y);
        e.dataTransfer.setData("widgetDragIdx", idx);
      }}
      onDrop={(e) => {
        const dragIdx = e.dataTransfer.getData("widgetDragIdx");
        if (dragIdx !== idx && onRearrange) onRearrange(dragIdx, idx);
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      <Box
        height="48px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding="0 12px"
        ref={ref}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Text type="label" content={label} />
            {isLoading && <Spinner style={{ marginLeft: "8px" }} />}
          </Box>
          <Tooltip content="You can modify the order of the widget by dragging.">
            <Icon
              name="drag"
              style={{ color: "#686868", opacity: 0.7, cursor: "move" }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Divider />
      <Box
        height="calc(100% - 49px)"
        padding="0 12px"
        style={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {children ? (
          children
        ) : (
          <Box
            height="90%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Spinner />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default WidgetContainer;
