import { Text } from "../../../design-library";

function EditExampleEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          made changes to example &nbsp;
          <b>#{meta.example}</b>.
        </>
      }
    />
  );
}

export default EditExampleEntry;
