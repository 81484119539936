import PropTypes from "prop-types";
import { Switch, FormControlLabel } from "@material-ui/core";
import _ from "lodash";

function MySwitch({
  initialState = false,
  label,
  style = {},
  onChange = _.noop,
}) {
  const switchDom = (
    <Switch
      defaultChecked={initialState}
      onChange={onChange}
      name={label}
      color="primary"
      size="small"
      style={style}
    />
  );

  return (
    <FormControlLabel
      style={{ margin: "-2px 0 -2px -8px", ...style }}
      control={switchDom}
      label={label}
      color="primary"
    />
  );
}

MySwitch.propTypes = {
  initialState: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MySwitch;
