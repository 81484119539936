/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Link as MuiLink, makeStyles } from "@material-ui/core";
import { useCallback, useContext } from "react";
import {
  Route,
  Switch,
  useLocation,
  Link,
  Redirect as ReactRedirect,
} from "react-router-dom";
import { AppContext } from "./context";
import { Icon, MenuItem, Tooltip } from "./design-library";
import {
  BrowseTips,
  Home,
  FourOFour,
  Login,
  Profile,
  Students,
  Student,
  Admin,
} from "./pages";
import { NotificationPopper } from "./shared-components/Notification";
import { NotificationResource, UserResource } from "./resources";

function getLink(path, defaults) {
  if (!defaults) return path;

  for (const key in defaults) {
    path = path.replace(`:${key}`, defaults[key]);
  }

  return path;
}

function Redirect({ to, ...props }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  if (to === "/login") {
    const dest = encodeURIComponent(`${location.pathname}${location.search}`);
    if (dest) to = `/login?dest=${dest}`;
  } else if (params.get("dest")) {
    try {
      to = decodeURIComponent(params.get("dest"));
    } catch (err) {}
  }

  return <ReactRedirect to={to} {...props} />;
}

const routes = [
  {
    path: "/login",
    getComponent: (user) => {
      if (user?.id) return <Redirect to="/home" />;
      return <Login />;
    },
    label: "Login",
  },
  {
    path: "/home",
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      return <Home />;
    },
    label: "Home",
    navPosition: "top",
    icon: "home",
  },
  {
    path: "/profile",
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      return <Profile />;
    },
    label: "Profile",
    navPosition: "top",
    icon: "person",
  },
  {
    path: "/tips",
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      if (user.isDLP && user.yetToFillSurvey) return <Redirect to="/home" />;
      return <BrowseTips />;
    },
    label: "Browse Tips",
    navPosition: "top",
    icon: "bulb",
  },
  {
    path: "/students/:id/:tab",
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      return <Student />;
    },
    label: "Student",
    roles: [
      UserResource.ROLES.ADMIN,
      UserResource.ROLES.EDUCATOR,
      UserResource.ROLES.EDUCATOR_PLUS,
    ],
  },
  {
    path: "/students",
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      return <Students />;
    },
    label: "Students",
    navPosition: "top",
    icon: "student",
    roles: [
      UserResource.ROLES.ADMIN,
      UserResource.ROLES.EDUCATOR,
      UserResource.ROLES.EDUCATOR_PLUS,
    ],
  },
  {
    path: "/admin/:tab",
    defaults: { tab: "activity" },
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      return <Admin />;
    },
    label: "Admin Panel",
    icon: "admin",
    navPosition: "bottom",
    roles: [UserResource.ROLES.ADMIN],
  },
  {
    path: "*",
    getComponent: (user) => {
      if (!user?.id) return <Redirect to="/login" />;
      return <FourOFour />;
    },
    label: "Not Found",
  },
];

const useStyles = makeStyles(() => ({
  nav: {
    height: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  menu: ({ collapsed }) =>
    collapsed
      ? {
          paddingLeft: 0,
          paddingRight: 0,
          justifyContent: "center",
        }
      : {},
  notifCue: ({ collapsed }) => ({
    position: "absolute",
    height: "8px",
    width: "8px",
    background: "#00B5B2",
    borderRadius: "50%",
    right: collapsed ? "8px" : "4px",
    top: collapsed ? "8px" : "15px",
  }),
}));

function NavigationController({ collapsed }) {
  const {
    appDispatch,
    appState: { flags },
  } = useContext(AppContext);
  const location = useLocation();
  const onLogout = () => {
    NotificationResource.stopListener();
    appDispatch({ type: "RESET_ALL" });
  };

  const classes = useStyles({ collapsed });
  const NotificationAnchor = useCallback(
    ({ isActive, ...props }) => (
      <MenuItem
        icon="bell"
        className={classes.menu}
        tooltip={collapsed ? "Notifications" : undefined}
        label={!collapsed && "Notifications"}
        selected={isActive}
        {...props}
      />
    ),
    [collapsed]
  );

  const user = UserResource.current;
  const disabledRoutes = [];
  if (user?.isDLP && user?.yetToFillSurvey) disabledRoutes.push("/tips");

  return (
    <>
      <Box pt={2} height="calc(100% - 250px)">
        {routes
          .filter((route) => route.navPosition === "top")
          .filter((route) => !route.roles || route.roles.includes(user?.role))
          .map((route) => (
            <Box mt={1} mb={1} key={route.path}>
              <Link
                to={getLink(route.path, route.defaults)}
                style={{
                  textDecoration: "none",
                  pointerEvents: disabledRoutes.includes(route.path)
                    ? "none"
                    : "auto",
                }}
              >
                <MenuItem
                  disabled={disabledRoutes.includes(route.path)}
                  selected={location.pathname.startsWith(
                    route.path.split(":")[0]
                  )}
                  className={classes.menu}
                  icon={route.icon}
                  tooltip={collapsed ? route.label : undefined}
                  label={!collapsed && route.label}
                />
              </Link>
            </Box>
          ))}
      </Box>
      <Box className={classes.nav}>
        {routes
          .filter((route) => route.navPosition === "bottom")
          .filter((route) => !route.roles || route.roles.includes(user?.role))
          .map((route) => (
            <Box mt={1} mb={1} key={route.path}>
              <Link
                to={getLink(route.path, route.defaults)}
                style={{ textDecoration: "none" }}
              >
                <MenuItem
                  selected={location.pathname.startsWith(
                    route.path.split(":")[0]
                  )}
                  className={classes.menu}
                  icon={route.icon}
                  tooltip={collapsed ? route.label : undefined}
                  label={!collapsed && route.label}
                />
              </Link>
            </Box>
          ))}
        <Box mb={1} position="relative">
          <NotificationPopper AnchorComponent={NotificationAnchor} />
          {flags.hasNewNotifications && <span className={classes.notifCue} />}
        </Box>
        <Box mb={1}>
          <MenuItem
            icon="exit"
            className={classes.menu}
            onClick={onLogout}
            tooltip={collapsed ? "Logout" : undefined}
            label={!collapsed && "Logout"}
          />
        </Box>
        <Divider />
        {collapsed ? (
          <>
            <Box mt={2} mb={1} textAlign="center">
              <MuiLink href="#" variant="body2" color="secondary">
                <Tooltip content="Terms of use">
                  <Icon name="warning" />
                </Tooltip>
              </MuiLink>
            </Box>
            <Box mt={1} textAlign="center" mb={1}>
              <MuiLink href="#" variant="body2" color="secondary">
                <Tooltip content="Contact support">
                  <Icon name="contact" />
                </Tooltip>
              </MuiLink>
            </Box>
          </>
        ) : (
          <>
            <Box ml={2} mr={2} mt={2} mb={1}>
              <MuiLink href="#" variant="body2" color="secondary">
                Terms of use
              </MuiLink>
            </Box>
            <Box ml={2} mr={2} mt={1} mb={3}>
              <MuiLink href="#" variant="body2" color="secondary">
                Contact us
              </MuiLink>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

function RouteController() {
  const {
    appState: { user },
  } = useContext(AppContext);

  return (
    <Switch>
      <ReactRedirect exact from="/" to="/home" />
      {routes
        .filter((route) => !route.roles || route.roles.includes(user?.role))
        .map((route) => (
          <Route path={route.path} key={route.path}>
            {route.getComponent(user)}
          </Route>
        ))}
    </Switch>
  );
}

export { NavigationController, RouteController };
