/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { UserResource } from "../../resources";
import { ActivityLog } from "../../shared-components/Activity";
import WidgetContainer from "./WidgetContainer";

function RecentActivitiesWidget({ label, idx, onRearrange }) {
  const [isLoading, setIsLoading] = useState(true);
  const practitioner = UserResource.current?.practitioner;

  useEffect(() => {
    setIsLoading(true);
    practitioner?.loadActivities().finally(() => setIsLoading(false));
  }, [practitioner?.id]);

  const activities = practitioner?.activities;

  return (
    <WidgetContainer
      label={label}
      isLoading={isLoading}
      idx={idx}
      onRearrange={onRearrange}
    >
      {activities && (
        <ActivityLog style={{ marginTop: "8px" }} entries={activities} />
      )}
    </WidgetContainer>
  );
}

export default RecentActivitiesWidget;
