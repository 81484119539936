import { Box, makeStyles } from "@material-ui/core";
import { ContentList, ContentListModal, Text } from "../../design-library";
import { TipResource } from "../../resources";

const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "3px solid #f5f5f5",
    "&:first-child": {
      marginTop: "1px",
    },
  },
  descriptor: {
    background: "white",
    width: "250px",
    borderLeft: "3px solid ",
  },
  tips: {
    width: "calc(100% - 250px)",
    background: "white",
    borderLeft: "3px solid #f5f5f5",
  },
  cardContainer: {
    paddingRight: "3px",
  },
  showMore: {
    fontSize: "12px",
    fontStyle: "italic",
    margin: "0 8px 4px 8px",
    textAlign: "right",
  },
}));

export const rowDescriptor = {
  1: (
    <span>
      Rewarding the child <br />
      &bull; Relief <br />
      &bull; Comfort <br />
      &bull; Escape <br />
      &bull; Sensory avoidance
    </span>
  ),
  2: (
    <span>
      Rewarding the child <br />
      &bull; Physical avoidance <br />
      &bull; Control <br />
      &bull; Repetition <br />
      &bull; Sensory seeking
    </span>
  ),
  3: (
    <span>
      Rewarding the child <br />
      &bull; Having audience <br />
      &bull; Social reaction <br />
      &bull; Flickers of connection <br />
      &bull; Attention <br />
      &bull; Social power
    </span>
  ),
  4: (
    <span>
      Rewarding the child <br />
      &bull; Relationships <br />
      &bull; Influence <br />
      &bull; Interactive play
    </span>
  ),
  5: (
    <span>
      Rewarding the child <br />
      &bull; Continuity <br />
      &bull; Predictability <br />
      &bull; Self improvement
    </span>
  ),
};

export const rows = {
  1: {
    label: "Avoidance motivation",
    description: rowDescriptor[1],
  },
  2: {
    label: "Object/sensory motivation",
    description: rowDescriptor[2],
  },
  3: {
    label: "Reaction motivation",
    description: rowDescriptor[3],
  },
  4: {
    label: "Interaction flow motivation",
    description: rowDescriptor[4],
  },
  5: {
    label: "Planning motivation",
    description: rowDescriptor[5],
  },
};

function TipRowV2({ CardComponent, row, descriptor, onClick }) {
  const classes = useStyles();
  const { tips, id: rowId } = row;
  const cutoff = 12;

  return (
    <Box className={classes.row}>
      <Box
        padding={2}
        className={classes.descriptor}
        style={{ borderColor: TipResource.COLORS[rowId] }}
      >
        <Box>
          <Text
            content={rows[rowId].label}
            type="label"
            style={{ color: "#00B5B2" }}
          />
          <Text content={descriptor || rows[rowId].description} />
        </Box>
      </Box>
      <Box className={classes.tips}>
        <ContentList
          entries={tips.slice(0, cutoff)}
          spacing={0}
          CardComponent={CardComponent}
          onEntryClick={onClick}
          folds={3}
        />
        {tips.length > cutoff && (
          <ContentListModal
            breadcrumbs={["Tips"]}
            entries={tips}
            CardComponent={CardComponent}
            onEntryClick={onClick}
            AnchorComponent={({ onClick }) => (
              <Text
                type="clickable"
                content={`+ ${tips.length - cutoff} more`}
                className={classes.showMore}
                onClick={onClick}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
}

export default TipRowV2;
