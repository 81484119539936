import Base from "./base";
import {
  ActivityResource,
  ExampleResource,
  StruggleResource,
  StudentResource,
} from ".";

export const headsUpLabelMap = {
  play_themes: "Play themes",
  preferred_objects: "Preferred objects",
  new_connections: "New connections",
  peer_conflicts: "Peer conflicts",
  routine_changes: "Routine changes",
  new_behaviors: "New behaviors",
  celebrations: "Celebrations",
  shadow_todos: "Shadow To do's",
  misc_observations: "Miscellaneous observations",
  upcoming_events: "Upcoming events",
  monitoring: "Monitoring",
  struggles: "Struggles",
  language: "Language",
};

export default class EpisodeResource extends Base {
  static __requiredFields = {
    createOne: [
      { key: "title", label: "Title" },
      { key: "description", label: "Description" },
    ],
    update: [],
  };

  get __resource() {
    return "EPISODE";
  }

  get __endpoint() {
    return "/v1/episodes";
  }

  get __properties() {
    return [
      "id",
      "date",
      "title",
      "description",
      "studentId",
      "user",
      "transcript",
      "heads_up",
      "heads_up_json",
      "heads_up_fields",
      "example_ids",
      "struggle_ids",
      "contributors",
      "practitioner",
    ];
  }

  static track(action, obj, meta) {
    switch (action) {
      case "create":
        return ActivityResource.activities.CREATE_EPISODE({
          episode: obj.id,
          student: obj.studentId,
          student_nickname: obj.student?.nickname,
        });
      case "edit":
        return ActivityResource.activities.EDIT_EPISODE({ episode: obj.id });
      default:
        return null;
    }
  }

  async loadExamples() {
    const examples = await ExampleResource.list({ episode: this.id });
    this.example_ids = examples.map((ex) => ex.id);
    this.updateStateObject();

    return this;
  }

  async loadStruggles() {
    const struggles = await StruggleResource.list({
      student: this.studentId,
      episode: this.id,
    });
    this.struggle_ids = struggles.map((ex) => ex.id);
    this.updateStateObject();

    return this;
  }

  async addExample(data) {
    const example = await ExampleResource.createOne({
      episode: this.id,
      tip: null,
      ...data,
    });
    this.example_ids = [example.id, ...(this.example_ids || [])];
    this.updateStateObject();
  }

  async addStruggle(data) {
    const struggle = await StruggleResource.createOne({
      episode: this.id,
      student: this.studentId,
      ...data,
    });
    this.struggle_ids = [struggle.id, ...(this.struggle_ids || [])];
    this.updateStateObject();
  }

  static toObject(data) {
    data.studentId = data.student;
    if (data.heads_up_json) {
      data.heads_up_fields = Object.entries(headsUpLabelMap).map(
        ([dataKey, label]) => ({
          label,
          key: dataKey,
          value: data.heads_up_json[dataKey],
        })
      );
    }

    if (!data.practitioner?.id) data.practitioner = data.user;
    else if (
      data.practitioner.id !== data.user.id &&
      !data.contributors?.map((ob) => ob.id).includes(data.user.id)
    ) {
      data.contributors?.push(data.user);
    }

    return super.toObject(data);
  }

  get examples() {
    return ExampleResource.pickMany(this.example_ids);
  }

  get struggles() {
    return StruggleResource.pickMany(this.struggle_ids);
  }

  get student() {
    return StudentResource.pick(this.studentId);
  }
}
