import PropTypes from "prop-types";
import {
  Home,
  Person,
  WbIncandescent,
  ExitToApp,
  Settings,
  Search,
  Replay,
  Close,
  DateRangeOutlined,
  Info,
  Forward,
  EventOutlined,
  DragIndicator,
  AddCircle,
  Print,
  Save,
  BorderColor,
  Warning,
  ContactSupport,
  Menu,
  MenuOpen,
  Add,
  Star,
  Help,
  LinkOff,
  Widgets,
  CheckBox,
  CheckBoxOutlineBlank,
  ThumbDown,
  ThumbUp,
  Forum,
  MoreVert,
  Notifications,
  CheckCircleOutline,
  Bookmark,
} from "@material-ui/icons";
import { Student, Filter, Admin, EditMark, Lock } from "./customIcons";
import { forwardRef } from "react";

const icons = {
  home: Home,
  person: Person,
  bulb: WbIncandescent,
  exit: ExitToApp,
  student: Student,
  settings: Settings,
  search: Search,
  reload: Replay,
  close: Close,
  filter: Filter,
  dateRange: DateRangeOutlined,
  info: Info,
  forward: Forward,
  calendar: EventOutlined,
  drag: DragIndicator,
  plus: AddCircle,
  print: Print,
  save: Save,
  edit: BorderColor,
  warning: Warning,
  contact: ContactSupport,
  menu: Menu,
  menuClose: MenuOpen,
  add: Add,
  star: Star,
  help: Help,
  crossLink: LinkOff,
  widget: Widgets,
  checked: CheckBox,
  unchecked: CheckBoxOutlineBlank,
  admin: Admin,
  editMark: EditMark,
  lock: Lock,
  thumbDown: ThumbDown,
  thumbUp: ThumbUp,
  chat: Forum,
  ellipsis: MoreVert,
  bell: Notifications,
  tick: CheckCircleOutline,
  bookmark: Bookmark,
};

const Icon = forwardRef(({ name, style = {}, onClick, ...props }, ref) => {
  const MuiIcon = icons[name];
  return (
    <MuiIcon
      ref={ref}
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "inherit", ...style }}
      {...props}
    />
  );
});

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  style: PropTypes.object,
};

export default Icon;
