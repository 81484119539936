import { Text } from "../../../design-library";

function DetachTipFromExmapleEntry({ meta, onClick }) {
  return (
    <Text
      content={
        <>
          detached tip &nbsp;
          <b className="clickable" onClick={() => onClick({ tip: meta.tip })}>
            #{meta.tip}
          </b>
          &nbsp; from example &nbsp;
          <b>#{meta.example}</b>.
        </>
      }
    />
  );
}

export default DetachTipFromExmapleEntry;
