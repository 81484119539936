/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import {
  Button,
  DateRangePicker,
  SearchBar,
  Spinner,
} from "../../design-library";
import { EpisodeTable } from "../../shared-components/Episode";
import { CreateEpisodeModal } from "../../shared-components/Episode";
import { useURLState } from "../../shared-components/utils";

const defaultRange = {
  start_date: moment().subtract(6, "weeks").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
};

function formatParams(params, page) {
  return {
    title: params.title,
    start_date: params.start_date || defaultRange.start_date,
    end_date: params.end_date || defaultRange.end_date,
    ordering: params.ordering || "-id",
    page: page || 1,
    page_size: params.pageSize || 20,
  };
}

export default function EpisodesTab({ student }) {
  const [isLoading, setIsLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);
  const [createTipModalOpen, setCreateTipModalOpen] = useState(false);
  const [params, setParams] = useURLState({}, [
    "title",
    "start_date",
    "end_date",
  ]);

  const loadNextEpisodes = (fromFirst = true) => {
    setIsLoading(true);
    const page = fromFirst ? 1 : nextPage;
    student
      .loadEpisodes(formatParams(params, page))
      .then((episodes) => setNextPage(episodes.length < 20 ? -1 : page + 1))
      .catch(() => setNextPage(-1))
      .finally(() => setIsLoading(false));
  };

  useEffect(loadNextEpisodes, [params, student.id]);

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box height="60px">
        <Grid container justifyContent="space-between">
          <Grid item>
            <SearchBar
              initialValue={params.title || ""}
              onSearch={(q) => setParams({ ...params, title: q })}
              placeholder="Search an episode"
            />
            <DateRangePicker
              style={{ marginLeft: "12px" }}
              noBorder
              range={{
                startDate: new Date(
                  params.start_date || defaultRange.start_date
                ),
                endDate: new Date(params.end_date || defaultRange.end_date),
              }}
              onChange={({ startDate, endDate }) =>
                setParams({
                  ...params,
                  start_date: moment(startDate).format("YYYY-MM-DD"),
                  end_date: moment(endDate).format("YYYY-MM-DD"),
                })
              }
            />
            {isLoading && (
              <Spinner
                style={{
                  display: "inline-flex",
                  height: "40px",
                  marginLeft: "12px",
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              label="Create Episode"
              primary
              onClick={() => setCreateTipModalOpen(true)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box height="calc(100% - 60px)" overflow="auto" position="relative">
        <EpisodeTable episodes={student.episodes} />
        {!isLoading && nextPage !== -1 && (
          <>
            .
            <Waypoint onEnter={() => loadNextEpisodes(false)} />
          </>
        )}
        {nextPage > 1 && isLoading && (
          <Spinner style={{ height: "40px" }} label="Loading more..." />
        )}
      </Box>
      <CreateEpisodeModal
        open={createTipModalOpen}
        onClose={() => setCreateTipModalOpen(false)}
        studentId={student.id}
        onCreate={(episodeId) => {
          student.episodePages[1]?.unshift(episodeId);
          student.updateStateObject();
        }}
      />
    </Box>
  );
}
