import { Box } from "@material-ui/core";
import _ from "lodash";
import { useState } from "react";
import { StudentForm } from ".";
import { Button, Modal } from "../../design-library";
import { StudentResource } from "../../resources";

function StudentCreationModal({ open, onClose, onCreate = _.noop }) {
  const [form, setForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const onSave = () => {
    setIsSaving(true);

    StudentResource.createOne(form)
      .then((stu) => {
        onCreate(stu);
        onClose();
        setForm({});
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      breadcrumbs={["Add New Student"]}
      footer={
        <Box textAlign="right">
          <Button
            disabled={isSaving}
            label="Cancel"
            onClick={onClose}
            style={{ marginRight: "10px" }}
          />
          <Button label="Save" onClick={onSave} primary isLoading={isSaving} />
        </Box>
      }
    >
      <StudentForm onUpdate={setForm} />
    </Modal>
  );
}

export default StudentCreationModal;
