import { Box, Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Dropdown, Emptyness, Text } from "../../design-library";
import {
  arrayMove,
  readCookie,
  writeCookie,
} from "../../shared-components/utils";
import RecentActivitiesWidget from "./RecentActivitiesWidget";
import NewStrugglesWidget from "./NewStrugglesWidget";
import ContributionWidget from "./ContributionWidget";
import { UserResource } from "../../resources";
import RecentQuestionsWidget from "./RecentQuestionsWidget";

const useStyles = makeStyles(() => ({
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  widgetSelector: {
    marginRight: "-10px",
    "& svg": {
      fontSize: "30px !important",
    },
  },
}));

const allWidgets = [
  {
    label: "Your Contributions",
    value: "your_contributions",
    Component: ContributionWidget,
    excludeFromDLP: true,
  },
  {
    label: "Your Recent Activities",
    value: "recent_activities",
    Component: RecentActivitiesWidget,
  },
  {
    label: "New Struggles",
    value: "new_struggles",
    Component: NewStrugglesWidget,
    excludeFromDLP: true,
  },
  {
    label: "Recent Questions",
    value: "recent_questions",
    Component: RecentQuestionsWidget,
  },
];

function getInitialWidgets() {
  const widgets = readCookie("widgetConfig") || {};
  let idx = Object.keys(widgets).length;

  for (const { value } of allWidgets) {
    if (value in widgets) continue;

    widgets[value] = { idx, show: true };
    idx += 1;
  }

  return widgets;
}

export default function DashboardPage() {
  const classes = useStyles();
  const [widgets, setWidgets] = useState(getInitialWidgets());

  const toggleWidget = (key) => {
    widgets[key].show = !widgets[key].show;
    setWidgets({ ...widgets });
    writeCookie("widgetConfig", widgets);
  };

  const handleRearrange = (fromIdx, toIdx) => {
    let sortedKeys = Object.keys(widgets).sort(
      (a, b) => widgets[a].idx - widgets[b].idx
    );

    sortedKeys = arrayMove(sortedKeys, fromIdx, toIdx);
    for (const [idx, key] of sortedKeys.entries()) {
      widgets[key].idx = idx;
    }

    setWidgets({ ...widgets });
    writeCookie("widgetConfig", widgets);
  };

  const user = UserResource.current;
  const widgetList = allWidgets.filter((w) => !user.isDLP || !w.excludeFromDLP);
  const visibleWidgets = widgetList.filter(({ value }) => widgets[value].show);

  return (
    <>
      <Box
        height="100px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box height="100%" className={classes.pageHeader}>
              <Text
                type="title"
                style={{ fontSize: "24px", margin: 0 }}
                content={
                  <>
                    Welcome back,{" "}
                    <span style={{ color: "#00B5B2" }}>{user.first_name}</span>!
                  </>
                }
              />
            </Box>
          </Grid>
          <Grid item>
            <Box
              height="100%"
              className={[classes.pageHeader, classes.widgetSelector].join(" ")}
            >
              <Dropdown
                title="Widgets"
                icon="widget"
                options={widgetList}
                onSelect={toggleWidget}
                selectedItems={Object.keys(widgets).filter(
                  (key) => widgets[key].show
                )}
                closeOnSelect={false}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box height="calc(100% - 100px)" width="100%" overflow="auto">
        {visibleWidgets.length ? (
          <Grid container>
            {visibleWidgets
              .sort((a, b) => widgets[a.value].idx - widgets[b.value].idx)
              .map(({ label, value, Component }) => (
                <Grid item key={value} style={{ margin: "0 16px 16px 0" }}>
                  <Component
                    label={label}
                    idx={widgets[value].idx}
                    onRearrange={handleRearrange}
                  />
                </Grid>
              ))}
          </Grid>
        ) : (
          <Emptyness />
        )}
      </Box>
    </>
  );
}
