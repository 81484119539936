import { Fragment } from "react";
import { Box, Divider } from "@material-ui/core";
import { Text } from "../../design-library";
import { formatDate } from "../utils";

const entries = [
  {
    label: "Celebration",
    dataKey: "celebration",
  },
];

function CelebrationDetails({ celebration }) {
  return (
    <Box>
      <Text type="title" content={celebration.headline} />
      <Text
        type="subtitle"
        content={`Created at ${formatDate(celebration.created_at)} by ${
          celebration.practitioner
        }`}
      />
      <Divider style={{ margin: "20px 0" }} />
      {entries.map((entry, idx) => (
        <Fragment key={entry.dataKey}>
          <Text
            type="label"
            content={entry.label}
            style={{ marginTop: idx > 0 ? "20px" : 0 }}
          />
          <Text
            type="paragraph"
            content={celebration[entry.dataKey]}
            style={{ marginTop: "6px" }}
          />
        </Fragment>
      ))}
    </Box>
  );
}

export default CelebrationDetails;
