/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import _ from "lodash";

import { useEffect } from "react";
import { useState } from "react";
import { Text } from "../../design-library";

const ratingFields = [
  { label: "Clarity", dataKey: "clarity" },
  { label: "Relevance", dataKey: "relevance" },
  { label: "Uniqueness", dataKey: "uniqueness" },
];

function TipReviewForm({ onChange = _.noop, style }) {
  const [form, setForm] = useState({});
  const setField = (field, value) => setForm({ ...form, [field]: value });

  useEffect(() => {
    const filled = ratingFields.every(({ dataKey }) => form[dataKey]);
    onChange(filled ? form : null);
  }, [form]);

  return (
    <Box style={style}>
      {ratingFields.map(({ label, dataKey }) => (
        <Box
          key={dataKey}
          display="flex"
          alignItems="center"
          marginBottom="4px"
        >
          <Text content={label} style={{ width: "80px" }} />
          <Rating
            name={dataKey}
            size="small"
            value={form[dataKey]}
            onChange={(e, val) => setField(dataKey, val)}
          />
        </Box>
      ))}
    </Box>
  );
}

export default TipReviewForm;
