import PropTypes from "prop-types";
import _ from "lodash";
import { Radio, FormControlLabel } from "@material-ui/core";

function MyRadio({ initialState, label, onChange = _.noop }) {
  const radio = (
    <Radio
      defaultChecked={initialState}
      onChange={onChange}
      name={label}
      color="primary"
    />
  );

  return <FormControlLabel control={radio} label={label} color="primary" />;
}

MyRadio.propTypes = {
  initialState: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default MyRadio;
