/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Button, ContentList, Input } from "../../design-library";
import { QuestionStamp } from "../Question";

function TipQuestions({ tip, highlightedIds }) {
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState();

  const inputRef = useRef();

  const addQuestion = () => {
    setIsLoading(true);
    tip
      .addQuestion({ body: question })
      .then(() => setQuestion(""))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (tip?.id) inputRef.current?.focus();
  }, []);

  return (
    <Box>
      <Box width="100%">
        <Input
          inputRef={inputRef}
          fullWidth
          value={question}
          placeholder="Ask a question..."
          onChange={(e) => setQuestion(e.target.value)}
          multiline
        />
        {question && (
          <Box marginTop="8px" textAlign="right">
            <Button
              inline
              isLoading={isLoading}
              label="Send"
              onClick={addQuestion}
            />
          </Box>
        )}
      </Box>
      <Divider style={{ margin: "20px 0" }} />
      <ContentList
        entries={tip?.questions}
        CardComponent={QuestionStamp}
        emptyState={10}
        highlightedIds={highlightedIds}
      />
    </Box>
  );
}

export default TipQuestions;
