import Cookies from "js-cookie";
import Base from "./base";

export default class NotificationResource extends Base {
  static ws = null;

  get __resource() {
    return "NOTIFICATION";
  }

  get __endpoint() {
    return "/v1/notifications";
  }

  get __properties() {
    return ["id", "verb", "actor", "action_object", "created_at", "unread"];
  }

  static async startListener() {
    if (this.ws) return;

    const token = this.appContext.appState.authToken || Cookies.get("token");
    if (!token) return null;

    const setHasUnreadNotif = () => {
      this.appContext.appDispatch({
        type: "FLAGS:SET",
        payload: { hasNewNotifications: true },
      });
    };

    const param = { page_size: 1 };
    this.http
      .get(`${this.prototype.__endpoint}/unread/`, param)
      .then((data) => data.length && setHasUnreadNotif())
      .catch((err) => err);

    this.ws = new WebSocket(`${this.__baseUrl}/ws/?token=${token}`);
    this.ws.onclose = (evt) => (this.ws = null);
    this.ws.onmessage = setHasUnreadNotif;
  }

  static async stopListener() {
    this.ws?.close();
    this.ws = null;
  }

  async markAsRead() {
    if (!this.unread) return;

    await this.constructor.http.patch(`${this.__endpoint}/${this.id}/read/`);
    this.unread = false;
    this.updateStateObject();
  }

  static async markAllAsRead() {
    await this.http.patch(`${this.prototype.__endpoint}/read_all/`);
  }
}
