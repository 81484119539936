/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Input } from "../../design-library";

const bioFields = [
  {
    label: "First Name",
    dataKey: "first_name",
    placeholder: "Enter first name",
  },
  {
    label: "Last Name",
    dataKey: "last_name",
    placeholder: "Enter last name",
  },
  {
    label: "Username",
    dataKey: "username",
    placeholder: "Enter a unique username",
    newOnly: true,
  },
  {
    label: "Email Address",
    dataKey: "email",
    placeholder: "Enter email address",
  },
];

function PractitionerForm({
  initialValue = {},
  newEntry = false,
  onUpdate = _.noop,
}) {
  const [form, setForm] = useState({});
  const onFormInput = (key, value) => setForm({ ...form, [key]: value });

  useEffect(() => onUpdate(form), [form]);

  return (
    <Grid container spacing={2}>
      {bioFields
        .filter((f) => (newEntry ? true : !f.newOnly))
        .map(({ label, dataKey, placeholder }) => (
          <Grid xs={6} item key={dataKey}>
            <Input
              placeholder={placeholder}
              label={label}
              onChange={(e) => onFormInput(dataKey, e.target.value)}
              initialValue={initialValue[dataKey] || ""}
              shrinkLabel
              fullWidth
              fullHeight
              multiline
            />
          </Grid>
        ))}
    </Grid>
  );
}

export default PractitionerForm;
