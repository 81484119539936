/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Input, Text } from "../../design-library";

function ExampleForm({ initialValues, onUpdate }) {
  const [form, setForm] = useState({});
  const headlineRef = useRef();
  const onInput = (key, value) => setForm({ ...form, [key]: value });

  const onUpdateDebounced = useCallback(_.debounce(onUpdate, 500), []);
  useEffect(() => onUpdate && onUpdateDebounced(form), [form]);
  useEffect(() => headlineRef.current?.focus(), []);

  const inputProps = {
    fullWidth: true,
    multiline: true,
    style: { marginTop: "8px" },
  };

  const labelProps = {
    type: "label",
    style: { marginTop: "12px" },
  };

  return (
    <>
      <Text {...labelProps} content="Headline" style={{ marginTop: 0 }} />
      <Input
        {...inputProps}
        inputRef={headlineRef}
        initialValue={initialValues?.headline}
        multiline={false}
        variant="standard"
        placeholder="Enter headline..."
        onChange={(e) => onInput("headline", e.target.value)}
      />

      <Text {...labelProps} content="Child Context" />
      <Input
        {...inputProps}
        initialValue={initialValues?.situation}
        placeholder="Enter child context..."
        onChange={(e) => onInput("situation", e.target.value)}
      />

      <Text {...labelProps} content="Shadow Response" />
      <Input
        {...inputProps}
        initialValue={initialValues?.shadows_response}
        placeholder="Enter shadow response..."
        onChange={(e) => onInput("shadows_response", e.target.value)}
      />

      <Text {...labelProps} content="Outcome" />
      <Input
        {...inputProps}
        initialValue={initialValues?.outcome}
        placeholder="Enter outcome..."
        onChange={(e) => onInput("outcome", e.target.value)}
      />
    </>
  );
}

export default ExampleForm;
