import { Box } from "@material-ui/core";
import _ from "lodash";
import { useURLState } from "../utils";
import { standardTipFilters } from "./filters";
import TipCard from "./TipCard";
import TipDisplayModal from "./TipDisplayModal";
import TipRowV2 from "./TipRowV2";

function getRows(tips) {
  const rowMap = { 1: [], 2: [], 3: [], 4: [], 5: [] };
  for (const tip of tips) {
    rowMap[tip.levels].push(tip);
  }

  return Object.keys(rowMap).map((id) => ({ id, tips: rowMap[id] }));
}

function TipGridV2({
  tips,
  CardComponent = TipCard,
  onTipOpen = _.noop,
  filters = standardTipFilters,
  descriptor = null,
  printMode = false,
}) {
  const [modalContent, setModalContent] = useURLState({}, ["tipId"]);

  const rows = getRows(tips || []);

  return (
    <>
      {rows.map((row) => (
        <TipRowV2
          key={row.id}
          CardComponent={CardComponent}
          row={row}
          onClick={(tip, e) => setModalContent({ tipId: tip.id })}
        />
      ))}

      <TipDisplayModal
        tipId={parseInt(modalContent.tipId)}
        onClose={() => setModalContent({})}
        onOpen={onTipOpen}
      />
    </>
  );
}

export default TipGridV2;
