import { Box } from "@material-ui/core";
import _ from "lodash";
import { Tooltip, Icon, Chip, Divider } from "../../design-library";
import { TipByline, TipSummary } from ".";

function TipDetails({ tip }) {
  return (
    <Box>
      <Chip
        filled
        style={{ marginBottom: "8px" }}
        color={tip.color}
        content={<span>{_.capitalize(tip.motivator)}</span>}
      />
      {tip.marked_for_editing && (
        <Tooltip content="Marked for editing">
          <Chip
            filled
            style={{ marginBottom: "8px", marginLeft: "4px" }}
            color="#00B5B2"
            content={<Icon name="edit" style={{ fontSize: "12px" }} />}
          />
        </Tooltip>
      )}
      <TipByline tip={tip} />
      <Divider />
      <TipSummary tip={tip} />
    </Box>
  );
}

export default TipDetails;
