/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Input } from "../../design-library";

const headsUpFields = [
  {
    label: "Play themes",
    dataKey: "play_themes",
    placeholder: "Enter play themes...",
  },
  {
    label: "Preferred objects",
    dataKey: "preferred_objects",
    placeholder: "Enter preferred objects...",
  },
  {
    label: "New connections",
    dataKey: "new_connections",
    placeholder: "Enter new connections...",
  },
  {
    label: "Peer conflicts",
    dataKey: "peer_conflicts",
    placeholder: "Enter peer conflicts...",
  },
  {
    label: "Routine changes",
    dataKey: "routine_changes",
    placeholder: "Enter routine changes...",
  },
  {
    label: "New Behaviors",
    dataKey: "new_behaviors",
    placeholder: "Enter new behaviors...",
  },
  {
    label: "Celebrations",
    dataKey: "celebrations",
    placeholder: "Enter celebrations...",
  },
  {
    label: "Shadow To do's",
    dataKey: "shadow_todos",
    placeholder: "Enter shadow to do's...",
  },
  {
    label: "Miscellaneous observations",
    dataKey: "misc_observations",
    placeholder: "Enter miscellaneous observations...",
  },
  {
    label: "Upcoming events",
    dataKey: "upcoming_events",
    placeholder: "Enter upcoming events...",
  },
  {
    label: "Monitoring",
    dataKey: "monitoring",
    placeholder: "Enter monitoring info...",
  },
  {
    label: "Struggles",
    dataKey: "struggles",
    placeholder: "Enter struggles...",
  },
  {
    label: "Language",
    dataKey: "language",
    placeholder: "Enter language...",
  },
];

function HeadsUpInput({ initialValue = {}, onChange, ...props }) {
  const [form, setForm] = useState(initialValue);

  useEffect(() => onChange(form), [form]);

  return (
    <Grid container spacing={2} {...props}>
      {headsUpFields.map(({ label, dataKey, placeholder }) => (
        <Grid xs={6} item key={dataKey}>
          <Input
            placeholder={placeholder}
            label={label}
            onChange={(e) => setForm({ ...form, [dataKey]: e.target.value })}
            initialValue={initialValue[dataKey] || ""}
            shrinkLabel
            fullWidth
            fullHeight
            multiline
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default HeadsUpInput;
