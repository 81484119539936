import { useContext, useEffect, useState } from "react";

import {
  Button,
  ContentList,
  Dropdown,
  Input,
  Modal,
  Spinner,
  Text,
} from "../../design-library";
import { AppContext } from "../../context";
import { Box, Divider, makeStyles } from "@material-ui/core";
import { formatDate } from "../utils";
import { StruggleResource, TipResource } from "../../resources";
import { TipSelector, TipDisplayModal, TipCard } from "../Tip";
import { StruggleForm, StruggleDetails } from ".";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  reply: {
    marginBottom: "20px",
    border: "1px solid #B5B5B555",
    padding: "8px",
  },
  byline: { marginBottom: "8px", fontWeight: "bold" },
  tips: {
    marginTop: "8px",
  },
}));

function Reply({ reply, showTip }) {
  const classes = useStyles();

  const tips = reply.tips
    .map(
      (id) =>
        TipResource.pick(id) || TipResource.toObject({ id, title: `Tip#${id}` })
    )
    .filter(Boolean);

  return (
    <Box className={classes.reply}>
      <Text
        content={`${reply.added_by?.full_name} on ${formatDate(
          reply.created_at
        )}`}
        type="subtitle"
        className={classes.byline}
      />
      <Text content={reply.details} type="paragraph" />
      <Box className={classes.tips}>
        <ContentList
          folds={3}
          entries={tips}
          onEntryClick={(entry) => showTip(entry.id)}
          CardComponent={TipCard}
        />
      </Box>
    </Box>
  );
}

function StruggleDisplayModal({ struggleId, onClose }) {
  const {
    appState: { strugglesMap, tipsMap },
  } = useContext(AppContext);

  const [replyForm, setReplyForm] = useState({});
  const [isBusy, setIsBusy] = useState(false);
  const [modalTipId, setModalTipId] = useState();
  const [mode, setMode] = useState();
  const [struggleEditForm, setStruggleEditForm] = useState({});

  const breadcrumbs = ["Struggles"];
  if (struggleId) {
    breadcrumbs.push(struggleId);
  }
  const struggle = strugglesMap && struggleId ? strugglesMap[struggleId] : null;

  useEffect(() => {
    if (struggleId) StruggleResource.getOne(struggleId);
  }, [struggleId]);

  const createReply = () => {
    const form = { ...replyForm };
    if (!form.tips) form.tips = [];

    setIsBusy(true);
    struggle
      .addReply(form)
      .then(() => {
        setMode();
        setReplyForm({});
      })
      .finally(() => setIsBusy(false));
  };

  const updateStruggle = () => {
    setIsBusy(true);
    struggle
      .update(struggleEditForm)
      .then(() => setMode())
      .finally(() => setIsBusy(false));
  };

  const pencilDropdownOptions = [{ label: "Edit", value: "edit-struggle" }];

  if (struggle) {
    const ii = struggle.is_resolved ? "Unmark" : "Mark";
    pencilDropdownOptions.push({
      label: `${ii} as Resolved`,
      value: "toggle-resolve-mark",
    });
  }

  return (
    <Modal
      open={!!struggleId}
      onClose={onClose}
      breadcrumbs={breadcrumbs}
      footer={
        <Box textAlign="right">
          {mode ? (
            <>
              <Button
                label="Cancel"
                onClick={() => setMode()}
                style={{ marginRight: "10px" }}
                disabled={isBusy}
              />
              <Button
                isLoading={isBusy}
                label="Save"
                primary
                onClick={() => {
                  if (mode === "write-reply") return createReply();
                  if (mode === "edit-struggle") return updateStruggle();
                }}
              />
            </>
          ) : (
            <>
              <Dropdown
                icon="edit"
                options={pencilDropdownOptions}
                style={{
                  float: "left",
                  marginLeft: "-12px",
                }}
                onSelect={(op) =>
                  op === "toggle-resolve-mark"
                    ? struggle.toggleResolveMark()
                    : setMode(op)
                }
                statefulLoader
              />
              <Button
                label="Back"
                onClick={onClose}
                style={{ marginRight: "10px" }}
              />
              <Button
                label="Write a Reply"
                primary
                onClick={() => setMode("write-reply")}
              />
            </>
          )}
        </Box>
      }
    >
      {struggle ? (
        mode === "edit-struggle" ? (
          <StruggleForm
            initialValues={_.pick(struggle, [
              "headline",
              "outcome",
              "shadow_response",
              "child_context",
              "question",
            ])}
            onUpdate={(form) => setStruggleEditForm(form)}
          />
        ) : (
          <StruggleDetails struggle={struggle} />
        )
      ) : (
        <Spinner style={{ height: "100%" }} size={30} />
      )}
      {mode === "write-reply" && (
        <Box>
          <Divider style={{ margin: "12px 0" }} />
          <Text
            type="label"
            content="Suggestion"
            style={{ marginBottom: "12px" }}
          />
          <Input
            variant="standard"
            initialValue={replyForm.details || ""}
            fullWidth
            multiline
            placeholder="Write your suggestion..."
            onChange={(e) =>
              setReplyForm({ ...replyForm, details: e.target.value })
            }
          />
          <Box display="flex" justifyContent="space-between" marginTop="12px">
            <Text
              type="label"
              content="Tips"
              style={{ marginBottom: "12px" }}
            />
            <TipSelector
              AnchorComponent={({ onClick }) => (
                <Button
                  icon="plus"
                  style={{
                    padding: "8px",
                    margin: "-8px",
                    height: "100%",
                  }}
                  onClick={onClick}
                />
              )}
              onSelect={(tipIds) =>
                setReplyForm({
                  ...replyForm,
                  tips: [...(replyForm.tips || []), ...tipIds],
                })
              }
              multiSelect
            />
          </Box>
          <ContentList
            CardComponent={TipCard}
            entries={replyForm.tips?.map((id) => tipsMap[id]) || []}
            onEntryCross={(entry) =>
              setReplyForm({
                ...replyForm,
                tips: replyForm.tips.filter((id) => id !== entry.id),
              })
            }
          />
        </Box>
      )}
      {!mode && struggle && (
        <Box>
          <Divider style={{ margin: "12px 0" }} />
          <Text
            type="label"
            content="Replies"
            style={{ marginBottom: "12px" }}
          />
          {struggle.replies?.map((reply) => (
            <Reply
              key={reply.id}
              reply={reply}
              showTip={(id) => setModalTipId(id)}
            />
          ))}
        </Box>
      )}
      <TipDisplayModal tipId={modalTipId} onClose={() => setModalTipId()} />
    </Modal>
  );
}

export default StruggleDisplayModal;
