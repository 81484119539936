import _ from "lodash";
import moment from "moment";
import Base from "./base";

export default class StruggleResource extends Base {
  static __requiredFields = {
    createOne: [
      { key: "headline", label: "Headline" },
      { key: "child_context", label: "Child Context" },
      { key: "shadow_response", label: "Shadow Reponse" },
      { key: "outcome", label: "Outcome" },
      { key: "question", label: "Question" },
    ],
    update: [],
  };

  get __properties() {
    return [
      "id",
      "headline",
      "child_context",
      "shadow_response",
      "outcome",
      "question",
      "episode",
      "created_at",
      "practitioner",
      "student",
      "replied_by",
      "replies",
      "is_resolved",
    ];
  }

  get __resource() {
    return "STRUGGLE";
  }

  get __endpoint() {
    return "/v1/struggles";
  }

  static async getRecent() {
    const struggles = await this.list({
      start_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
      is_resolved: false,
    });

    this.appContext.appDispatch({
      type: "RECENT_STRUGGLE_IDS:SET",
      payload: struggles.map((st) => st.id),
    });

    return struggles;
  }

  async addReply(data) {
    const reqFields = [{ key: "details", label: "Suggestion" }];
    if (!this.constructor.validate(data, reqFields)) return Promise.reject();

    try {
      const reply = await this.constructor.http.post(
        `/v1/struggles/${this.id}/replies/`,
        {},
        data
      );
      this.replies = [reply, ...this.replies];
      this.updateStateObject();
      this.constructor.alert.success("Successfully added a suggestion.");
    } catch (err) {
      this.constructor.alert.error("Failed to add a suggestion.");
      throw err;
    }
  }

  async toggleResolveMark() {
    try {
      const ii = this.is_resolved ? "unmarked" : "marked";
      await this.update({ is_resolved: !this.is_resolved }, undefined, true);
      StruggleResource.alert.success(`The struggle is ${ii} as resolved.`);
    } catch (err) {
      StruggleResource.alert.error("Failed to toggle the resolve-mark.");
      throw err;
    }
  }

  static toObject(data) {
    const tip = new StruggleResource();
    data.practitioner = data.added_by?.full_name;
    data.replied_by = _.uniqBy(
      data.replies?.map((rep) => rep.added_by) || [],
      (v) => v.id
    );
    tip.assign(data);
    return tip;
  }

  get studentInitial() {
    return this.student?.nickname || "";
  }
}
