import { Box, CircularProgress } from "@material-ui/core";
import { Text } from "..";

function Spinner({ size = 20, label, style }) {
  return (
    <Box
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <CircularProgress size={size} />
      {label && <Text style={{ marginLeft: "8px" }} content={label} />}
    </Box>
  );
}

export default Spinner;
