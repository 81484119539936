import _ from "lodash";
import { PractitionerResource } from ".";
import Base from "./base";

/**
 * DLP Users who were created before cutOffDate do not require filling
 * up the survey to be able to see the grid.
 */
const cutOffDate = new Date("2022-02-10T13:32:08.697Z");

export default class UserResource extends Base {
  static ROLES = {
    ADMIN: 1, // Administrator User
    EDUCATOR: 2, // Educator Shadow
    EDUCATOR_PLUS: 4, // Guest
    DLP: 5, // Experimental Teacher
  };

  static ROLE_LABELS = {
    1: "Administrator",
    2: "Educator",
    4: "Educator Plus",
    5: "DLP User",
  };

  static __requiredFields = {
    changePassword: [
      { key: "old_password", label: "Current Password" },
      { key: "new_password1", label: "New Password" },
    ],
    getToken: [
      { key: "username", label: "Username" },
      { key: "password", label: "Password" },
    ],
    requestPasswordReset: [{ key: "email", label: "Email" }],
    confirmPasswordReset: [
      { key: "token", label: "Token" },
      { key: "password", label: "Password" },
    ],
  };

  get __properties() {
    return [
      "id",
      "first_name",
      "last_name",
      "role",
      "username",
      "contributions",
    ];
  }

  static async getToken(form) {
    if (!this.validate(form, this.__requiredFields.getToken))
      return Promise.reject();

    try {
      return await this.http.post("/api-token-auth/", {}, form, {
        Authorization: null,
      });
    } catch (e) {
      this.alert.error(e.message);
      throw e;
    }
  }

  updateStateObject() {
    UserResource.appContext.appDispatch({
      type: "USER:SET",
      payload: this,
    });
  }

  async loadContributions() {
    this.contributions = await UserResource.http.get(
      "/v1/reports/contributions/",
      { days: 100 }
    );
    this.updateStateObject();

    return this;
  }

  static async changePassword(form) {
    if (!this.validate(form, this.__requiredFields.changePassword))
      return Promise.reject();

    if (form.new_password1 !== form.new_password2) {
      this.alert.warning("New password fields do not match.");
      return Promise.reject();
    }

    try {
      await this.http.post("/api-auth/password/change/", {}, form);
      this.alert.success("Password has been changed.");
    } catch (e) {
      this.alert.error(e.message);
      throw e;
    }
  }

  static async requestPasswordReset(form) {
    if (!this.validate(form, this.__requiredFields.requestPasswordReset))
      return;

    try {
      await this.http.post("/api-auth/password-reset/", {}, form);
      this.alert.success("Please check your email.");
    } catch (e) {
      this.alert.error(e.message);
      throw e;
    }
  }

  static async confirmPasswordReset(form) {
    if (!this.validate(form, this.__requiredFields.confirmPasswordReset))
      return Promise.reject();

    if (form.password !== form.password2) {
      this.alert.warning("Password fields do not match.");
      return Promise.reject();
    }

    try {
      await this.http.post(
        "/api-auth/password-reset/confirm/",
        {},
        _.pick(form, ["token", "password"])
      );
      this.alert.success("Password has been updated.");
    } catch (e) {
      this.alert.error(e.message);
      throw e;
    }
  }

  static async getCurrentUser() {
    const userData = await this.http.get("/api-auth/user/");
    const user = new UserResource();
    user.assign(userData);

    try {
      await PractitionerResource.getOne(user.id);
    } catch (e) {}

    this.appContext.appDispatch({ type: "USER:SET", payload: user });

    return user;
  }

  static async saveSurveyResult(data) {
    try {
      await this.http.post("/v1/surveys/", {}, data);
      this.alert.success("Your selections have been saved.");
    } catch (e) {
      this.alert.error("Failed to save your selections, please try again.");
      throw e;
    }
  }

  get practitioner() {
    return PractitionerResource.pick(this.id);
  }

  get isDLP() {
    return UserResource.current?.role === UserResource.ROLES.DLP;
  }

  get isEducatorPlus() {
    return UserResource.current?.role === UserResource.ROLES.EDUCATOR_PLUS;
  }

  get isAdmin() {
    return UserResource.current?.role === UserResource.ROLES.ADMIN;
  }

  get yetToFillSurvey() {
    const prac = this.practitioner;
    return !prac?.professional_goal && cutOffDate < new Date(prac?.date_joined);
  }

  static get current() {
    return this.appContext.appState.user;
  }
}
