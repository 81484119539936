import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
import { Button, Text } from "..";

const useStyles = makeStyles(() => ({
  sidebar: {
    position: "fixed",
    top: "223px",
    right: ({ open }) => (open ? 0 : "-400px"),
    width: "400px",
    backgroundColor: "white",
    bottom: 0,
    padding: "8px 12px",
    transition: "right 0.5s ease",
    borderLeft: "2px solid #00B5B2",
  },
}));

function Sidebar({ open, title, children, onClose }) {
  const classes = useStyles({ open });

  return (
    <Box className={classes.sidebar}>
      <Grid container justifyContent="space-between">
        <Text content={title} type="label" />
        <Button icon="forward" style={{ padding: 0 }} onClick={onClose} />
      </Grid>
      <Divider style={{ margin: "8px 0" }} />
      <Box height="calc(100% - 33px)" overflow="auto">
        {children}
      </Box>
    </Box>
  );
}

export default Sidebar;
