import _ from "lodash";
import { ActivityResource } from "../../resources";

const standardTipFilters = [
  {
    label: "Motivator",
    dataKey: "levels",
    options: [
      {
        label: "Avoidance motivation",
        value: "1",
        cue: <span style={{ color: "#F05B70" }}>&bull;&nbsp;</span>,
      },
      {
        label: "Object/sensory motivation",
        value: "2",
        cue: <span style={{ color: "#EAA533" }}>&bull;&nbsp;</span>,
      },
      {
        label: "Reaction motivation",
        value: "3",
        cue: <span style={{ color: "#b2b347" }}>&bull;&nbsp;</span>,
      },
      {
        label: "Interaction flow motivation",
        value: "4",
        cue: <span style={{ color: "#81C77F" }}>&bull;&nbsp;</span>,
      },
      {
        label: "Planning motivation",
        value: "5",
        cue: <span style={{ color: "#819BD0" }}>&bull;&nbsp;</span>,
      },
    ],
  },
  {
    label: "Marked for Editing",
    dataKey: "marked_for_editing",
    options: [
      {
        label: "With edit mark",
        value: true,
      },
      {
        label: "Without edit mark",
        value: false,
      },
    ],
  },
];

const studentTipFilters = [
  {
    label: "Status",
    dataKey: "status",
    options: [
      { label: "Newly added", value: "HIGHLIGHTED" },
      { label: "Has New Content", value: "BOLD" },
      { label: "Graduated", value: "FADED" },
      { label: "Others", value: "REGULAR" },
    ],
  },
];

const dlpTipFilters = [
  {
    label: "Status",
    dataKey: "status",
    options: [
      { label: "Newly added", value: "new" },
      { label: "Needs rating", value: "needs_rating" },
      { label: "Not tried yet", value: "not_tried" },
      { label: "Others", value: "" },
    ],
  },
];

const activityFilters = [
  {
    label: "Activity types",
    dataKey: "types",
    fullWidth: true,
    options: Object.entries(ActivityResource.types).map(([label, value]) => ({
      label: _.capitalize(_.replace(label, /_/g, " ")),
      value,
    })),
  },
];

export {
  standardTipFilters,
  studentTipFilters,
  dlpTipFilters,
  activityFilters,
};
