import { Box } from "@material-ui/core";
import { Dropdown } from "..";

function ModalFooter({ actions }) {
  const activeActions = actions.filter((ac) => !ac.hidden);
  const ellipsisActions = actions
    .filter((ac) => !ac.Component)
    .map(({ label, onClick }, idx) => ({ label, value: idx, onClick }));

  return (
    <Box textAlign="right">
      {ellipsisActions.length > 0 && (
        <Dropdown
          icon="ellipsis"
          options={ellipsisActions}
          style={{
            marginRight: "10px",
            float: "left",
            marginLeft: "-12px",
          }}
          onSelect={(val) => ellipsisActions[val]?.onClick()}
          statefulLoader
        />
      )}
      <Box display="flex" flexDirection="row-reverse">
        {activeActions
          .filter((ac) => !!ac.Component)
          .map(({ Component, ...props }, idx) => (
            <Component
              key={idx}
              primary={!idx}
              style={{ marginRight: idx ? "12px" : 0 }}
              {...props}
            />
          ))}
      </Box>
    </Box>
  );
}

export default ModalFooter;
