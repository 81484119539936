import { Box, makeStyles } from "@material-ui/core";
import { Icon } from "../../design-library";

const useStyles = makeStyles(() => ({
  root: {
    borderLeft: ({ tipColor }) => `3px solid ${tipColor}`,
    backgroundColor: ({ tipColor }) => `${tipColor}22`,
    cursor: ({ onClick }) => (onClick ? "pointer" : ""),
    color: "#686868",
    fontSize: "14px",
    padding: "8px",
    marginBottom: "3px",
    outline: ({ highlighted, tipColor }) =>
      highlighted ? `1px solid ${tipColor}` : "",
  },
  cross: {
    float: "right",
    cursor: "pointer",
    margin: "-8px",
    padding: "8px",
    color: ({ tipColor }) => tipColor,
    "&:hover": {
      background: ({ tipColor }) => tipColor,
      color: "white",
    },
  },
  subtext: {
    color: "#93999A",
    fontSize: "12px",
    marginBottom: "2px",
  },
  editMark: {
    fontSize: "14px",
    marginBottom: "-2px",
    marginRight: "4px",
    color: ({ tipColor }) => tipColor,
  },
  printMark: {
    fontSize: "14px",
    marginBottom: "-2px",
    marginRight: "4px",
    color: ({ tipColor }) => tipColor,
    opacity: ({ printTagged }) => (printTagged ? 1 : 0.25),
  },
}));

function TipCard({
  entry,
  subtext,
  tip,
  truncate = 2,
  highlighted,
  onClick,
  onCross,
  togglePrintTag,
  printTagged,
}) {
  tip = entry || tip;

  const classes = useStyles({
    onClick: !!onClick,
    highlighted,
    tipColor: tip.color,
    printTagged,
  });

  return (
    <Box className={classes.root} onClick={onClick}>
      {subtext && (
        <span className={["truncate-1", classes.subtext].join(" ")}>
          {subtext}
        </span>
      )}
      <Box display="flex">
        <span
          style={{ width: onCross ? "calc(100% - 17px)" : "100%" }}
          className={`truncate-${truncate}`}
        >
          {tip.marked_for_editing ? (
            <Icon name="editMark" className={classes.editMark} />
          ) : (
            togglePrintTag && (
              <Icon
                name="print"
                className={classes.printMark}
                onClick={(e) => {
                  togglePrintTag();
                  e.stopPropagation();
                }}
              />
            )
          )}
          {tip.title}
        </span>
        {onCross && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.cross}
            onClick={(e) => {
              e.stopPropagation();
              onCross();
            }}
          >
            <Icon name="close" style={{ fontSize: "17px" }} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TipCard;
