import { SvgIcon } from "@material-ui/core";

function Illustration(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <path
        style={{ fill: "#FDB62F" }}
        d="M0,457.534L0,457.534c0.009-4.829,1.254-9.569,3.619-13.771L231.724,41.137
	c5.085-9.066,14.68-14.671,25.07-14.654l0,0c10.408,0.026,20.003,5.614,25.159,14.654l226.428,402.714
	c2.375,4.167,3.628,8.881,3.619,13.683l0,0c0,15.457-12.526,27.983-27.983,27.983H27.983C12.526,485.517,0,472.991,0,457.534z"
      />
      <path
        style={{ fill: "#FFA230" }}
        d="M508.381,443.851L365.021,188.91c-47.307,126.738-138.867,232.086-257.766,296.607h376.761
	c15.457,0,27.983-12.526,27.983-27.983l0,0C512.009,452.732,510.755,448.018,508.381,443.851z"
      />
      <g>
        <path
          style={{ fill: "#DE4C3C" }}
          d="M194.207,353.103h-17.655v26.483h-15.448l15.448-68.679l-17.655-3.884l-17.655,79.448
		c-0.591,2.639,0.062,5.402,1.766,7.503c1.721,2.127,4.326,3.328,7.062,3.266h26.483v17.655h17.655v-17.655h8.828v-17.655h-8.828
		V353.103z"
        />
        <path
          style={{ fill: "#DE4C3C" }}
          d="M256,308.966c-19.5,0-35.31,15.81-35.31,35.31v35.31c0,19.5,15.81,35.31,35.31,35.31
		s35.31-15.81,35.31-35.31v-35.31C291.31,324.776,275.5,308.966,256,308.966z M273.655,379.586c0,9.754-7.901,17.655-17.655,17.655
		s-17.655-7.901-17.655-17.655v-35.31c0-9.754,7.901-17.655,17.655-17.655s17.655,7.901,17.655,17.655V379.586z"
        />
        <path
          style={{ fill: "#DE4C3C" }}
          d="M370.759,379.586h-8.828v-26.483h-17.655v26.483h-15.448l15.448-68.679l-17.655-3.884
		l-17.655,79.448c-0.591,2.639,0.062,5.402,1.766,7.503c1.721,2.127,4.326,3.328,7.062,3.266h26.483v17.655h17.655v-17.655h8.828
		V379.586z"
        />
        <rect
          x="247.172"
          y="141.241"
          style={{ fill: "#DE4C3C" }}
          width="17.655"
          height="88.276"
        />
        <rect
          x="247.172"
          y="247.172"
          style={{ fill: "#DE4C3C" }}
          width="17.655"
          height="17.655"
        />
      </g>
    </SvgIcon>
  );
}

export default Illustration;
