import { Box, makeStyles } from "@material-ui/core";
import { Icon } from "../../design-library";
import { QuestionResource } from "../../resources";
import { formatDate } from "../utils";

const useStyles = makeStyles(() => ({
  subtext: {
    color: "#93999A",
    fontSize: "12px",
    marginBottom: "2px",
  },
  root: {
    borderLeft: "3px solid #D59777",
    backgroundColor: ({ selected }) => (selected ? "	#D59777" : "#D5979955"),
    cursor: ({ onClick }) => (onClick ? "pointer" : "default"),
    color: ({ selected }) => (selected ? "white" : "#686868"),
    fontSize: "14px",
    padding: "8px",
    marginBottom: "3px",
  },
  chatIcon: { fontSize: "12px", float: "right", color: "#D59777" },
}));

function QuestionCard({ subtext, entry, selected, onClick }) {
  const classes = useStyles({ selected, onClick });

  return (
    <Box className={classes.root} onClick={onClick}>
      <span className={["truncate-1", classes.subtext].join(" ")}>
        <b>{QuestionResource.getAuthorName(entry)}</b> about{" "}
        <b>Tip #{entry.tip}</b> &bull; {formatDate(entry.created_at)}
        {entry.replies?.length > 0 && (
          <Icon name="chat" className={classes.chatIcon} />
        )}
      </span>
      <span className="truncate-1">{entry.body}</span>
    </Box>
  );
}

export default QuestionCard;
