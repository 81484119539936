import { Box, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useContext, useState } from "react";
import { AppContext } from "../../context";
import { Button, Checkbox, Divider, Input, Text } from "../../design-library";
import { UserResource } from "../../resources";
import { getFormInputFn } from "../../shared-components/utils";

const useStyles = makeStyles(() => ({
  root: {
    width: "440px",
    background: "white",
    boxShadow: "0 0 16px 2px rgba(0, 0, 0, 0.2)",
    padding: "8px 0 32px 0",
    zIndex: 10,
  },
}));

function SignInBox({ toggleBox }) {
  const { appDispatch } = useContext(AppContext);
  const [form, setForm] = useState({});

  const onInput = getFormInputFn(setForm);
  const onSubmit = () =>
    UserResource.getToken(_.pick(form, ["username", "password"]))
      .then((data) =>
        appDispatch({
          type: "TOKEN:SET",
          payload: { token: data.token, remember: form.remember },
        })
      )
      .then(() => UserResource.getCurrentUser())
      .then((user) => appDispatch({ type: "USER:SET", payload: user }));

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Text
        content="Sign In"
        style={{ color: "#00B5B2", fontSize: "150%", textAlign: "center" }}
      />
      <Divider />
      <Box padding="0 16px">
        <Text type="label" content="Email address or username" />
        <Input
          fullWidth
          placeholder="Enter your email address or username"
          onChange={(e) => onInput("username", e.target.value)}
        />
        <Text type="label" content="Password" style={{ marginTop: "10px" }} />
        <Input
          fullWidth
          placeholder="Enter your password"
          type="password"
          onChange={(e) => onInput("password", e.target.value)}
        />
        <Text
          type="clickable"
          content="Forgot your password?"
          onClick={toggleBox}
        />
        <Box display="flex" justifyContent="space-between" marginTop="10px">
          <Checkbox
            label="Remember me"
            onChange={(e, v) => onInput("remember", v)}
          />
          <Button label="Sign in" primary statefulLoader onClick={onSubmit} />
        </Box>
      </Box>
    </Box>
  );
}

export default SignInBox;
